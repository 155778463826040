import ActiveIndicator from 'components/ActiveIndicator/ActiveIndicator'
import HoverArrow from 'components/HoverArrow/HoverArrow'
import * as jwtDecode from 'jwt-decode'
import get from 'lodash.get'
import DashboardIcon from '@material-ui/icons/Dashboard'
import HomeIcon from '@material-ui/icons/Home'
import RulesIcon from '@material-ui/icons/List'
import RoomIcon from '@material-ui/icons/Room'
import SettingsIcon from '@material-ui/icons/Settings'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import FileImportIcon from '@material-ui/icons/CloudUpload'
import NotificationsIcon from '@material-ui/icons/NotificationsActive'
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'
import { SidebarMenuContext } from 'providers/SidebarMenuProvider'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
import { getLocalStorageToken } from 'store/localStorage'
import { MdLiveHelp } from 'react-icons/md'
import { getUser } from 'utils/getUser'
import SecurityIcon from '@material-ui/icons/Security'
import { useCustomerSettings } from 'hooks/useCustomerSettings'

export interface Props {
  location: any
}
interface LinkProps {
  menuItem: any
  clickLink: (e: any) => void
  index: number
  children: any
}
function CustomLink(props: LinkProps) {
  const { menuItem, clickLink, index } = props
  if (!menuItem.url) {
    return props.children
  }
  return !menuItem.url?.match('http') ? (
    <Link
      to={menuItem.url}
      target={menuItem.target ? menuItem.target : null}
      key={index}
      onClick={clickLink}
      className={menuItem.onlyMobile ? 'block sm:hidden' : ''}
    >
      {props.children}
    </Link>
  ) : (
    <a
      href={menuItem.url}
      target={menuItem.target ? menuItem.target : null}
      className={menuItem.onlyMobile ? 'block sm:hidden' : ''}
    >
      {props.children}
    </a>
  )
}
const baseStyle: React.CSSProperties = { width: 20, height: 20, display: 'block', margin: '0 auto' }

declare global {
  function FreshworksWidget(...props: any): void
}

const SidebarMenu: React.FC<Props> = props => {
  const { t } = useTranslation()
  const { enableGeofencesOnCustomersAndOrders } = useCustomerSettings()

  useEffect(() => {
    const user = getUser()

    if (user) {
      FreshworksWidget('identify', 'ticketForm', {
        email: user.username
      })
    }
  }, [])
  const handleToggleSupport = () => {
    const widgetNode = document.getElementById('freshworks-frame-wrapper')
    if (widgetNode) {
      FreshworksWidget('close')
    } else {
      FreshworksWidget('open')
    }
  }

  const menuItems = useMemo(
    () => [
      {
        url: '/',
        title: t('SidebarMenu.start'),
        icon: c => <HomeIcon style={baseStyle} className={c} />,
        exact: true,
        onlyMobile: false
      },
      {
        url: '/persons',
        title: t('general.people'),
        icon: c => <SupervisorAccountIcon style={baseStyle} className={c} />,
        exact: true,
        onlyMobile: false
      },
      {
        url: '/notifications',
        title: t('general.notifications'),
        icon: c => <NotificationsIcon style={baseStyle} className={c} />,
        exact: true,
        onlyMobile: false
      },
      {
        url: '/newNotifications',
        title: t('general.deviations'),
        icon: c => <NotificationsOutlinedIcon style={baseStyle} className={c} />,
        exact: false,
        news: true,
        onlyMobile: false
      },
      {
        url: '/rules',
        title: t('SidebarMenu.rules'),
        icon: c => <RulesIcon style={baseStyle} className={c} />,
        exact: true,
        onlyMobile: false
      },
      {
        url: '/geofences',
        title: t('SidebarMenu.geoFences'),
        icon: c => <RoomIcon style={baseStyle} className={c} />,
        exact: true,
        onlyMobile: false,
        hidden: enableGeofencesOnCustomersAndOrders ? false : true
      },
      {
        url: '/fileimports',
        title: t('SidebarMenu.fileImport'),
        icon: c => <FileImportIcon style={baseStyle} className={c} />,
        exact: false,
        onlyMobile: false
      },
      {
        url: '/admin',
        title: t('SidebarMenu.admin'),
        icon: c => <DashboardIcon style={baseStyle} className={c} />,
        minimumPrivilegeLevel: 2,
        exact: true,
        onlyMobile: false
      },
      {
        url: '/userSettings',
        title: t('SidebarMenu.userSettings'),
        icon: c => <SettingsIcon style={baseStyle} className={c} />,
        exact: true,
        onlyMobile: true
      },
      {
        url: '/superadmin',
        title: t('SidebarMenu.superAdmin'),
        icon: c => <SecurityIcon style={baseStyle} className={c} />,
        exact: false,
        minimumPrivilegeLevel: 4
      }
    ],
    [t, enableGeofencesOnCustomersAndOrders]
  )
  const path = props.location.pathname
  const isActive = (url: string, currPath: string, exact: boolean) => {
    return exact ? path === url : currPath.includes(url)
  }
  let privilegeLevel = 1
  try {
    privilegeLevel = get(jwtDecode(getLocalStorageToken()), 'user.privilegeLevel', 1)
  } catch (err) {}

  return (
    <SidebarMenuContext.Consumer>
      {p => {
        const { hidden, actions } = p ?? {}
        const { closeMenu } = actions ?? {}
        const clickLink = props => {
          closeMenu?.()
        }
        return (
          <div
            className={`fixed flex justify-between flex-col w-[75px] top-[75px] left-0 bg-white sm:flex ${
              hidden ? 'hidden' : 'flex'
            }`}
            style={{ zIndex: 1000, height: 'calc(100% - 75px)' }}
          >
            <div>
              {menuItems
                .filter(menuItem => Boolean(!menuItem.hidden))
                .map((menuItem, index) =>
                  menuItem.minimumPrivilegeLevel &&
                  privilegeLevel < menuItem.minimumPrivilegeLevel ? null : (
                    <CustomLink clickLink={clickLink} index={index} menuItem={menuItem} key={index}>
                      <div className="py-4 px-0 relative">
                        {menuItem.icon(
                          menuItem.url && isActive(menuItem.url, path, menuItem.exact)
                            ? '!text-primary-500'
                            : '!text-gray-400'
                        )}
                        {menuItem.url && isActive(menuItem.url, path, menuItem.exact) ? (
                          <ActiveIndicator />
                        ) : null}
                        {menuItem.news && (
                          <div className="absolute top-1 w-full flex-center">
                            <div className="bg-green-500 rounded-full px-2 text-white text-xs">
                              Ny
                            </div>
                          </div>
                        )}
                        <HoverArrow text={menuItem.title} />
                      </div>
                    </CustomLink>
                  )
                )}
            </div>
            <div
              onClick={handleToggleSupport}
              className="text-gray-400 w-full flex-center py-6 text-xl hover:text-gray-700 cursor-pointer hover:bg-gray-100"
            >
              <MdLiveHelp />
            </div>
          </div>
        )
      }}
    </SidebarMenuContext.Consumer>
  )
}

export default withRouter(SidebarMenu)
