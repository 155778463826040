import gql from 'graphql-tag'
export default gql`
  query absenceCodes($token: String!, $searchPhrase: String, $limit: Int) {
    viewer(token: $token) {
      id
      ipAbsenceCodes(searchPhrase: $searchPhrase, limit: $limit) {
        id
        name
      }
    }
  }
`
