import * as React from 'react'
import { Button, Dialog, DialogTitle } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/SupervisorAccount'
import ReplayIcon from '@material-ui/icons/Send'
import get from 'lodash.get'
import { INotificaion } from 'models/interfaces'
import { Translation } from 'react-i18next'

export interface Props {
  notification: INotificaion
  handleRemove: (id: number) => void
  handleUpdateTimestamp: any
  openPersonTimestampsDialog: any
}
export interface State {
  removeNotificationDialog: boolean
}

class NotificationButtons extends React.Component<Props, State> {
  state = {
    removeNotificationDialog: false,
    selectedNotification: undefined
  }

  render() {
    const {
      notification,
      handleRemove,
      handleUpdateTimestamp,
      openPersonTimestampsDialog
    } = this.props
    const { removeNotificationDialog } = this.state

    const closeNotificationRemoveDialog = () => this.setState({ removeNotificationDialog: false })
    const openNotificationRemoveDialog = n => this.setState({ removeNotificationDialog: true })
    const removeNotification = () => {
      handleRemove(notification.id)
      closeNotificationRemoveDialog()
    }

    const ipPerson = get(notification, 'timestamp.ipPerson', undefined)
    return (
      <Translation>
        {t => {
          const removeActions = [
            <Button color="primary" key={1} onClick={removeNotification}>
              {t('general.confirm')}
            </Button>,
            <Button color="secondary" key={0} onClick={closeNotificationRemoveDialog}>
              {t('general.cancel')}
            </Button>
          ]
          return (
            <>
              <Dialog
                title={t('NotificationButtons.removeNotification')}
                open={removeNotificationDialog}
                onClose={closeNotificationRemoveDialog}
              >
                <DialogTitle id="simple-dialog-title">
                  {t('NotificationButtons.removeConfirmation')}
                </DialogTitle>

                {removeActions}
              </Dialog>
              <IconButton onClick={openNotificationRemoveDialog}>
                <DeleteIcon className="sm:!h-6 sm:!w-6 !text-gray-300 !w-5 !h-5" />
              </IconButton>
              {ipPerson ? (
                <IconButton onClick={() => openPersonTimestampsDialog(ipPerson)}>
                  <PersonIcon className="sm:!h-6 sm:!w-6 !text-gray-300 !w-5 !h-5" />
                </IconButton>
              ) : null}
              <IconButton onClick={() => handleUpdateTimestamp(notification.timestamp.id)}>
                <ReplayIcon className="sm:!h-6 sm:!w-6 !text-gray-300 !w-5 !h-5" />
              </IconButton>
            </>
          )
        }}
      </Translation>
    )
  }
}

export default NotificationButtons
