import gql from 'graphql-tag'

export const notificationTypeCountQuery = gql`
  query notificationTypeCount(
    $token: String!
    $from: Date
    $to: Date
    $excludeOrganisationIds: [Int]
  ) {
    viewer(token: $token) {
      id
      summary {
        countPerNotificationType(
          from: $from
          to: $to
          excludeOrganisationIds: $excludeOrganisationIds
        ) {
          type
          count
        }
      }
    }
  }
`
