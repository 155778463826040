import * as React from 'react'
import ActionBase from 'components/Timeline/WorkdayActions/ActionBase/ActionBase'
import updateTimestamp from 'graphql/mutations/mutationUpdateTimestamp'
import { getLocalStorageToken } from 'store/localStorage'
import { TimestampDirectionEnum } from 'types/graphql-global-types'
import { useMutation } from '@apollo/client'
import {
  updateTimestamp as updateTimestampType,
  updateTimestampVariables
} from 'graphql/mutations/types/updateTimestamp'
export interface Props {
  text: string
  timestampId: number
  direction: TimestampDirectionEnum
}
export interface State {}

const TimestampMarkerDirectionAction: React.FC<Props> = ({ text, timestampId, direction }) => {
  const updateTimestampDirection = (
    mutate: (data: any) => void,
    id: number,
    direction: TimestampDirectionEnum
  ) => () =>
    mutate({
      variables: {
        token: getLocalStorageToken(),
        id,
        direction:
          direction === TimestampDirectionEnum.In
            ? TimestampDirectionEnum.Out
            : TimestampDirectionEnum.In
      }
    })

  const [mutation] = useMutation<updateTimestampType, updateTimestampVariables>(updateTimestamp, {
    refetchQueries: ['queryPersonWorkdays', 'notificationsList', 'notificationsPaginated']
  })

  return (
    <ActionBase
      text={text}
      onClick={updateTimestampDirection(mutation, timestampId, direction)}
      isActive={true}
    />
  )
}

export default TimestampMarkerDirectionAction
