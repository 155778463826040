import { useMutation, useQuery } from '@apollo/client'
import ActionPanel from 'base-components/ActionPanel'
import { FleximeLoader } from 'components/FleximeLoader/FleximeLoader'
import { format, subHours } from 'date-fns'
import { isBefore } from 'date-fns/fp'
import queryPersonTimestamps from 'graphql/queries/queryPersonTimestamps'
import {
  personTimestamps,
  personTimestampsVariables,
  personTimestamps_viewer_paginatedTimestamps_posts
} from 'graphql/queries/types/personTimestamps'
import { useOrganisations } from 'hooks/useOrganisations'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalStorageToken } from 'store/localStorage'
import { groupArrayBy } from 'utils/groupArrayBy'
import { notificationsPaginatedQuery } from '../graphql/queries/queryNotificationsPaginated'
import {
  notificationsPaginated,
  notificationsPaginatedVariables,
  notificationsPaginated_viewer_notificationsPaginated_posts,
  notificationsPaginated_viewer_notificationsPaginated_posts_timestamp
} from '../graphql/queries/types/notificationsPaginated'
import InfoIcon from '@material-ui/icons/Info'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton } from '@material-ui/core'
import { useConfirmDialog } from 'hooks/useConfirmDialog'
import mutationRemoveNotification from 'graphql/mutations/mutationRemoveNotification'
import toast from 'react-hot-toast'
import TablePageNumbering from 'components/Table/components/TablePageNumbering/TablePageNumbering'
import { NotificationTypeEnum, TimestampDirectionEnum } from 'types/graphql-global-types'
import {
  removeNotification,
  removeNotificationVariables
} from 'graphql/mutations/types/removeNotification'

interface Props {
  dateFrom: Date
  dateTo: Date
  notificationType: NotificationTypeEnum
  title: string
  description: string
}

const NotificationAbsenceInfo: React.FC<{
  notification: notificationsPaginated_viewer_notificationsPaginated_posts
  timestamp: notificationsPaginated_viewer_notificationsPaginated_posts_timestamp
  onDelete: (notificationId: number) => void
}> = ({ timestamp, onDelete, notification }) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const { t } = useTranslation()

  const { data: timestampsData, loading: loadingTimestamps } = useQuery<
    personTimestamps,
    personTimestampsVariables
  >(queryPersonTimestamps, {
    variables: {
      token: getLocalStorageToken(),
      ipPersonId: timestamp.ipPerson?.id ?? 0,
      first: 0,
      after: 15,
      dateFrom: subHours(timestamp.timeStamped, 24),
      dateTo: timestamp.timeStamped
    },
    skip: timestamp.ipPerson == null || !expanded
  })

  const correctAbsTimestamps = useMemo(() => {
    const timestamps = (timestampsData?.viewer?.paginatedTimestamps?.posts ?? []).filter(
      timestamp => timestamp != null
    ) as personTimestamps_viewer_paginatedTimestamps_posts[]

    const sortedTimestamps = timestamps.sort((a, b) =>
      isBefore(b.timeStamped, a.timeStamped) ? -1 : 1
    )
    let i = sortedTimestamps.length - 1
    for (; i > 0; i--) {
      if (
        sortedTimestamps[i - 1].direction !== TimestampDirectionEnum.BreakStart &&
        sortedTimestamps[i - 1].direction !== TimestampDirectionEnum.BreakEnd
      ) {
        break
      }
    }
    const temp = sortedTimestamps.slice(i)

    return temp.map((timestamp, index) => (
      <div key={timestamp?.id ?? index}>
        {format(timestamp?.timeStamped, 'yyyy-MM-dd HH:mm')}{' '}
        {typeof timestamp?.direction !== 'undefined'
          ? t(`TimestampDirection.${timestamp?.direction}`)
          : t('TimestampDirection.Unknown')}
      </div>
    ))
  }, [timestampsData, t])
  const formattedDate = format(timestamp.timeStamped, 'yyyy-MM-dd HH:mm')

  return (
    <div className="ml-4 my-2">
      <div className="w-full flex flex-row items-center">
        <div
          className="flex flex-row cursor-pointer items-center"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          <div className="mr-2">{t('NotificationAbsenceInfo.body1', { formattedDate })}</div>
        </div>
        <IconButton size="small" onClick={() => onDelete(notification.id)}>
          <DeleteIcon />
        </IconButton>
      </div>
      {expanded ? (
        <div className="ml-8 relative">
          {t('general.absenceTimestamps')}:
          {loadingTimestamps ? <FleximeLoader size={32} /> : correctAbsTimestamps}
        </div>
      ) : null}
    </div>
  )
}

const NotificationsPerPerson: React.FC<{
  notifications: notificationsPaginated_viewer_notificationsPaginated_posts[]
  onDelete: (notificationId: number) => void
}> = ({ notifications, onDelete }) => {
  const sortedNotifications = useMemo(
    () =>
      notifications
        .filter(noti => typeof noti.timestamp?.timeStamped !== 'undefined')
        .sort((a, b) => (isBefore(a.timestamp?.timeStamped, b.timestamp?.timeStamped) ? -1 : 1)),
    [notifications]
  )
  return (
    <div>
      {sortedNotifications.map(notification => {
        if (notification.timestamp != null) {
          return (
            <NotificationAbsenceInfo
              key={`notification_${notification.id}`}
              notification={notification}
              timestamp={notification.timestamp}
              onDelete={onDelete}
            />
          )
        } else {
          return null
        }
      })}
    </div>
  )
}

export const NotificationIncorrectAbsence: React.FC<Props> = ({
  dateFrom,
  dateTo,
  notificationType,
  description,
  title
}) => {
  const { excludeOrganisationIds } = useOrganisations()
  const { t } = useTranslation()
  const after = 10
  const [first, setFirst] = useState<number>(0)
  const { showDialog, dialogComponent } = useConfirmDialog()
  const { data: notificationsData, loading } = useQuery<
    notificationsPaginated,
    notificationsPaginatedVariables
  >(notificationsPaginatedQuery, {
    variables: {
      token: getLocalStorageToken(),
      excludeOrganisationIds,
      first,
      after,
      dateFrom: dateFrom.valueOf(),
      dateTo: dateTo.valueOf(),
      type: notificationType,
      orderBy: 'createdAt',
      sortOrder: 'DESC'
    },
    skip: excludeOrganisationIds === null,
    fetchPolicy: 'cache-and-network'
  })
  const [removeNotification] = useMutation<removeNotification, removeNotificationVariables>(
    mutationRemoveNotification
  )
  const notificationsPerPerson = groupArrayBy<
    notificationsPaginated_viewer_notificationsPaginated_posts
  >(notificationsData?.viewer?.notificationsPaginated?.posts ?? [], 'timestamp.ipPerson.id')

  let currentPage = notificationsData?.viewer?.notificationsPaginated?.paginationInfo?.currentPage
  const nrOfPages = notificationsData?.viewer?.notificationsPaginated?.paginationInfo?.totalPages

  const onDelete = (notificationId: number) => {
    showDialog({
      title: t('NewNotifications.NotificationIncorrectAbsence.removeDialog.title'),
      body: t('NewNotifications.NotificationIncorrectAbsence.removeDialog.body'),
      confirm: {
        text: t('general.yes'),
        callback: async () => {
          toast.promise(
            removeNotification({
              variables: {
                token: getLocalStorageToken(),
                id: notificationId
              },
              refetchQueries: [
                'notificationsTree',
                'notificationGroups',
                'notificationGroup',
                'notificationsList',
                'notificationsPaginated'
              ]
            }),
            {
              success: t('NotificationListItem.notificationRemoved').toString(),
              error: t('NotificationListItem.notificationRemoveFailed').toString(),
              loading: t('NotificationListItem.notificationRemoving').toString()
            }
          )
        }
      }
    })
  }
  return (
    <>
      {dialogComponent}
      <ActionPanel
        className="mt-8"
        title={t(title)}
        content={
          <>
            <div className="mb-8">
              <div className="flex flex-row items-center mb-4">
                <div className="mr-2">
                  <InfoIcon />
                </div>
                <div className="font-bold">
                  {t('NewNotifications.IncorrectNrOfTimestamps.component.body1')}
                </div>
              </div>
              <div className="mb-4">
                {t('NewNotifications.IncorrectNrOfTimestamps.component.body2')}
              </div>
              <div className="whitespace-pre-line">{t(description)}</div>
            </div>
            <div className="my-2">
              {t('NewNotifications.IncorrectNrOfTimestamps.component.body3', {
                count:
                  notificationsData?.viewer?.notificationsPaginated?.paginationInfo?.totalCount ?? 0
              })}
            </div>
            <div className={`relative ${loading ? 'pb-24' : ''}`}>
              {loading ? (
                <FleximeLoader size={32} />
              ) : (
                Object.keys(notificationsPerPerson).map(ipPersonIdStr => {
                  const ipPerson = notificationsPerPerson[ipPersonIdStr][0].timestamp?.ipPerson
                  const personName =
                    typeof ipPerson !== 'undefined'
                      ? `${ipPerson?.firstName} ${ipPerson?.surName}`
                      : t('general.unknownPerson')
                  return (
                    <div key={ipPersonIdStr}>
                      {personName} ({ipPersonIdStr})
                      <NotificationsPerPerson
                        notifications={notificationsPerPerson[ipPersonIdStr]}
                        onDelete={onDelete}
                      />
                    </div>
                  )
                })
              )}
            </div>
            <div>
              <TablePageNumbering
                currentPage={currentPage != null ? currentPage - 1 : 0}
                nrOfPages={nrOfPages ?? 1}
                onPageClick={(pageNr: number) => {
                  setFirst((pageNr - 1) * after)
                }}
                hideGoToLast={false}
              />
            </div>
          </>
        }
      />
    </>
  )
}
