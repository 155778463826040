import * as React from 'react'

export interface Props {
  isPunched: boolean
  alignRight?: boolean
}
export interface State {}

class WorkdayPunch extends React.Component<Props, State> {
  render() {
    const { isPunched, alignRight } = this.props

    return (
      <div
        className={[
          'flex h-full flex-grow justify-start items-center',
          alignRight ? '!justify-end' : null
        ].join(' ')}
      >
        <div className="flex-center w-[30px] h-full">
          <div
            className={[
              `punch w-[10px] h-[10px] rounded-[50%] border border-white bg-transparent`,
              isPunched ? `punchFilled !bg-white` : ''
            ].join(' ')}
          />
        </div>
      </div>
    )
  }
}

export default WorkdayPunch
