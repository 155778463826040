import * as React from 'react'
import { TextField } from '@material-ui/core'
import { Translation } from 'react-i18next'
import Button from 'base-components/Button'

export interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  currentPasswordVal: string
  newPasswordVal: string
  repeatedPasswordVal: string
}
export interface State {}

export default class PasswordChanger extends React.Component<Props, State> {
  render() {
    const {
      onChange,
      onSubmit,
      currentPasswordVal,
      newPasswordVal,
      repeatedPasswordVal
    } = this.props
    const disabled =
      !Boolean(currentPasswordVal) || !Boolean(newPasswordVal) || !Boolean(repeatedPasswordVal)
    return (
      <Translation>
        {t => (
          <form onSubmit={onSubmit} className="max-w-sm">
            <div className="font-semibold">{t('general.changePassword')}</div>

            <TextField
              name="currentPassword"
              type="password"
              label={t('PasswordChanger.currentPassword')}
              onChange={onChange}
              value={currentPasswordVal}
              className="!w-auto sm:!w-[95%] min-w-[230px]"
            />

            <TextField
              name="newPassword"
              type="password"
              label={t('PasswordChanger.newPassword')}
              onChange={onChange}
              value={newPasswordVal}
              className="!w-auto sm:!w-[95%] min-w-[230px]"
            />

            <TextField
              name="repeatedPassword"
              type="password"
              label={t('PasswordChanger.repeatedPassword')}
              onChange={onChange}
              value={repeatedPasswordVal}
              className="!w-auto sm:!w-[95%] min-w-[230px]"
            />
            <div className="mt-4">
              <Button disabled={disabled}>{t('general.save')}</Button>
            </div>
          </form>
        )}
      </Translation>
    )
  }
}
