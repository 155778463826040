import * as React from 'react'
import { IconButton } from '@material-ui/core'

export const DialogCloseButton: React.FC = props => (
  <IconButton className="!absolute !top-[5px] !right-[5px]" {...props} />
)

export const DialogContent: React.FC<{ className: string }> = ({ className, ...props }) => (
  <div className={`mt-[50px] ${className}`} {...props} />
)
