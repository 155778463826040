import React, { ChangeEvent, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FleximeLoader } from 'components/FleximeLoader/FleximeLoader'
import { getLocalStorageToken } from 'store/localStorage'
import { notificationsPaginatedQuery } from '../graphql/queries/queryNotificationsPaginated'
import {
  notificationsPaginated,
  notificationsPaginatedVariables,
  notificationsPaginated_viewer_notificationsPaginated_posts
} from '../graphql/queries/types/notificationsPaginated'
import NotificationListItem from './NotificationListItem'
import { groupArrayBy } from 'utils/groupArrayBy'
import { format } from 'date-fns'
import TablePageNumbering from 'components/Table/components/TablePageNumbering/TablePageNumbering'
import PersonTimestampsContainer from 'containers/PersonTimestamps/PersonTimestampsContainer'
import { useOrganisations } from 'hooks/useOrganisations'
import { BiCalendarAlt } from 'react-icons/bi'
import Checkbox from 'base-components/Checkbox'
import Button from 'base-components/Button'
import { useList } from 'react-use'
import { useCallback } from 'react'
import mutationUpdateTimestamps from '../graphql/mutations/mutationUpdateTimestamps'
import {
  updateTimestamps,
  updateTimestampsVariables
} from '../graphql/mutations/types/updateTimestamps'
import moment from 'moment'
import toast from 'react-hot-toast'
import {
  removeNotificationsVariables,
  removeNotifications
} from '../graphql/mutations/types/removeNotifications'
import mutationRemoveNotifications from '../graphql/mutations/mutationRemoveNotifications'
import { useTranslation } from 'react-i18next'
import { NotificationTypeEnum } from 'types/graphql-global-types'
import GeneralSearchPrefetched from 'components/GeneralSearch/GeneralSearchPrefetched'
interface Props {
  notificationType: NotificationTypeEnum
  dateFrom: Date
  dateTo: Date
}

export const NotificationsTimelineList: React.FC<Props> = ({
  dateFrom,
  dateTo,
  notificationType
}) => {
  const after = 10
  const [first, setFirst] = useState<number>(0)
  const [personTimestampsDialog, setPersonTimestampsDialog] = useState(false)
  const [selectedTimestampsPerson, setSelectedTimestampsPerson] = useState<number | null>(null)
  const [selectedPerson, setSelectedPerson] = useState<{ id: number; title: string } | null>(null)
  const [selectedTerminal, setSelectedTerminal] = useState<{ id: number; title: string } | null>(
    null
  )
  const { excludeOrganisationIds } = useOrganisations()
  const [list, { set, push, filter }] = useList<number>([])
  const { t } = useTranslation()
  const { data: notificationsData, loading } = useQuery<
    notificationsPaginated,
    notificationsPaginatedVariables
  >(notificationsPaginatedQuery, {
    variables: {
      token: getLocalStorageToken(),
      excludeOrganisationIds,
      first,
      after,
      dateFrom: dateFrom.valueOf(),
      dateTo: dateTo.valueOf(),
      type: notificationType,
      orderBy: 'createdAt',
      sortOrder: 'DESC',
      terminalId: selectedTerminal?.id ?? undefined,
      ipPersonId: selectedPerson?.id ?? undefined
    },
    skip: excludeOrganisationIds === null,
    fetchPolicy: 'cache-and-network'
  })

  const notificationsByDay: {
    [key: string]: notificationsPaginated_viewer_notificationsPaginated_posts[]
  } = useMemo(() => {
    const tempNot: notificationsPaginated_viewer_notificationsPaginated_posts[] = []

    for (const notification of notificationsData?.viewer?.notificationsPaginated?.posts ?? []) {
      // Hate this
      tempNot.push({ ...notification } as any)
    }
    return groupArrayBy(tempNot, 'timestamp.timeStamped', (value: any) => {
      try {
        const res = format(new Date(value), 'dd/MM')
        return res
      } catch (err) {
        return value
      }
    })
  }, [notificationsData?.viewer?.notificationsPaginated?.posts])
  const empty = Object.keys(notificationsByDay).length === 0

  const [updateTimestamps] = useMutation<updateTimestamps, updateTimestampsVariables>(
    mutationUpdateTimestamps
  )
  const [removeNotifications] = useMutation<removeNotifications, removeNotificationsVariables>(
    mutationRemoveNotifications
  )
  const currentPage = notificationsData?.viewer?.notificationsPaginated?.paginationInfo?.currentPage
  const nrOfPages = notificationsData?.viewer?.notificationsPaginated?.paginationInfo?.totalPages
  const openPersonTimestampsDialog = ipPerson => {
    setSelectedTimestampsPerson(ipPerson)
    setPersonTimestampsDialog(true)
  }
  const closePersonTimestampsDialog = () => {
    setSelectedTimestampsPerson(null)
    setPersonTimestampsDialog(false)
  }
  const handleCheckboxChange = useCallback(
    id => (val: ChangeEvent<HTMLInputElement>) => {
      if (val.target.checked && !list.find(i => i === id)) {
        push(id)
      } else {
        filter(i => i !== id)
      }
    },
    [list, push, filter]
  )
  const handleSelectAll = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const notificationIds =
          notificationsData?.viewer?.notificationsPaginated?.posts?.map(n => n.id) ?? []
        set(notificationIds)
      } else {
        set([])
      }
    },
    [notificationsData?.viewer?.notificationsPaginated?.posts, set]
  )
  const handleResendTimestamps = async () => {
    await toast.promise(
      updateTimestamps({
        variables: {
          token: getLocalStorageToken(),
          updateTimestampsData: list.map(nid => ({
            id:
              notificationsData?.viewer?.notificationsPaginated?.posts?.find(p => p.id === nid)
                ?.timestamp?.id ?? -1,
            timeSend: moment().valueOf(),
            sendAttempts: 5
          }))
        }
      }),
      {
        success: (t('NotificationListItem.notificationResend') as string) ?? '',
        error: (t('NotificationListItem.notificationResendFailed') as string) ?? '',
        loading: (t('general.loading') as string) ?? ''
      }
    )
    set([])
  }
  const handleRemoveNotifications = async () => {
    await toast.promise(
      removeNotifications({
        variables: { token: getLocalStorageToken(), ids: list, updateTimestampTimeSent: true },
        refetchQueries: ['notificationsPaginated']
      }),
      {
        success: (t('NotificationListItem.notificationRemoved') as string) ?? '',
        error: (t('NotificationListItem.notificationRemoveFailed') as string) ?? '',
        loading: (t('general.loading') as string) ?? ''
      }
    )
    set([])
  }
  if (!loading && empty && selectedPerson === null && selectedTerminal === null) {
    return <div className="py-8 text-lg">{t('NewNotifications.noNotifications')} 🎉</div>
  }
  return (
    <>
      {personTimestampsDialog && (
        <PersonTimestampsContainer
          open={personTimestampsDialog}
          ipPerson={selectedTimestampsPerson}
          onClose={closePersonTimestampsDialog}
        />
      )}
      <div>
        <div className="bg-white rounded-xl p-4 my-4 max-w-full">
          <div className="text-sm font-semibold mb-2">Filter</div>
          <div className="flex flex-row">
            <div className="mx-2">
              <GeneralSearchPrefetched
                label="Person"
                size="small"
                onChange={item =>
                  setSelectedPerson(
                    typeof item?.item.id !== 'undefined'
                      ? { id: item?.item.id, title: item.title }
                      : null
                  )
                }
                prefetchedResult={notificationsData?.viewer?.notificationTypeMeta?.ipPersons?.map(
                  person => ({ item: person, title: `${person.firstName} ${person.surName}` })
                )}
                value={selectedPerson}
              />
            </div>
            <div className="mx-2">
              <GeneralSearchPrefetched
                label="Terminal"
                size="small"
                value={selectedTerminal}
                onChange={item =>
                  setSelectedTerminal(
                    typeof item?.item.id !== 'undefined'
                      ? { id: item.item.id, title: item.title }
                      : null
                  )
                }
                prefetchedResult={notificationsData?.viewer?.notificationTypeMeta?.terminals?.map(
                  term => ({ item: term, title: `${term.description}` })
                )}
              />
            </div>
          </div>
          <div className="mt-4 flex items-center justify-start text-gray-800 text-sm">
            <BiCalendarAlt />
            <span className="ml-1">
              {notificationsData?.viewer?.notificationsPaginated?.paginationInfo?.totalCount ?? 0}{' '}
              {t('general.time', {
                count:
                  notificationsData?.viewer?.notificationsPaginated?.paginationInfo?.totalCount ?? 0
              }).toLowerCase()}
            </span>
          </div>
        </div>
        {loading ? (
          <div className="relative w-72 h-72">
            <FleximeLoader size={32} />
          </div>
        ) : (
          <div className="bg-white rounded-xl overflow-hidden">
            <div className="ml-4 mt-4 mb-4 flex">
              <Checkbox
                label={t('general.selectAll')}
                checked={
                  list.length === notificationsData?.viewer?.notificationsPaginated?.posts?.length
                }
                onChange={handleSelectAll}
              />
              {Boolean(list.length) && (
                <div className="ml-4">
                  <span className="mx-2 text-sm">({list.length} markerade)</span>
                  <Button variant="secondary" onClick={handleResendTimestamps}>
                    {t('general.try_again')}
                  </Button>
                  <Button
                    variant="secondary"
                    color="red"
                    className="ml-2"
                    onClick={handleRemoveNotifications}
                  >
                    {t('general.remove')}
                  </Button>
                </div>
              )}
            </div>
            {Object.keys(notificationsByDay).map(dayStr => {
              return (
                <div key={dayStr}>
                  {/* <div className="w-full bg-gray-100 mt-4 text-sm -mb-1">{dayStr}</div> */}
                  {notificationsByDay[dayStr].map(notification => {
                    return (
                      <div key={notification.id} className="flex flex-row">
                        <div className="flex-center ml-4">
                          <Checkbox
                            checked={Boolean(list.find(i => notification.id === i))}
                            onChange={handleCheckboxChange(notification.id)}
                          />
                        </div>
                        <NotificationListItem
                          key={notification.id}
                          notification={notification}
                          openPersonTimestampsDialog={openPersonTimestampsDialog}
                        />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )}
        {!loading && (
          <div className="bg-white p-2 rounded-xl my-4">
            <TablePageNumbering
              currentPage={currentPage != null ? currentPage - 1 : 0}
              nrOfPages={nrOfPages ?? 1}
              onPageClick={(pageNr: number) => {
                setFirst((pageNr - 1) * after)
                set([])
              }}
              hideGoToLast={false}
            />
          </div>
        )}
      </div>
    </>
  )
}
