import * as React from 'react'

interface OrganisationsProviderValue {
  excludeOrganisationIds: number[] | null
  actions: {
    setExcludedOrganisationsIds: (orgIds: number[]) => void
  }
}

const OrganisationsContext = React.createContext<OrganisationsProviderValue | null>(null)

const OrganisationsProvider: React.FC<{}> = props => {
  const [excludeOrganisationIds, setExcludedOrganisationsIds] = React.useState<number[] | null>(
    null
  )
  const actions = React.useMemo(() => {
    return { setExcludedOrganisationsIds }
  }, [])

  return (
    <OrganisationsContext.Provider value={{ excludeOrganisationIds, actions }}>
      {props.children}
    </OrganisationsContext.Provider>
  )
}

export { OrganisationsProvider, OrganisationsContext }
