import React, { createContext, useEffect, useState, useMemo } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'

interface ContextType {
  isLoaded: boolean
  loadError: Error | undefined
}
const GoogleMapsLoadedContext = createContext<ContextType>({
  isLoaded: false,
  loadError: undefined
})

const GoogleMapsLoadedProvider: React.FC<{}> = props => {
  const [googleMapsLoadedInfo, setGoogleMapsLoadedInfo] = useState<ContextType>({
    isLoaded: false,
    loadError: undefined
  })

  const libraries: Libraries = useMemo(() => ['drawing', 'places'], [])
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBAuPgEFy4GVPydmm3dCg_WZ0mFgFwR_Sk',
    libraries
  })

  useEffect(() => {
    setGoogleMapsLoadedInfo({ isLoaded, loadError })
  }, [isLoaded, loadError])
  return (
    <GoogleMapsLoadedContext.Provider value={{ ...googleMapsLoadedInfo }}>
      {props.children}
    </GoogleMapsLoadedContext.Provider>
  )
}

export { GoogleMapsLoadedProvider, GoogleMapsLoadedContext }
