import React, { useCallback } from 'react'
import moment from 'moment-timezone'
import { ITimestampRule, IIpWorkday, ITimestamp } from '../../../models/interfaces'
import ActionBase from 'components/Timeline/WorkdayActions/ActionBase/ActionBase'
import { useTranslation } from 'react-i18next'
import { TimestampDirectionEnum } from 'types/graphql-global-types'

export interface Props {
  innerRules: ITimestampRule[]
  outerRules: ITimestampRule[]
  ipOrderId: number
  timestamp: ITimestamp
  workday: IIpWorkday
}

export const ActionNoRules: React.FC<Props> = props => {
  const { t } = useTranslation()
  const onClick = useCallback(() => {
    var win = window.open(`/rules?selectedOrder=${props.ipOrderId}`, '_blank')
    win?.focus()
  }, [props.ipOrderId])

  const isActive = () => {
    const { innerRules, outerRules, workday, timestamp } = props
    const from = moment(workday.originalDateFrom || workday.dateFrom)
    const to = moment(workday.originalDateTo || workday.dateTo)
    const timeStamped = moment(timestamp.timeStamped)

    let active = false
    let directionText = ''
    let positionText = ''

    switch (timestamp.direction) {
      case TimestampDirectionEnum.In:
        directionText = t('general.in').toLowerCase()
        break
      case TimestampDirectionEnum.Out:
        directionText = t('general.out').toLowerCase()
        break
      default:
        break
    }
    if (timeStamped > from && timeStamped < to) {
      active = innerRules.length === 0
      positionText = t('ActionNoRules.within')
    } else {
      if (timeStamped < from) {
        positionText = t('general.before')
        if (timestamp.direction === TimestampDirectionEnum.In) {
          active = outerRules.length === 0
        } else if (timestamp.direction === TimestampDirectionEnum.Out) {
          active = innerRules.length === 0
        }
      } else {
        positionText = t('general.after')
        if (timestamp.direction === TimestampDirectionEnum.In) {
          active = innerRules.length === 0
        } else if (timestamp.direction === TimestampDirectionEnum.Out) {
          active = outerRules.length === 0
        }
      }
    }
    return {
      active,
      text: t('ActionNoRules.body', { directionText, positionText })
    }
  }

  const { active, text } = isActive()
  return <ActionBase text={text} onClick={onClick} isActive={active} />
}

export default ActionNoRules
