/* eslint-disable */
import * as Sentry from '@sentry/browser'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import 'core-js/stable'
import firebase from 'firebase/app'
import 'isomorphic-fetch'
import * as jwtDecode from 'jwt-decode'
import get from 'lodash.get'
import React from 'react'
import ReactDOM from 'react-dom'
import { getLocalStorageToken } from 'store/localStorage'
import { getClient } from './client'
import AppContainer from './containers/AppContainer'
import 'styles/tailwind.output.css'
import * as serviceWorker from './serviceWorker'
import { getStore } from './store'
import './utils/i18/i18n'
/* eslint-enable */
const Provider = require('react-redux').Provider

const __BACKEND_ADDRESS__ = process.env.REACT_APP__BACKEND_ADDRESS__
const __PROD__ = process.env.NODE_ENV === 'production'
const __TEST__ = process.env.NODE_ENV === 'test'

if (__PROD__) {
  Sentry.init({
    dsn: 'https://a138ec56082540ae9758b175e1149aa7@sentry.io/1742566',
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
  })

  Sentry.configureScope(scope => {
    const token = getLocalStorageToken()
    if (token) {
      const username = get(jwtDecode(token), 'user.username', null)
      if (username) {
        scope.setUser({ username })
      }
    }
  })
}
if (!firebase.apps.find(app => app.name === '[DEFAULT]')) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBTCoib2YliY4PvxD3Mg0K_0frGPySWjg0',
    authDomain: 'frontend-282515.firebaseapp.com',
    databaseURL: 'https://frontend-282515.firebaseio.com',
    projectId: 'frontend-282515',
    storageBucket: 'frontend-282515.appspot.com',
    messagingSenderId: '317729564959',
    appId: '1:317729564959:web:f644d9b4600523939da82a',
    measurementId: 'G-4WL89WV28X'
  })
}
const backendUrls = {
  development: 'http://localhost:8080/graphql',
  test: 'https://dev.flexime.io/graphql',
  production: __BACKEND_ADDRESS__ ? __BACKEND_ADDRESS__ : 'http://46.21.109.69:3000/graphql'
}
const store = getStore()
export let BACKEND_URL: string | undefined = undefined
try {
  if (__PROD__ && __BACKEND_ADDRESS__) {
    BACKEND_URL = backendUrls['production']
  } else if (__PROD__) {
    BACKEND_URL = `https://${window.location.hostname}/graphql`
  } else if (__TEST__) {
    BACKEND_URL = backendUrls['test']
  } else if (!__PROD__ && !__TEST__) {
    BACKEND_URL = backendUrls['development']
  }
} catch (err) {
  console.error('Error trying to get backend URL: [', err, '] using localhost url')
  BACKEND_URL = backendUrls['development']
}
ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <AppContainer store={store} client={getClient(BACKEND_URL)} />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root') as HTMLElement
)
// registerServiceWorker();
serviceWorker.unregister()
