import * as React from 'react'
import { Button, Dialog, DialogTitle, Select, MenuItem } from '@material-ui/core'
import { Translation } from 'react-i18next'

export interface SelectOption {
  name: string
  value: any
}

export interface Props {
  title: string
  options: SelectOption[]
  startValue?: any
  onSave: (id: number) => void
  showModal: boolean
  setShowModal: (show: boolean) => void
}
export interface State {
  selectedValue: any
}

class SelectDialog extends React.Component<Props, State> {
  state = {
    selectedValue: this.props.startValue
  }

  private saveAndClose = (value: any) => {
    this.props.onSave(value)
    this.props.setShowModal(false)
  }
  render() {
    const { title, startValue, options, showModal, setShowModal } = this.props
    const { selectedValue } = this.state
    return (
      <Translation>
        {t => (
          <Dialog
            onClose={() => setShowModal(false)}
            open={showModal}
            classes={{ paper: 'p-4 px-8' }}
          >
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>

            <div>
              <Select
                value={selectedValue}
                onChange={event => this.setState({ selectedValue: event.target.value })}
              >
                {options.map(option => (
                  <MenuItem value={option.value} key={option.value}>
                    {option.name}{' '}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={`flex justify-end mt-8`}>
              <Button color="primary" onClick={() => setShowModal(false)}>
                {t('general.cancel')}
              </Button>
              <Button
                color="secondary"
                disabled={selectedValue === startValue}
                onClick={() => this.saveAndClose(selectedValue)}
              >
                {t('general.save')}
              </Button>
            </div>
          </Dialog>
        )}
      </Translation>
    )
  }
}

export default SelectDialog
