import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import UnreadIcon from '@material-ui/icons/Markunread'
import { WhatsNewType, whatsNew } from 'whatsNew'
import { useTranslation } from 'react-i18next'

export const WhatsNewDialog: React.FC<{}> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [buttonIsVisible, setButtonIsVisible] = useState<boolean>(false)
  const [lastWhatsNew, setLastWhatsNew] = useLocalStorage<string>('lastWhatsNew', '0.0.0')
  const { t } = useTranslation()

  const news: WhatsNewType[] = []
  const lastWhatsNewIndex = whatsNew.findIndex(w => w.version === lastWhatsNew)
  for (let i = lastWhatsNewIndex !== -1 ? lastWhatsNewIndex + 1 : 0; i < whatsNew.length; i++) {
    news.push(whatsNew[i])
  }
  const latestWhatsNew = whatsNew[whatsNew.length - 1]

  useEffect(() => {
    setButtonIsVisible(latestWhatsNew.version !== lastWhatsNew)
  }, [lastWhatsNew, latestWhatsNew])

  const close = () => {
    setIsOpen(false)
    setLastWhatsNew(latestWhatsNew.version)
  }
  if (!setButtonIsVisible) {
    return null
  }

  return (
    <>
      {buttonIsVisible && (
        <div className="mx-4">
          <IconButton onClick={() => setIsOpen(true)}>
            <UnreadIcon className="text-gray-400" />
            <span className="flex h-3 w-3 absolute top-1 right-1">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500"></span>
            </span>
          </IconButton>
        </div>
      )}
      <Dialog
        open={isOpen}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t('general.news')}</DialogTitle>
        <DialogContent>
          <div className="mb-4 text-gray-500">{t('WhatsNewDialog.intro')}</div>
          {news.reverse().map(n => {
            return (
              <div key={`whatsnew_version_${n.version}`}>
                <div className="font-extralight text-xl mt-8 mb-4">{n.version}</div>
                {n.entries.map((entry, entryIndex) => {
                  return (
                    <div key={`whatsnew_header_${n.version}_${entryIndex}`}>
                      <div className="font-bold mt-2">{t(entry.header)}</div>
                      {entry.text.map((text, textIndex) => (
                        <div key={`whatsnew_text_${n.version}_${textIndex}`}>{t(text)}</div>
                      ))}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
