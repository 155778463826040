import gql from 'graphql-tag' 
export default gql`
query organisationsList($token: String!, $first: Int, $after: Int, $sortOrder: SortOrder, $orderBy: String, $filterText: String) {
  viewer(token: $token) {
    id
    organisationsPaginated(first: $first, after: $after, sortOrder: $sortOrder, orderBy: $orderBy, filterText: $filterText) {
      paginationInfo {
        id
        totalPages
        pageSize
        currentPage
      }
      posts {
        name
        id
      }
    }
  }
}
`