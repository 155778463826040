import * as React from 'react'
import Organisations from 'components/Organisations/Organisations'
import {
  IOrganisationsConnectedDispatch,
  IOrganisationsConnectedState
} from './organisationsMapProps'
import { IReduxFormProps } from 'models/interfaces'
import { ISearchBarMappedProps } from '../../components/SearchBar/containers/searchBarDecorator'

import { getLocalStorageToken } from 'store/localStorage'
import mutationRemoveOrganisation from 'graphql/mutations/mutationRemoveOrganisation'
import mutationUpdateOrganisation from 'graphql/mutations/mutationUpdateOrganisation'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import {
  removeOrganisation as removeOrganisationType,
  removeOrganisationVariables
} from 'graphql/mutations/types/removeOrganisation'
import {
  updateOrganisation,
  updateOrganisationVariables
} from 'graphql/mutations/types/updateOrganisation'
import { useMutation } from '@apollo/client'
export interface IOrganisationsProps
  extends IOrganisationsConnectedState,
    IOrganisationsConnectedDispatch,
    ISearchBarMappedProps,
    IReduxFormProps<ICreateOrganisationForm> {
  removeOrganisation: (id: number) => void
  updateOrganisationName: (OrganisationId: number, newName: string) => void
}

export interface ICreateOrganisationForm {
  name: string
}

export const ADMIN_ORGANISATIONS_ID = 'organisationsTable'
export const ADMIN_ORGANISATIONS_PAGE_SIZE = 5
/*

export const dataTable = ({ id, pageSize, preSortBy, preSortOrder }: IDataTableDecorator): any => {
  const store = getStore()
  store.dispatch({ type: ADD_NEW_DATA_TABLE, payload: { id, pageSize, preSortBy, preSortOrder } })

  return WrappedComponent => {
    class Table extends React.Component<any, any> {
      render() {
        return createElement(WrappedComponent, { ...this.props })
      }
    }

    const mapStateToProps = (state: AppState): IDataTableMappedProps => ({
      dataTableSortInfo: state.dataTable.get(id).get('sortInfo'),
      dataTablePageInfo: state.dataTable.get(id).get('pageInfo'),
      dataTablePageSize: state.dataTable.get(id).get('pageSize')
    })

    return connect(mapStateToProps)(Table)
  }
}


*/

const OrganisationsContainerClass: React.FC<any> = props => {
  const { t } = useTranslation()
  const [removeOrganisationMutation] = useMutation<
    removeOrganisationType,
    removeOrganisationVariables
  >(mutationRemoveOrganisation, {
    refetchQueries: ['organisationsList', 'adminTerminalsList', 'ipOrders']
  })
  const [updateOrganisationMutation] = useMutation<updateOrganisation, updateOrganisationVariables>(
    mutationUpdateOrganisation,
    { refetchQueries: ['organisationsList', 'adminTerminalsList', 'ipOrders'] }
  )

  const removeOrganisation = async (id: number) => {
    try {
      await removeOrganisationMutation({
        variables: { token: getLocalStorageToken(), id }
      })
      toast.success((t('OrganisationsContainer.organisationRemoved') as string) ?? '')
    } catch (error) {
      toast.error((t('OrganisationsContainer.organisationRemovedFailed') as string) ?? '')
    }
  }

  const updateOrganisationName = async (OrganisationId: number, newName: string) => {
    try {
      updateOrganisationMutation({
        variables: {
          token: getLocalStorageToken(),
          id: OrganisationId,
          name: newName
        }
      })
      toast.success((t('OrganisationsContainer.organisationUpdated') as string) ?? '')
    } catch (error) {
      toast.error((t('OrganisationsContainer.organisationUpdatedFailed') as string) ?? '')
    }
  }

  return <Organisations {...{ ...props, removeOrganisation, updateOrganisationName }} />
}

export const OrganisationsContainer: any = OrganisationsContainerClass
