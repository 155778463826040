import { createImmutableMap, ImmutableMap } from 'models/AppState'
import { ADMIN_CREATE_USER_UPDATE } from './adminUsersMapProps'

export interface IAdminUsersState {
  createUserStatus: { success: boolean; message: string }
}

const initialState = createImmutableMap<IAdminUsersState>({
  createUserStatus: {
    success: false,
    message: ''
  }
})
export function adminUsersReducer(
  state: ImmutableMap<IAdminUsersState> = initialState,
  action: any
) {
  switch (action.type) {
    case ADMIN_CREATE_USER_UPDATE:
      return state.set('createUserStatus', action.payload)
    default:
      return state
  }
}
