import * as React from 'react'

export interface Props {
  style?: any
  color?: 'primaryLight' | 'blue'
  className?: string
}
export interface State {}

class HoverTriangle extends React.Component<Props, State> {
  render() {
    const { style, color = 'primaryLight' } = this.props
    let borderColor = '#908cc1'
    if (color === 'blue') {
      borderColor = '#9DCDD3'
    }
    return (
      <div className="-mt-px flex-center w-full h-[10px]" style={style}>
        <div
          className="w-0 h-0"
          style={{
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: `10px solid ${borderColor}`
          }}
        />
      </div>
    )
  }
}

export default HoverTriangle
