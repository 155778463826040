import * as React from 'react'

export interface Props {
  text?: string | number
  handleChange?: (text: string, name?: string) => void
  handleKeyDown?: (event: any, text: string, setText: (text: string) => void) => boolean
  handleButtonPressed?: (text: string) => boolean
  placeholder?: string
  icon?: string
  type?: string
  name?: string
  width?: string
}
export interface State {
  internalText: string
}

class TextInput extends React.Component<Props, State> {
  state = {
    internalText: ''
  }

  private setText = (text: string) => {
    this.setState({ internalText: text })
  }

  private onChange = (event: any) => {
    const { handleChange } = this.props
    const inputText = event.target.value
    this.setState({ internalText: inputText })
    handleChange && handleChange(inputText, this.props.name)
  }

  private onKeyDown = (event: any) => {
    const { handleKeyDown } = this.props
    if (handleKeyDown) {
      const res = handleKeyDown(event, this.state.internalText, this.setText)
      if (res) {
        this.setState({ internalText: '' })
      }
    }
  }
  private onClick = () => {
    const { handleButtonPressed } = this.props
    if (handleButtonPressed) {
      const res = handleButtonPressed(this.state.internalText)
      if (res) {
        this.setState({ internalText: '' })
      }
    }
  }
  render() {
    const { text, placeholder, icon, type, width } = this.props
    const { internalText } = this.state
    const inputValue = typeof text !== 'undefined' ? text : internalText
    const containerStyle: React.CSSProperties = {}
    typeof width !== 'undefined' && (containerStyle.width = width)
    return (
      <div className="inline-block min-w-[200px]" style={containerStyle}>
        <input
          className={
            'text-gray-800 font-extralight outline-none border-0 w-[90%] text-sm h-[40px] !border-b !border-gray-200 ring-offset-0'
          }
          value={inputValue}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          type={type ? type : 'text'}
        />
        {icon ? (
          <button
            className="border-none text-xl rounded-lg text-gray-100 bg-none mt-[5px]"
            onClick={this.onClick}
            disabled={!inputValue}
            type="submit"
          >
            <i className={`material-icons ml-[5px] mr-[5px] text-gray-400`}>{icon}</i>
          </button>
        ) : null}
      </div>
    )
  }
}

export default TextInput
