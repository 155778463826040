import * as React from 'react'
import HoverTriangle from 'components/HoverTriangle/HoverTriangle'
export interface Props {
  parentHover: boolean
  width: number
  color?: 'blue' | 'primaryLight'
  disableHover?: boolean
  elevated?: boolean
  maxHeight?: number
}
export interface State {
  hover: boolean
}
class TimelineHover extends React.Component<Props, State> {
  state = {
    hover: false
  }
  render() {
    const { children, parentHover, width, color, disableHover, elevated, maxHeight } = this.props

    const { hover } = this.state
    let colorClass
    let colorStyle
    if (color === 'blue') {
      colorClass = 'bg-blue-300'
      colorStyle = {}
    }

    const bodyStyle: React.CSSProperties = {}
    elevated && (bodyStyle.boxShadow = '0px 3px 24px rgba(0,0,0,0.16)')
    maxHeight && (bodyStyle.maxHeight = `${maxHeight}px`)
    return (
      <div
        className={[
          'hidden absolute bottom-full transform -translate-x-1/2 left-[50%]',
          hover || parentHover ? '!block' : null,
          disableHover ? 'pointer-events-none' : null
        ].join(' ')}
        style={{ width: `${width}px` }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <div
          style={{
            ...bodyStyle,
            ...(colorStyle ?? {})
          }}
          className={[
            'bottom-full w-full p-[3px] max-h-[123px] select-none overflow-y-scroll',
            colorClass ?? 'bg-primary-500'
          ].join(' ')}
        >
          {children}
        </div>
        <HoverTriangle color={color} />
      </div>
    )
  }
}

export default TimelineHover
