import * as React from 'react'
import { Button, TextField } from '@material-ui/core'

export const SearchIconBox: React.FC<{ icon: any }> = ({ icon, ...rest }) => (
  <div {...rest} className="pt-1 pr-2">
    <i style={{ color: '#aaa' }} className="material-icons">
      {icon ? icon : 'search'}
    </i>
  </div>
)
export const SearchButton: any = (props: any) => <Button {...props} />

export const SearchTextField: any = (props: any) => <TextField {...props} />

export const SearchBoxWrapper: React.FC<{ style?: any; className?: string }> = ({
  children,
  ...rest
}) => (
  <form
    {...rest}
    className="relative max-w-[400px] flex align-bottom pr-[10px]"
    onSubmit={e => e.preventDefault()}
  >
    {children}
  </form>
)

export const SearchBox: React.FC = props => (
  <div className="flex items-center rounded-[5px]" {...props} />
)
