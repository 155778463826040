import * as React from 'react'
import moment from 'moment-timezone'
import HomeIcon from '@material-ui/icons/Home'
import NetworkProblemIcon from '@material-ui/icons/SignalWifiOff'
import NotificationButtons from './NotificationButtons'
import UnexcpetedErrorIcon from '@material-ui/icons/Error'
import UnexpectedTimestampIcon from '@material-ui/icons/AccessTime'
import UnregisteredCardIcon from '@material-ui/icons/CreditCard'
import NrOfTimestampsIcon from '@material-ui/icons/Input'
import TooLongAbsenceIcon from '@material-ui/icons/FreeBreakfast'
import LockIcon from '@material-ui/icons/Lock'
import UnexpectedTimestampContainer from 'containers/UnexpectedTimestampContainer/UnexpectedTimestampContainer'
import { useMutation } from '@apollo/client'
import ListItem from './ListItem'
import mutationRemoveNotification from 'graphql/mutations/mutationRemoveNotification'
import mutationUpdateTimestamp from 'graphql/mutations/mutationUpdateTimestamp'
import { BiCalendarAlt, BiTimeFive, BiDirections } from 'react-icons/bi'
import { GoPerson } from 'react-icons/go'
import { BsGeo } from 'react-icons/bs'
import { MdComment } from 'react-icons/md'

import { getLocalStorageToken } from 'store/localStorage'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import format from 'date-fns/format'
import { NotificationTypeEnum, TimestampDirectionEnum } from 'types/graphql-global-types'
import { useCallback, useMemo } from 'react'
import { notificationsPaginated_viewer_notificationsPaginated_posts } from '../graphql/queries/types/notificationsPaginated'
import {
  removeNotification,
  removeNotificationVariables
} from 'graphql/mutations/types/removeNotification'
import { updateTimestamp, updateTimestampVariables } from 'graphql/mutations/types/updateTimestamp'

export interface Props {
  notification: notificationsPaginated_viewer_notificationsPaginated_posts | null
  openPersonTimestampsDialog: (ipPerson: any) => void
}
export interface State {}
const NotificationListItem: React.FC<Props> = props => {
  /*const openTerminal = (terminalId: number) => () =>
    window?.open(`/terminals/${terminalId}`, '_blank')?.focus()*/

  const getDirectionText = (
    direction: TimestampDirectionEnum | undefined,
    t: (key: string) => string
  ) => {
    switch (direction) {
      case TimestampDirectionEnum.In:
        return t('general.in').toLowerCase()
      case TimestampDirectionEnum.Out:
        return t('general.out').toLowerCase()
      case TimestampDirectionEnum.OutAbsence:
        return t('general.outAbsence').toLowerCase()
      default:
        return ''
    }
  }

  const { notification, openPersonTimestampsDialog } = props
  const { i18n, t } = useTranslation()

  const [removeNotificationMutation] = useMutation<removeNotification, removeNotificationVariables>(
    mutationRemoveNotification,
    {
      refetchQueries: [
        'notificationGroups',
        'notificationGroup',
        'notificationsList',
        'notificationsPaginated'
      ]
    }
  )
  const [updateTimestampMutation] = useMutation<updateTimestamp, updateTimestampVariables>(
    mutationUpdateTimestamp,
    {
      refetchQueries: [
        'notificationGroups',
        'notificationGroup',
        'notificationsList',
        'notificationsPaginated'
      ]
    }
  )

  const notificationsInfo = useCallback(
    (
      notification: notificationsPaginated_viewer_notificationsPaginated_posts | null,
      handleRemove,
      handleUpdateTimestamp,
      openPersonTimestampsDialog
    ) => {
      let Icon: any = HomeIcon
      let label = ''
      let body: JSX.Element | string = ''
      let lowerBody: JSX.Element | string | undefined = undefined
      let expandedBody: string | JSX.Element = ''
      let colorClass = ''
      const person = notification?.timestamp?.ipPerson ?? null
      const rfid = `RFID: ${notification?.timestamp?.rfid ?? ''}`
      const direction = getDirectionText(notification?.timestamp?.direction, t)
      const timeText = moment(notification?.timestamp?.timeStamped ?? Date.now())
        .tz('Europe/Stockholm')
        .format('HH:mm')
      const dateText = format(notification?.timestamp?.timeStamped ?? Date.now(), 'd MMMM', {
        locale: i18n.getResourceBundle(i18n.language, 'datefns')
      })

      const personText = person
        ? `${person.firstName ?? ''} ${person.surName ?? ''} (${person.id})`
        : rfid
      const terminalDescription = notification?.terminal?.description ?? 'okänd plats'

      const tryAgainText = (
        <>
          {t('NotificationListItem.tryAgain')} - {body}
        </>
      )

      const buttons = (
        <NotificationButtons
          handleRemove={handleRemove}
          handleUpdateTimestamp={handleUpdateTimestamp}
          notification={notification as any}
          openPersonTimestampsDialog={openPersonTimestampsDialog}
        />
      )
      body = (
        <div className="flex flex-row w-full max-w-full">
          <div className="flex flex-col sm:w-40">
            <div className="flex items-center">
              <BiCalendarAlt /> <span className="ml-2 capitalize">{dateText}</span>
            </div>
            <div className="flex items-center">
              <BiTimeFive /> <span className="ml-2">{timeText}</span>
            </div>
            <div className="flex items-center">
              <BiDirections /> <span className="ml-2 capitalize">{direction}</span>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex items-center">
              <BsGeo /> <span className="ml-2">{terminalDescription}</span>
            </div>
            <div className="flex items-center">
              <GoPerson /> <span className="ml-2 truncate">{personText}</span>
            </div>
            {notification?.timestamp?.comment ? (
              <div className="flex items-center">
                <MdComment />
                <span className="ml-2 capitalize">{notification.timestamp?.comment}</span>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      )
      switch (notification?.type) {
        case NotificationTypeEnum.NetworkError:
          Icon = NetworkProblemIcon
          label = t('NotificationListItem.networkProblem')
          body = tryAgainText
          colorClass = 'bg-blue-500'
          break
        case NotificationTypeEnum.WorkdayNotFound:
        case NotificationTypeEnum.TerminalMissingOrderOrWorkplace:
        case NotificationTypeEnum.TerminalMissingRules:
          Icon = UnexpectedTimestampIcon
          label = t('NotificationListItem.unScheduled')
          expandedBody = (
            <UnexpectedTimestampContainer
              ipPersonId={person?.id ?? undefined}
              hoursBefore={12}
              hoursAfter={12}
              notification={notification as any}
            />
          )
          colorClass = 'bg-purple-500'
          break
        case NotificationTypeEnum.PersonMissingInVirtualTimestamp:
        case NotificationTypeEnum.UnknownError:
          Icon = UnexcpetedErrorIcon
          label = t('NotificationListItem.unKnownError')
          body = tryAgainText
          colorClass = 'bg-secondary-500'
          break
        case NotificationTypeEnum.RfidNotFound:
          Icon = UnregisteredCardIcon
          label = t('NotificationListItem.unregisteredCard')
          colorClass = 'bg-yellow-500'
          break
        case NotificationTypeEnum.IncorrectNrOfTimestamps:
          Icon = NrOfTimestampsIcon
          label = t('NotificationListItem.incorrectNrOfTimestamps')
          colorClass = 'bg-green-500'
          break
        case NotificationTypeEnum.IncorrectAbsence:
          Icon = TooLongAbsenceIcon
          label = t('NotificationListItem.tooLongAbsence')
          colorClass = 'bg-primary-500'
          break
        case NotificationTypeEnum.WorkdayAlreadyAttested:
          Icon = LockIcon
          label = t('NotificationListItem.workdayAlreadyAttested')
          colorClass = 'bg-orange-500'
          expandedBody = (
            <UnexpectedTimestampContainer
              ipPersonId={person?.id ?? undefined}
              hoursBefore={12}
              hoursAfter={12}
              notification={notification as any}
            />
          )
          break
        default:
      }
      return {
        icon: (
          <div className={`bg- w-8 h-8 flex-center rounded-full ${colorClass}`}>
            <Icon className="!text-white !w-6 !h-6" />
          </div>
        ),
        label,
        body,
        lowerBody,
        expandedBody,
        buttons
      }
    },
    [i18n, t]
  )

  const handleRemove = useCallback(
    async (id: number) => {
      try {
        await removeNotificationMutation({
          variables: {
            token: getLocalStorageToken(),
            id,
            updateTimestampTimeSent: true
          }
        })
        toast.success((t('NotificationListItem.notificationRemoved') as string) ?? '')
      } catch (error) {
        toast.error((t('NotificationListItem.notificationRemoveFailed') as string) ?? '')
      }
    },
    [removeNotificationMutation, t]
  )
  const handleUpdateTimestamp = useCallback(
    async (id: number) => {
      try {
        await updateTimestampMutation({
          variables: {
            token: getLocalStorageToken(),
            id,
            timeSend: moment().valueOf(),
            sendAttempts: 5
          }
        })
        toast.success((t('NotificationListItem.notificationResend') as string) ?? '')
      } catch (error) {
        toast.error((t('NotificationListItem.notificationResendFailed') as string) ?? '')
      }
    },
    [t, updateTimestampMutation]
  )

  const { body, lowerBody, expandedBody, buttons } = useMemo(
    () =>
      notificationsInfo(
        notification,
        handleRemove,
        handleUpdateTimestamp,
        openPersonTimestampsDialog
      ),
    [
      notification,
      handleRemove,
      handleUpdateTimestamp,
      openPersonTimestampsDialog,
      notificationsInfo
    ]
  )
  return (
    <ListItem
      key={notification?.id}
      body={body}
      expandedBody={expandedBody}
      lowerBody={lowerBody}
      right={buttons}
    />
  )
}

export default NotificationListItem
