import HeaderSearchBar from 'containers/HeaderSearchBar/HeaderSearchBar'
import * as React from 'react'
import { useApolloClient } from '@apollo/client'
import querySearchAll from 'graphql/queries/querySearchAll'
import { getLocalStorageToken } from 'store/localStorage'
import get from 'lodash.get'
import SearchResults from 'components/SearchResults/SearchResults'
import { SidebarMenuContext } from 'providers/SidebarMenuProvider'
import { HeaderSearchContext } from 'providers/HeaderSearchProvider'
import { withRouter, RouteComponentProps } from 'react-router'

export interface Props extends RouteComponentProps<any> {
  style?: any
  history: any
}

const SearchBarContainer: React.FC<Props> = ({ style, history }) => {
  const client = useApolloClient()

  return (
    <div style={{ ...style }}>
      <SidebarMenuContext.Consumer>
        {p => {
          const { actions } = p ?? {}
          const { closeMenu } = actions ?? {}
          return (
            <HeaderSearchContext.Consumer>
              {p => {
                const { searchPhrase, actions } = p ?? {}
                const {
                  focusSearch = () => {},
                  setData,
                  clearSearchResult = () => {},
                  setLoading,
                  setSearchPhrase
                } = actions ?? {}
                const goToLink = (url: string) => {
                  history.push(url)
                  setSearchPhrase?.('')
                  closeMenu?.()
                }
                return (
                  <HeaderSearchBar
                    onCancel={clearSearchResult}
                    onSearch={async () => {
                      setLoading?.(true)
                      try {
                        const { data } = await client.query({
                          query: querySearchAll,
                          variables: { token: getLocalStorageToken(), searchPhrase }
                        })
                        const terminals = get(data, 'viewer.terminals', [])
                        const persons = get(data, 'viewer.ipPersons', [])
                        setData?.(terminals, persons)
                        setLoading?.(false)
                      } catch (err) {
                        setLoading?.(false)
                      }
                    }}
                    onFocus={focusSearch}
                    goToLink={goToLink}
                  >
                    <SearchResults goToLink={goToLink} />
                  </HeaderSearchBar>
                )
              }}
            </HeaderSearchContext.Consumer>
          )
        }}
      </SidebarMenuContext.Consumer>
    </div>
  )
}

export default withRouter(SearchBarContainer)
