import * as React from 'react'

import LAST_PAGE_ICON from './double-chevron.svg'

export interface Props {
  currentPage: number
  nrOfPages: number
  onPageClick: (pageNr: number) => void
  hideGoToLast?: boolean
}

class TablePageNumbering extends React.PureComponent<Props> {
  render() {
    const { nrOfPages, onPageClick, currentPage, hideGoToLast } = this.props
    const onClick = pageNr => () => onPageClick(pageNr)
    const nrOfVisiblePages = Math.min(nrOfPages, 3)
    const firstPage = currentPage === 0
    const lastPage = currentPage >= nrOfPages - 1

    return (
      <div className="flex-center flex-row flex-1 min-h-[29px]">
        <img
          src={LAST_PAGE_ICON}
          className={`opacity-20 w-4 h-auto transform rotate-180 ${
            !firstPage ? 'cursor-pointer !opacity-100' : ''
          }`}
          alt={''}
          onClick={!firstPage ? onClick(1) : undefined}
        />
        <i
          className={`zmdi zmdi-chevron-left ${
            !firstPage ? 'cursor-pointer !opacity-100' : ''
          } ${'opacity-20 cursor-default my-0 mx-1 first:mr-2 last:ml-2'}`}
          onClick={!firstPage ? onClick(currentPage) : undefined}
        />
        {new Array(nrOfVisiblePages).fill(undefined).map((page, index) => {
          let pageNr = index + 1 + currentPage
          if (!firstPage) {
            pageNr = pageNr - 1
          }
          if (lastPage && nrOfPages > 2) {
            pageNr = pageNr - 1
          }
          return (
            <div
              key={index}
              className={`w-[25px] h-[25px] rounded-full flex-center text-xs ${
                currentPage === pageNr - 1
                  ? 'border-2 border-primary-600 text-primary-600 font-bold'
                  : 'cursor-pointer'
              }`}
              onClick={onClick(pageNr)}
            >
              {pageNr}
            </div>
          )
        })}
        <i
          className={`zmdi zmdi-chevron-right ${
            !lastPage ? 'cursor-pointer !opacity-100' : ''
          } ${'opacity-20 cursor-default my-0 mx-1 first:mr-2 last:ml-2'}`}
          onClick={!lastPage ? onClick(currentPage + 2) : undefined}
        />
        {!hideGoToLast ? (
          <img
            src={LAST_PAGE_ICON}
            className={[
              'opacity-20 w-4 h-auto',
              !lastPage ? 'cursor-pointer !opacity-100' : ''
            ].join(' ')}
            alt={''}
            onClick={!lastPage ? onClick(nrOfPages) : undefined}
          />
        ) : null}
      </div>
    )
  }
}

export default TablePageNumbering
