import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const Error: React.FC<{ error: Error }> = ({ error }) => {
  const { t } = useTranslation()
  return (
    <div className="p-6 text-center">
      <div className="text-lg text-gray-500">{t('List.error')}</div>
      <div className="text-xs text-gray-400">
        {t('List.errorCode', { errorCode: btoa(error.message) })}
      </div>
    </div>
  )
}
