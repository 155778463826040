import PasswordChangerContainer from 'components/UserSettings/containers/PasswordChangerContainer/PasswordChangerContainer'
import * as React from 'react'

export interface IUserSettings {
  error: string
  changePasswordSubmit: () => void
  closeUserSettings: () => void
  logout: () => void
}

export const UserSettings: React.FC<IUserSettings> = () => {
  return <PasswordChangerContainer />
}
