import gql from 'graphql-tag'
export default gql`
  query CustomerSettings {
    customerSettings {
      SSO
      SSO_apiKey
      SSO_appId
      SSO_authDomain
      SSO_measurementId
      SSO_projectId
      absence
      createWorkday
      emailLogin
      fileImportFieldglass
      fileImportTeleopti
      fileImportRecruitive
      fileImportTidomat
      fileImportSchedule
      fileImportFlexHRM
      fileImportQuinyx
      fileImportDSV
      fileImportFleximeStandard
      fileImportLN4
      fileImportLookerArticles
      fileImportArticle
      fileImportAgda
      fileImportLooker
      fileImportTest2
      fileImportEON
      mobile
      updatedAt
      mobileAbsence
      excludedIpAbsenceCodeIds
      enableBreakPositions
      defaultBreakStyle
      enableGeofencesOnCustomersAndOrders
      switchOrder
    }
  }
`
