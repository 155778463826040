import React from 'react'
import { getLocalStorageToken } from 'store/localStorage'
import { useQuery } from '@apollo/client'
import queryOrganisations from 'graphql/queries/queryOrganisations'
import { useTranslation } from 'react-i18next'
import { useOrganisations } from 'hooks/useOrganisations'
import { organisations, organisationsVariables } from 'graphql/queries/types/organisations'
import { ExcludePicker } from 'containers/ExcludePicker/ExcludePicker'

export default function OrganisationPicker() {
  const { t } = useTranslation()
  const { excludeOrganisationIds, setExcludedOrganisations } = useOrganisations()
  const { data } = useQuery<organisations, organisationsVariables>(queryOrganisations, {
    variables: { token: getLocalStorageToken() },
    fetchPolicy: 'cache-and-network',
    skip: excludeOrganisationIds === null
  })
  const allOrganisations: { id: number; name: string }[] = (data?.viewer?.organisations ?? []).map(
    o => ({
      id: o.id,
      name: o.name ?? t('general.unknown')
    })
  )

  return (
    <ExcludePicker
      allItems={allOrganisations}
      excludedIds={excludeOrganisationIds}
      setExcludedIds={setExcludedOrganisations}
    />
  )
}
