import gql from 'graphql-tag'
export default gql`
  query organisations($token: String!, $searchPhrase: String) {
    viewer(token: $token) {
      id
      organisations(searchPhrase: $searchPhrase) {
        id
        name
      }
    }
  }
`
