import * as React from 'react'
export interface Props {
  text: string
}

const HoverArrow: React.SFC<Props> = props => {
  return (
    <div className="absolute left-0 top-0 h-full w-full hover:block hover:pt-4 opacity-0 sm:hover:opacity-100 ">
      <div className="flex absolute left-[85%] top-0 h-full items-center pointer-events-none hover:block hover:pt-4">
        <div className="flex h-5 items-center">
          <div
            className=" border-secondary-700  whitespace-nowrap flex items-center justify-center"
            style={{
              borderTop: '10px solid transparent',
              borderBottom: '10px solid transparent',
              borderRight: `10px solid #EC85B5`
            }}
          />
          <div className="bg-secondary-700 flex-grow h-5 whitespace-nowrap text-white text-xs font-semibold flex-center px-2">
            {props.text}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HoverArrow
