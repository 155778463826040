import { LOGIN_REMOVE_TOKEN } from 'containers/Login/loginReducer'
import { Toaster } from 'react-hot-toast'
import { AppState } from 'models/AppState'
import * as React from 'react'
import { connect } from 'react-redux'

interface ICoreLayout {
  children: any
  logout: () => void
}

const mapStateToProps = (state: AppState) => ({})

const mapDispatchToProps = {
  logout: () => ({ type: LOGIN_REMOVE_TOKEN })
}

export class CoreLayoutClass extends React.Component<ICoreLayout, any> {
  render() {
    return (
      <div
        id={'core-layout'}
        style={{ position: 'absolute', width: '100%', height: '100%', overflowX: 'hidden' }}
      >
        {this.props.children}
        <Toaster
          position="bottom-center"
          toastOptions={{
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff'
            }
          }}
        />
      </div>
    )
  }
}

const CoreLayout: any = connect<any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(CoreLayoutClass)
export { CoreLayout }
