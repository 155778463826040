import * as React from 'react'
import logo from 'assets/icon.svg'

export interface IFleximeLoader {
  size: number
  style?: React.CSSProperties
}

export class FleximeLoader extends React.PureComponent<IFleximeLoader, any> {
  render() {
    const { size, style } = this.props
    return (
      <div className="" style={style}>
        <img
          className="animate-spin"
          alt={'Loading'}
          src={logo}
          width={size}
          height={this.props.size}
        />
      </div>
    )
  }
}
