import gql from 'graphql-tag'
export default gql`
  query notifications(
    $token: String!
    $before: Date!
    $after: Date!
    $type: NotificationTypeEnum
    $excludeOrganisationIds: [Int]
  ) {
    viewer(token: $token) {
      id
      notifications(
        after: $after
        before: $before
        type: $type
        excludeOrganisationIds: $excludeOrganisationIds
      ) {
        id
        type
        terminal {
          id
          name
          description
        }
        OrderId
        timestamp {
          id
          timeStamped
          timeSent
          timeSend
          direction
          TerminalId
          rfid
          comment
          ipPerson {
            id
            firstName
            surName
          }
          absenceType
          sendAttempts
        }
      }
    }
  }
`
