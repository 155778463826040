import * as React from 'react'
import { Select, MenuItem, Button } from '@material-ui/core'
import TextInput from 'components/TextInput/TextInput'
import { Translation } from 'react-i18next'

export interface Props {
  createUser: (event: any, resetValues: () => void) => void
  handleSubmit: (submit: any) => any
  error: string
  createUserStatus: { success: boolean; message: string }
}
export interface State {
  values: {
    username: string
    password: string
    privilegeLevel: number
  }
}

export default class AdminAddUser extends React.Component<Props, State> {
  state = {
    values: {
      username: '',
      password: '',
      privilegeLevel: 0
    }
  }
  private handleChange = (value: string | number, name?: string) => {
    const values = this.state.values
    if (name) {
      values[name] = value
    }
    this.setState({ values })
  }
  private resetValues = () => {
    this.setState({ values: { username: '', password: '', privilegeLevel: 0 } })
  }
  private handleSubmit = (event: any) => {
    event.preventDefault()
    this.props.createUser(this.state.values, this.resetValues)
    return false
  }
  render() {
    return (
      <Translation>
        {t => (
          <form onSubmit={this.handleSubmit}>
            <div className="flex sm:flex-row flex-col">
              <div className="flex sm:flex-center flex-1">
                <TextInput
                  placeholder={t('general.email')}
                  type="email"
                  name="username"
                  text={this.state.values.username}
                  handleChange={this.handleChange}
                />
              </div>
              <div className="fled sm:flex-center flex-1">
                <TextInput
                  type="password"
                  placeholder={t('general.password')}
                  name="password"
                  handleChange={this.handleChange}
                  text={this.state.values.password}
                />
              </div>
              <div className="flex sm:flex-center flex-1 pl-4 sm:pl-0">
                <Select
                  onChange={(e: any) => this.handleChange(e.target.value, 'privilegeLevel')}
                  className="text-gray-400"
                  value={this.state.values['privilegeLevel']}
                  style={{ height: '43px', lineHeight: '43px', marginTop: '3px' }}
                >
                  <MenuItem value={0}>{t('AdminAddUser.privilege.user')} </MenuItem>
                  <MenuItem value={3}>{t('AdminAddUser.privilege.admin')} </MenuItem>
                </Select>
              </div>
              <div
                className="flex sm:flex-center flex-1 pl-2 sm:pl-0 mt-4 sm:mt-0"
                style={{ flex: 'none' }}
              >
                <Button
                  disabled={!this.state.values.username || !this.state.values.password}
                  variant="outlined"
                  type="submit"
                >
                  {t('general.add')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Translation>
    )
  }
}
