import * as React from 'react'

export interface Props {}

const ActiveIndicator: React.SFC<Props> = props => {
  return (
    <div className="flex w-[2px] h-full absolute top-0 right-0 items-center">
      <div className="h-[64%] w-full bg-secondary-700 shadow" />
    </div>
  )
}

export default ActiveIndicator
