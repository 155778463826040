import * as React from 'react'
import bg from './assets/bg.jpeg'
import { AiOutlineLogout } from 'react-icons/ai'
import { FaLanguage } from 'react-icons/fa'
import { GiOrganigram } from 'react-icons/gi'
import { Link } from 'react-router-dom'
import OrganisationPicker from 'containers/OrganisationPicker/OrganisationPicker'
import { useToggle } from 'react-use'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageSelector from 'components/LanguageSelector/LanguageSelector'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { BsShieldShaded } from 'react-icons/bs'
import { useCustomerSettings } from 'hooks/useCustomerSettings'
import isSSOLoggedIn from 'utils/isSSOLoggedIn'
import signOut from 'utils/signOut'

export interface Props {
  username: string
}

const UserAvatar: React.FC<Props> = props => {
  const customerSettings = useCustomerSettings()
  const handleSignout = () => {
    signOut()
  }

  const [isOpen, toggle] = useToggle(false)
  const { t } = useTranslation()
  const handleClickAway = useCallback(() => {
    toggle(false)
  }, [toggle])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="h-full flex items-center relative">
        <div
          onClick={toggle}
          className="w-[38px] h-[38px] rounded-full bg-gray-700 text-white flex-center cursor-pointer"
        >
          <span className="text-base uppercase">{props.username.charAt(0)}</span>
        </div>
        {isOpen && (
          <div className="fixed z-10 right-0 top-[75px] transform px-2 w-screen max-w-sm sm:px-0">
            <div className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5">
              <div className="h-28 w-full rounded-t-lg overflow-hidden relative flex-center">
                <img className="absolute inset-0 h-full w-full object-cover" src={bg} alt="" />
              </div>
              <div className="bg-white h-16 relative flex-center">
                <div className=" border-2 border-white w-20 h-20 rounded-full bg-gray-700 text-white flex-center absolute -top-10">
                  <span className="text-3xl uppercase">{props.username.charAt(0)}</span>
                </div>
                <span className="self-end text-gray-500">{props.username}</span>
              </div>
              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 sm:pt-4">
                <ul className="mt-5 space-y-6">
                  {customerSettings && !isSSOLoggedIn() && (
                    <li>
                      <Link
                        onClick={handleClickAway}
                        to="/userSettings"
                        className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                      >
                        <BsShieldShaded />
                        <span className="ml-4 pt-[1px]">{t('UserAvatarMenu.changePassword')}</span>
                      </Link>
                    </li>
                  )}
                  <li>
                    <span className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150">
                      <GiOrganigram className="mr-3" />
                      <OrganisationPicker />
                    </span>
                  </li>
                  <li>
                    <span className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150">
                      <FaLanguage className="mr-3" />
                      <LanguageSelector />
                    </span>
                  </li>
                </ul>
              </div>
              <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8 rounded-b-lg">
                <div className="flow-root">
                  <button
                    onClick={handleSignout}
                    className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150"
                  >
                    <AiOutlineLogout />
                    <span className="ml-3">{t('UserAvatarMenu.signOut')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default UserAvatar
