import React, { useState } from 'react'
import Button from 'base-components/Button'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import GeneralSearch from 'components/GeneralSearch/GeneralSearch'
import { useTranslation } from 'react-i18next'
import { GeneralSearchTypes } from 'models/interfaces'

interface Props {
  type: GeneralSearchTypes
  title: string
  text: string
  onClose: () => void
  onSelect: (id: number | undefined) => void
  isShowing: boolean
}

export const SelectObjectDialog: React.FC<Props> = ({
  type,
  onSelect,
  onClose,
  isShowing,
  title,
  text,
  children
}) => {
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const { t } = useTranslation()

  const handleClose = () => {
    setSelectedId(null)
    onClose()
  }
  const handleSelect = () => {
    onSelect(selectedId ?? undefined)
    setSelectedId(null)
    onClose()
  }
  return (
    <Dialog
      open={isShowing}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: 'pre-line' }}>{text}</DialogContentText>
        {
          <GeneralSearch
            value={selectedId}
            type={type}
            onChange={data => {
              setSelectedId(data?.item?.id ?? null)
            }}
          />
        }
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('general.close')}</Button>
        <Button disabled={!selectedId} onClick={handleSelect}>
          {t('general.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
