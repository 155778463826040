import { connect } from 'react-redux'
import * as React from 'react'
import { createElement } from 'react'
import { getStore } from 'store'
import { AppState } from 'models/AppState'
import { ADD_NEW_SEARCH_BAR } from './searchBarReducer'

export interface ISearchBarMappedProps {
  searchText: string
}

interface ISearchBarDecorator {
  id: string
}

export const searchBar = ({ id }: ISearchBarDecorator) => {
  const store = getStore()
  store.dispatch({ type: ADD_NEW_SEARCH_BAR, payload: { id } })

  return WrappedComponent => {
    class SearchBar extends React.Component<any, any> {
      render() {
        return createElement(WrappedComponent, { ...this.props })
      }
    }

    const mapStateToProps = (state: AppState): ISearchBarMappedProps => ({
      searchText: state.searchBar.get(id).get('searchBarSearchText')
    })

    return connect(mapStateToProps)(SearchBar)
  }
}
