import createStore from './store/createStore'
import { Store } from 'redux'
import { AppState } from './models/AppState'

declare const window

// const initialState = window.___INITIAL_STATE__
// export const store = createStore(initialState, client)

let store

export const getStore = (): Store<AppState> => {
  const initialState = window.___INITIAL_STATE__
  store = store ? store : createStore(initialState)
  return store
}
