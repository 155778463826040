import { FetchResult, useMutation } from '@apollo/client'
import { getLocalStorageToken } from 'store/localStorage'
import toast from 'react-hot-toast'
import {
  updateTimestamps,
  updateTimestampsVariables
} from '../graphql/mutations/types/updateTimestamps'
import mutationRemoveNotifications from '../graphql/mutations/mutationRemoveNotifications'
import {
  removeNotificationsVariables,
  removeNotifications
} from '../graphql/mutations/types/removeNotifications'
import mutationUpdateTimestamps from '../graphql/mutations/mutationUpdateTimestamps'

interface NotificationToUpdate {
  id: number | null
  timestamp: {
    id: number | null
  } | null
}

const useUpdateTimestampRemoveNotification = () => {
  let updateTimestampRes: FetchResult<updateTimestamps, Record<string, any>, Record<string, any>>
  const [updateTimestamps] = useMutation<updateTimestamps, updateTimestampsVariables>(
    mutationUpdateTimestamps
  )
  const [removeNotifications] = useMutation<removeNotifications, removeNotificationsVariables>(
    mutationRemoveNotifications
  )
  return async ({
    notifications,
    ipPersonId,
    timeSend,
    sendAttempts,
    successText,
    terminalId
  }: {
    notifications: (NotificationToUpdate | null)[] | null
    ipPersonId?: number
    timeSend?: number
    sendAttempts: number
    terminalId?: number
    successText: string
  }) => {
    try {
      const updateTimestampsData = notifications?.reduce<any[]>((acc, notification) => {
        if (notification?.timestamp?.id) {
          return [
            ...acc,
            {
              id: notification?.timestamp?.id,
              ipPersonId,
              timeSend: timeSend,
              sendAttempts,
              terminalId
            }
          ]
        }
        return acc
      }, [])
      updateTimestampRes = await updateTimestamps({
        variables: {
          token: getLocalStorageToken(),
          updateTimestampsData
        }
      })
    } catch (err) {
      console.log('err: ', err)
      toast.error('Kunde inte uppdatera tidsstämpling')
    }

    if (updateTimestampRes?.data?.updateTimestamps) {
      const notificationIds = notifications
        ?.filter(noti =>
          updateTimestampRes.data?.updateTimestamps?.find(
            updatedId => noti?.timestamp?.id === updatedId
          )
        )
        .map(notification => notification?.id)
      try {
        await removeNotifications({
          variables: {
            token: getLocalStorageToken(),
            ids: notificationIds?.map(id => id ?? null) ?? [],
            updateTimestampTimeSent: false
          },
          refetchQueries: [
            'notifications',
            'notificationsTree',
            'notificationGroups',
            'notificationGroup',
            'notificationsList',
            'notificationsPaginated',
            'notificationTypeCount'
          ]
        })

        toast.success(successText)
      } catch (err) {
        toast.error('Kunde inte uppdatera tidsstämpling')
      }
    }
  }
}

export default useUpdateTimestampRemoveNotification
