import * as React from 'react'
import { ITimestampRule } from 'models/interfaces'
import WorkdayRuleHover from 'components/Timeline/WorkdayRuleHover/WorkdayRuleHover'
import TimelineHover from 'components/Timeline/TimelineHover/TimelineHover'
import { TimestampRuleTypeEnum } from 'types/graphql-global-types'

export interface Props {
  style?: any
  timestampRule: ITimestampRule
  index: number
  parentWidth: number
  parentHover: boolean
  setParentHover: (hover: boolean, innerRule: boolean) => void
  isRightmost?: boolean
  isLeftmost?: boolean
  parentOffset: number
  hasOverflow: boolean
  hasUnderflow: boolean
  durationReduction?: number
}
export interface State {
  hover: boolean
}

class WorkdayRule extends React.Component<Props, State> {
  state = {
    hover: false
  }

  private setHover = (hover: boolean) => {
    this.props.setParentHover(hover, false)
    this.setState({ hover })
  }

  private getWidth = () => {
    const { hover } = this.state
    const { parentHover, timestampRule, durationReduction } = this.props

    if (hover || parentHover) {
      const duration =
        timestampRule?.durationWithOffset && typeof durationReduction !== 'undefined'
          ? timestampRule.durationWithOffset - durationReduction
          : 0
      return (duration / (1000 * 60 * 60)) * 100
    } else {
      return 0
    }
  }

  render() {
    const {
      timestampRule,
      index,
      parentWidth,
      isLeftmost,
      isRightmost,
      parentOffset,
      hasOverflow,
      hasUnderflow
    } = this.props
    const { hover } = this.state
    const width = this.getWidth()

    const onLeft = timestampRule.type === TimestampRuleTypeEnum.InBeforeStart

    return (
      <div
        className={[
          'ruleCotnainer',
          'box-border items-center h-[30px] opacity-100 bg-blue-500 rounded-[15px] border-2 border-white bottom-[15px] flex justify-center left-0 absolute cursor-auto transition-all',
          hover ? 'bg-blue-200' : null,
          isLeftmost ? 'border-l-0' : null,
          isRightmost ? 'border-r-0' : null,
          hasOverflow ? 'rounded-tr-none rounded-br-none' : null,
          hasUnderflow ? 'rounded-tl-none rounded-bl-none' : null,
          onLeft ? 'rounded-tr-none rounded-br-none' : 'rounded-tl-none rounded-bl-none'
        ].join(' ')}
        style={{
          left: `calc(${parentOffset + (onLeft ? -width : parentWidth)}% ${
            onLeft ? '' : '- 15px'
          })`,
          width: `calc(${width}% + 15px)`,
          scale: hover ? 1 : 0,
          //This was in the scss-file: width: `calc(100% - 15px)`,
          transitionDelay: `${index * 0.02}s`,
          zIndex: 900
        }}
        onMouseEnter={() => this.setHover(true)}
        onMouseLeave={() => this.setHover(false)}
      >
        <TimelineHover
          parentHover={hover}
          color={'blue'}
          disableHover={true}
          width={120}
          elevated={true}
        >
          <WorkdayRuleHover onLeft={onLeft} timestampRule={timestampRule} />
        </TimelineHover>
      </div>
    )
  }
}

export default WorkdayRule
