import React from 'react'

const Spinner: React.FC<{ size?: 'sm' | 'md' | 'xl' | '2xl' }> = ({ size = 'sm' }) => {
  let width = 'w-10'
  switch (size) {
    case 'md':
      width = 'w-16'
      break
    case 'xl':
      width = 'w-24'
      break
    case '2xl':
      width = 'w-32'
      break
  }
  return (
    <svg
      className={`animate-spin ${width}`}
      version="1.1"
      id="loader-1"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
    >
      <path
        fill="#000"
        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
      />
    </svg>
  )
}

export default Spinner
