import * as get from 'lodash.get'
import CheckboxIcon from '@material-ui/icons/CheckCircle'
import moment from 'moment-timezone'
import * as React from 'react'
import { IIpWorkday, ITimestamp, ITimestampRule } from '../../../models/interfaces'
import WorkdayActions from 'components/Timeline/WorkdayActions/WorkdayActions/WorkdayActions'
import WorkdayInnerRule from 'components/Timeline/WorkdayInnerRule/WorkdayInnerRule'
import WorkdayPunch from 'components/Timeline/WorkdayPunch/WorkdayPunch'
import WorkdayRule from 'components/Timeline/WorkdayRule/WorkdayRule'
import { TimestampDirectionEnum, TimestampRuleTypeEnum } from 'types/graphql-global-types'

export interface Props {
  workday: IIpWorkday
  maxDate: moment.Moment
  minDate: moment.Moment
  existsOnTerminal: boolean
  timestamp: ITimestamp
  expandRules?: boolean
  disableActionClick?: boolean
  terminalIpWorkplaceIds: number[]
}
export interface State {
  hover: boolean
  ruleHover: boolean
  innerRuleHover: boolean
  hidePunches: boolean
}

class Workday extends React.Component<Props, State> {
  private ruleHoverCount = 0
  private innerRuleHoverCount = 0
  private containerRef: HTMLElement | null = null

  state = {
    hover: false,
    ruleHover: false,
    innerRuleHover: false,
    hidePunches: false
  }

  private getOffset = (from: moment.Moment, to: moment.Momment) => {
    const duration = moment.duration(to.diff(from))
    var minutes = duration.asMinutes()
    return (minutes / 60) * 100
  }

  private setHover = (hover: boolean) => {
    if (this.state.hover !== hover) {
      this.setState({ hover })
    }
  }
  private setRuleHover = (hover: boolean, innerRule: boolean) => {
    if (hover) {
      if (innerRule) {
        this.innerRuleHoverCount++
      } else {
        this.ruleHoverCount++
      }
    } else {
      if (innerRule) {
        this.innerRuleHoverCount--
      } else {
        this.ruleHoverCount--
      }
    }
    this.setState({
      innerRuleHover: this.innerRuleHoverCount > 0,
      ruleHover: this.ruleHoverCount > 0
    })
  }

  private setContainerRef = (elem: HTMLElement | null) => {
    this.containerRef = elem
  }

  private updateHidePunches = () => {
    if (this.containerRef) {
      const hidePunches = this.containerRef.getBoundingClientRect().width < 80
      if (hidePunches !== this.state.hidePunches) {
        this.setState({ hidePunches })
      }
    }
  }

  private getInnerOuterRules = (from: moment.Moment, to: moment.Moment) => {
    const { workday, timestamp } = this.props
    const sortFunc = (t1, t2) => (t1.sortOrder < t2.sortOrder ? -1 : 1)

    const timeStamped = moment(timestamp.timeStamped)
    let timestampRules: ITimestampRule[] | undefined = []

    if (get(workday, 'ipOrder.timestampRules', []).length > 0) {
      timestampRules = workday.ipOrder.timestampRules
    } else {
      timestampRules = get(workday, 'ipOrder.workplace.timestampRules', [])
    }

    let tempOuterRules: ITimestampRule[] | undefined = []
    let tempInnerRules: ITimestampRule[] | undefined = []

    if (timeStamped < from && timestamp.direction === TimestampDirectionEnum.In) {
      tempOuterRules = timestampRules
        ?.filter(t => t.type === TimestampRuleTypeEnum.InBeforeStart)
        .sort(sortFunc)
    } else if (timeStamped > to && timestamp.direction === TimestampDirectionEnum.Out) {
      tempOuterRules = timestampRules
        ?.filter(t => t.type === TimestampRuleTypeEnum.OutAfterEnd)
        .sort(sortFunc)
    } else {
      if (timestamp.direction === TimestampDirectionEnum.In) {
        tempInnerRules = timestampRules
          ?.filter(t => t.type === TimestampRuleTypeEnum.InAfterStart)
          .sort(sortFunc)
      } else if (timestamp.direction === TimestampDirectionEnum.Out) {
        tempInnerRules = timestampRules
          ?.filter(t => t.type === TimestampRuleTypeEnum.OutBeforeEnd)
          .sort(sortFunc)
      }
    }

    // setting durations based on previous rules duration as offset
    let offset = 0
    const innerRules = tempInnerRules
      ?.map(rule => {
        const newDuration = offset + rule.duration
        offset += rule.duration
        return { ...rule, durationWithOffset: newDuration }
      })
      .reverse()
    const outerRules = tempOuterRules
      ?.map(rule => {
        const newDuration = offset + rule.duration
        offset += rule.duration
        return { ...rule, durationWithOffset: newDuration }
      })
      .reverse()
    return { innerRules, outerRules }
  }

  private getOuterRuleReduction = (
    to: moment.Moment,
    from: moment.Moment,
    minDate: moment.Moment,
    maxDate: moment.Moment
  ) => {
    if (to < minDate) {
      return minDate - to
    } else if (from > maxDate) {
      return from - maxDate
    } else {
      return 0
    }
  }

  private noRulesFromBefore = (
    to: moment.Moment,
    from: moment.Moment,
    minDate: moment.Moment,
    maxDate: moment.Moment,
    innerRules: ITimestampRule[],
    outerRules: ITimestampRule[]
  ) => {
    if (to < minDate) {
      return (
        to +
          Math.max.apply(
            Math,
            outerRules.map(rule => rule.duration)
          ) <
          minDate &&
        from +
          Math.max.apply(
            Math,
            innerRules.map(rule => rule.duration)
          ) <
          minDate
      )
    } else if (from > maxDate) {
      return (
        to -
          Math.max.apply(
            Math,
            outerRules.map(rule => rule.duration)
          ) >
          maxDate &&
        from -
          Math.max.apply(
            Math,
            innerRules.map(rule => rule.duration)
          ) >
          maxDate
      )
    }

    return false
  }

  componentDidMount() {
    this.updateHidePunches()
  }
  componentDidUpdate() {
    this.updateHidePunches()
  }

  render() {
    const {
      workday,
      minDate,
      maxDate,
      existsOnTerminal,
      timestamp,
      expandRules,
      disableActionClick
    } = this.props
    const { hover, ruleHover, innerRuleHover, hidePunches } = this.state

    let from = moment(workday.originalDateFrom || workday.dateFrom)
    let to = moment(workday.originalDateTo || workday.dateTo)

    const underflowTime = from < minDate ? minDate - from : 0
    const closeEndedClasses = underflowTime > 0 ? ['rounded-tl-none rounded-bl-none'] : []
    from = underflowTime > 0 ? minDate : from

    const overflowTime = to > maxDate ? to - maxDate : 0
    overflowTime > 0 && closeEndedClasses.push('rounded-tr-none rounded-br-none')
    to = overflowTime > 0 ? maxDate : to

    const timeOutsideTimeline = this.getOuterRuleReduction(to, from, minDate, maxDate)

    const { innerRules, outerRules } = this.getInnerOuterRules(from, to)

    const width = Math.max(this.getOffset(from, to), 18)
    const left = from <= maxDate ? this.getOffset(from.clone().startOf('hour'), from) : 0
    const workplaceName = get(workday, 'ipOrder.workplace.workPlaceName', '')

    // Checking if workday exists before timeline, if there are no rules are are relevant for the timeline, render null
    if (this.noRulesFromBefore(to, from, minDate, maxDate, innerRules ?? [], outerRules ?? [])) {
      return null
    }

    return (
      <React.Fragment>
        {outerRules?.map((timestampRule, index) => (
          <WorkdayRule
            key={index}
            timestampRule={timestampRule}
            index={index}
            parentWidth={width}
            parentHover={hover || expandRules || ruleHover}
            setParentHover={this.setRuleHover}
            parentOffset={left}
            hasOverflow={overflowTime > 0}
            hasUnderflow={underflowTime > 0}
            durationReduction={timeOutsideTimeline}
          />
        ))}
        <div
          className={[
            'container',
            'box-border absolute flex-center h-[30px] rounded-[15px] bg-purple-600 bottom-[15px] border-2 border-white z-[901]',
            ...closeEndedClasses,
            !existsOnTerminal ? `!bg-gray-400 dark` : null
          ].join(' ')}
          style={{ width: `${width}%`, left: `${left}%` }}
          onMouseEnter={() => this.setHover(true)}
          onMouseMove={() => this.setHover(true)}
          onMouseLeave={() => this.setHover(false)}
          ref={this.setContainerRef}
        >
          {!hidePunches ? (
            <WorkdayPunch
              isPunched={
                workday.punchInDateFrom !== null && typeof workday.punchInDateFrom !== 'undefined'
              }
            />
          ) : null}
          <WorkdayActions
            workday={workday}
            innerRules={innerRules ?? []}
            outerRules={outerRules ?? []}
            timestamp={timestamp}
            existsOnTerminal={existsOnTerminal}
            workplaceName={workplaceName}
            description={get(workday, 'ipOrder.staffingService.name', '')}
            parentHover={hover || innerRuleHover}
            from={moment(workday.originalDateFrom || workday.dateFrom)
              .tz('Europe/Stockholm')
              .format('HH:mm')}
            to={moment(workday.originalDateTo || workday.dateTo)
              .tz('Europe/Stockholm')
              .format('HH:mm')}
            disableActionClick={disableActionClick}
          />
          {workday.isBossConfirmed ? (
            <div className={`isBossConfirmed`}>
              <CheckboxIcon className="!text-white !w-[14px] !h-[14px] z-[904]" />
            </div>
          ) : null}
          <div
            className={[
              'text',
              'text-white text-sm select-none truncate whitespace-nowrap overflow-hidden h-full leading-7',
              hidePunches ? 'my-0 mx-[10px]' : null,
              overflowTime > 0 ? 'mr-0' : null,
              underflowTime > 0 ? 'ml-0' : null
            ].join(' ')}
          >
            {workplaceName}
          </div>
          {!hidePunches ? (
            <WorkdayPunch
              isPunched={
                workday.punchOutDateTo !== null && typeof workday.punchOutDateTo !== 'undefined'
              }
              alignRight={true}
            />
          ) : null}
        </div>
        {innerRules?.map((timestampRule, index) => (
          <WorkdayInnerRule
            key={index}
            timestampRule={timestampRule}
            index={index}
            parentWidth={width}
            parentHover={hover || expandRules || innerRuleHover}
            parentOffset={left}
            setParentHover={this.setRuleHover}
            overflowTime={overflowTime}
            underflowTime={underflowTime}
          />
        ))}
      </React.Fragment>
    )
  }
}

export default Workday
