import { DATA_TABLE_CHANGE_PAGE } from 'components/DataTable/containers/dataTableReducer'
import { ADMIN_ORGANISATIONS_ID, ADMIN_ORGANISATIONS_PAGE_SIZE } from './OrganisationsContainer'
import { AppState } from 'models/AppState'

export const ADMIN_SORT_ORGANISATIONS = 'ADMIN_SORT_ORGANISATIONS'
export const ADMIN_LOAD_ORGANISATIONS_PAGE = 'ADMIN_LOAD_ORGANISATIONS_PAGE'
export const ADMIN_CREATE_ORGANISATION_UPDATE = 'ADMIN_CREATE_ORGANISATION_UPDATE'

export interface IOrganisationsConnectedState {
  createOrganisationForm: any
  createOrganisationStatus: { success: boolean; message: string }
}
export const organisationsMapStateToProps = (state: AppState): IOrganisationsConnectedState => ({
  createOrganisationStatus: state.organisations.get('createOrganisationStatus'),
  createOrganisationForm: state.form.createOrganisation
})

export interface IOrganisationsConnectedDispatch {
  createOrganisationUpdate: (success: boolean, message: string) => void
  resetPagination: () => void
}
export const organisationsMapDispatchToProps: IOrganisationsConnectedDispatch = {
  createOrganisationUpdate: (success: boolean, message: string) => ({
    type: ADMIN_CREATE_ORGANISATION_UPDATE,
    payload: { success, message }
  }),
  resetPagination: () => ({
    type: DATA_TABLE_CHANGE_PAGE,
    payload: {
      id: ADMIN_ORGANISATIONS_ID,
      pageInfo: { first: 0, after: ADMIN_ORGANISATIONS_PAGE_SIZE }
    }
  })
}
