import { useQuery } from '@apollo/client'
import queryAbsenceCodes from 'graphql/queries/queryAbsenceCodes'
import queryIpOrdersList from 'graphql/queries/queryIpOrdersList'
import queryIpPersonsPaginated from 'graphql/queries/queryIpPersonsPaginated'
import queryIpWorkplacesList from 'graphql/queries/queryIpWorkplacesList'
import queryOrganisations from 'graphql/queries/queryOrganisations'
import queryTerminals from 'graphql/queries/queryTerminals'
import { absenceCodes, absenceCodesVariables } from 'graphql/queries/types/absenceCodes'

import { ipOrdersList, ipOrdersListVariables } from 'graphql/queries/types/ipOrdersList'
import { ipWorkplacesList, ipWorkplacesListVariables } from 'graphql/queries/types/ipWorkplacesList'
import { personList, personListVariables } from 'graphql/queries/types/personList'
import { terminals, terminalsVariables } from 'graphql/queries/types/terminals'
import React, { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useThrottle } from 'react-use'
import { getLocalStorageToken } from 'store/localStorage'
import { organisations, organisationsVariables } from 'graphql/queries/types/organisations'
import { GeneralSearchTypes } from 'models/interfaces'
import GeneralSearchBase, { GeneralSearchResult } from './GeneralSearchBase'
import usersQuery from 'graphql/queries/UsersQuery'
import { usersList, usersListVariables } from 'graphql/queries/types/usersList'
import { queryIpArticlesSearch } from 'graphql/queries/queryIpArticlesSearch'
import { IpArticlesSearch, IpArticlesSearchVariables } from 'graphql/queries/types/IpArticlesSearch'

interface Props<V extends { id: number }> {
  value: number | null | undefined
  onChange: (value: GeneralSearchResult<V> | null) => void
  type: GeneralSearchTypes
  size?: 'small' | 'large'
  fetchOnFocus?: boolean
  label?: string
}
function GeneralSearch<T extends { id: number }>({
  value,
  onChange,
  type,
  size = 'large',
  fetchOnFocus = false,
  label
}: Props<T>) {
  const CONFIG: Record<
    GeneralSearchTypes,
    {
      query: any
      getVariables: (searchPhrase: string | null) => any
      getResult: (data: any) => GeneralSearchResult<T>[]
    }
  > = {
    Terminal: {
      query: queryTerminals,
      getVariables: (searchPhrase): terminalsVariables => ({
        token: getLocalStorageToken(),
        filterText: searchPhrase,
        limit: 10
      }),
      getResult: (data: terminals) =>
        data?.viewer?.terminals?.map(terminal => {
          return ({
            title: terminal?.description ?? '',
            item: terminal,
            subtitle: terminal?.id?.toString() + ' - ' + terminal?.name ?? undefined
          } as unknown) as GeneralSearchResult<T>
        }) ?? []
    },
    AbsenceCode: {
      query: queryAbsenceCodes,
      getVariables: (searchPhrase): absenceCodesVariables => ({
        token: getLocalStorageToken(),
        searchPhrase,
        limit: 5
      }),
      getResult: (data: absenceCodes) =>
        data?.viewer?.ipAbsenceCodes?.map(absenceCode => {
          return ({
            title: absenceCode?.name ?? absenceCode?.id.toString() ?? '',
            item: absenceCode,
            subtitle: absenceCode?.id.toString()
          } as unknown) as GeneralSearchResult<T>
        }) ?? []
    },
    Workplace: {
      query: queryIpWorkplacesList,
      getVariables: (searchPhrase): ipWorkplacesListVariables => ({
        token: getLocalStorageToken(),
        filterText: searchPhrase,
        after: 10,
        first: 0
      }),
      getResult: (data: ipWorkplacesList) =>
        data?.viewer?.ipWorkplacesPaginated?.posts?.map(workplace => {
          return ({
            title: workplace?.workPlaceName ?? workplace?.workPlaceNo ?? '',
            item: workplace,
            subtitle: workplace?.workPlaceNo ?? undefined
          } as unknown) as GeneralSearchResult<T>
        }) ?? []
    },
    Order: {
      query: queryIpOrdersList,
      getVariables: (searchPhrase): ipOrdersListVariables => ({
        token: getLocalStorageToken(),
        filterText: searchPhrase,
        after: 5,
        first: 0
      }),
      getResult: (data: ipOrdersList) =>
        data?.viewer?.ipOrdersPaginated?.posts?.map(order => {
          return ({
            title: order?.staffingService?.name ?? '',
            item: order,
            subtitle: order?.id?.toString() + ' - ' + order?.description ?? undefined
          } as unknown) as GeneralSearchResult<T>
        }) ?? []
    },
    Person: {
      query: queryIpPersonsPaginated,
      getVariables: (searchPhrase): personListVariables => ({
        token: getLocalStorageToken(),
        searchText: searchPhrase,
        after: 10
      }),
      getResult: (data: personList) =>
        data?.viewer?.ipPersonsPaginated?.posts?.map(person => {
          const name = (person?.firstName ?? '') + ' ' + (person?.surName ?? '')
          return ({
            title: name ?? person?.id?.toString() ?? '',
            item: person,
            subtitle: person?.id?.toString() ?? undefined
          } as unknown) as GeneralSearchResult<T>
        }) ?? []
    },
    Organisation: {
      query: queryOrganisations,
      getVariables: (searchPhrase): organisationsVariables => ({
        token: getLocalStorageToken(),
        searchPhrase
      }),
      getResult: (data: organisations) =>
        data?.viewer?.organisations?.map(org => {
          return ({
            title: org.name ?? '',
            item: org
          } as unknown) as GeneralSearchResult<T>
        }) ?? []
    },
    User: {
      query: usersQuery,
      getVariables: (searchPhrase): usersListVariables => ({
        token: getLocalStorageToken(),
        filterText: searchPhrase,
        first: 0,
        after: 5
      }),
      getResult: (data: usersList) =>
        data?.viewer?.usersPaginated?.posts?.map(user => {
          return ({
            title: user?.username ?? '',
            item: user
          } as unknown) as GeneralSearchResult<T>
        }) ?? []
    },
    Article: {
      query: queryIpArticlesSearch,
      getVariables: (searchPhrase): IpArticlesSearchVariables => ({
        token: getLocalStorageToken(),
        filterText: searchPhrase,
        limit: 5
      }),
      getResult: (data: IpArticlesSearch) =>
        data?.viewer?.ipArticlesSearch?.map(article => {
          return ({
            title: article.name,
            item: article
          } as unknown) as GeneralSearchResult<T>
        }) ?? []
    }
  }
  const currentConfig = CONFIG[type]

  const { register, watch, reset, setValue } = useForm<{ searchPhrase: string }>()
  const searchPhrase = useThrottle<string | null>(watch('searchPhrase'), 300)

  const oldValue = useRef<number | null>(null)
  const { data, loading } = useQuery<T>(currentConfig?.query, {
    variables: currentConfig?.getVariables(searchPhrase || null),
    skip: typeof currentConfig === 'undefined' || (!fetchOnFocus && !Boolean(searchPhrase))
  })

  const queryResults: GeneralSearchResult<T>[] = useMemo(
    () => currentConfig?.getResult(data) ?? [],
    [currentConfig, data]
  )
  const results: GeneralSearchResult<T>[] = queryResults

  const currentValue = useMemo(() => {
    const findRes = queryResults.find(q => q.item.id === value)
    return typeof findRes?.item?.id !== 'undefined'
      ? { id: findRes.item.id, title: findRes.title }
      : null
  }, [queryResults, value])

  useEffect(() => {
    if (value != null && (oldValue == null || oldValue.current !== value)) {
      setValue('searchPhrase', value ? String(value) : '')
    }
    oldValue.current = value ?? null
  }, [setValue, value])

  return (
    <GeneralSearchBase
      value={currentValue}
      onChange={onChange}
      results={results}
      reset={reset}
      register={register}
      size={size}
      label={label}
      loading={loading}
    />
  )
}

export default GeneralSearch
