import React from 'react'

interface Props {
  title: string
  subtitle?: string
  content: React.ReactNode
  action?: React.ReactNode
  className?: string
}
const ActionPanel: React.FC<Props> = ({ title, subtitle, content, action, className }) => {
  return (
    <div className={`bg-white shadow rounded-lg ${className ?? ''}`}>
      <div className="px-4 py-5 sm:p-6">
        <div className="flex flex-row">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
          {subtitle && (
            <div className="text-gray-500 flex flex-row items-center">
              <div className="mx-2">-</div>
              <div className="text-sm flex-grow">{subtitle}</div>
            </div>
          )}
        </div>
        <div className="mt-2 max-w-xl text-sm text-gray-500 py-2">{content}</div>
        <div className="mt-3 text-sm">{action}</div>
      </div>
    </div>
  )
}

export default ActionPanel
