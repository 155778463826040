import gql from 'graphql-tag' 
export default gql`
mutation adminUpdateUser(
  $token: String!
  $userId: Int!
  $privilegeLevel: Int
  $OrganisationIds: [Int]
) {
  adminUpdateUser(
    token: $token
    userId: $userId
    privilegeLevel: $privilegeLevel
    OrganisationIds: $OrganisationIds
  )
}
`
