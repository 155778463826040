import { ReduxResponsive } from 'models/customTypings'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { dataTableReducer } from 'components/DataTable/containers/dataTableReducer'
import { searchBarReducer } from 'components/SearchBar/containers/searchBarReducer'
import locationReducer from './location'

export function browserSelector({ browser }: { browser: ReduxResponsive }) {
  return { browser }
}

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    location: locationReducer,
    form: formReducer,
    dataTable: dataTableReducer,
    searchBar: searchBarReducer,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
