import { IColInfo } from 'components/Table/Table'
import GraphQLTable, { DataExtractor } from 'components/GraphQLTable'
import queryPersonTimestamps from 'graphql/queries/queryPersonTimestamps'
import * as get from 'lodash.get'
import { IIpPerson, ITerminal } from 'models/interfaces'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalStorageToken } from 'store/localStorage'
import { TimestampDirectionEnum } from 'types/graphql-global-types'
import { personTimestamps_viewer_paginatedTimestamps_posts } from 'graphql/queries/types/personTimestamps'
import { getFormattedDateString } from 'utils/getFormattedDateString'

export interface Props {
  ipPerson?: IIpPerson
  ipPersonId?: number
}
export interface State {}
export const PersonTimestamps: React.FC<Props> = props => {
  const { t } = useTranslation()
  const colInfo: IColInfo[] = [
    {
      key: 'terminalName',
      titleTranslationKey: t('general.terminal'),
      sortable: false
    },
    {
      key: 'rfid',
      titleTranslationKey: 'general.rfid',
      sortable: true,
      minWidth: 100,
      hideOnMobile: true
    },
    { key: 'timeStamped', titleTranslationKey: 'general.time', sortable: true, minWidth: 150 },
    {
      key: 'timeSent',
      titleTranslationKey: 'general.status',
      sortable: true,
      minWidth: 150,
      hideOnMobile: true
    },
    { key: 'direction', titleTranslationKey: 'general.in_out', sortable: true }
  ]

  const extractData: DataExtractor = (data: any) => {
    const timestamps: personTimestamps_viewer_paginatedTimestamps_posts[] = get(
      data,
      'viewer.paginatedTimestamps.posts',
      []
    )
    const terminals: ITerminal[] = get(data, 'viewer.terminals', [])
    const nrOfPages = get(data, 'viewer.paginatedTimestamps.paginationInfo.totalPages', 1)

    const rows = timestamps.map(timestamp => {
      let direction = t('general.in')
      switch (timestamp.direction) {
        case TimestampDirectionEnum.Out:
          direction = t('general.out')
          break
        case TimestampDirectionEnum.BreakStart:
          direction = t('general.break_start')
          break
        case TimestampDirectionEnum.BreakEnd:
          direction = t('general.break_end')
          break
        case TimestampDirectionEnum.OutAbsence:
          direction = t('general.absence')
          break
        case TimestampDirectionEnum.SwitchOrder:
          direction = t('general.switch_order')
          break
        default:
          break
      }
      return {
        id: timestamp.id ?? '',
        colData: {
          terminalName: get(
            terminals.find(t => t.id === timestamp.TerminalId),
            'name',
            ''
          ),
          rfid: timestamp.rfid ?? '',
          timeStamped: getFormattedDateString(timestamp.timeStamped),
          timeSent: timestamp.timeSent ? (
            t('general.sent')
          ) : timestamp.simpleNotification ? (
            <>
              <div>{t('general.has_notification')}</div>
              <div className="text-gray-400">
                ({t(`NewNotifications.titles.${timestamp.simpleNotification?.type}`)})
              </div>
            </>
          ) : (
            t('general.waiting_to_be_sent')
          ),
          direction
        }
      }
    })

    return { rows, nrOfPages }
  }
  const { ipPerson, ipPersonId } = props
  const variables: any = {
    token: getLocalStorageToken(),
    orderBy: 'timeStamped',
    sortOrder: 'DESC'
  }
  typeof (ipPerson?.id ?? ipPersonId) !== 'undefined' &&
    (variables.ipPersonId = ipPerson?.id ?? ipPersonId)

  return (
    <GraphQLTable
      grow={true}
      colInfo={colInfo}
      extractData={extractData}
      pageSize={7}
      query={queryPersonTimestamps}
      queryVariables={variables}
      skip={typeof variables.ipPersonId === 'undefined'}
    />
  )
}
