import * as React from 'react'

interface HeaderSearchProviderValue {
  focus: boolean
  loading: boolean
  searchPhrase: string
  activeIndex: number
  activeLink: string
  nrOfLink: number
  searchResult: { [key: string]: { url: string; key: number; text: string; index: number }[] }
  actions: {
    focusSearch: () => void
    setSearchPhrase: (searchPhrase: string) => void
    decreaseActiveIndex: () => void
    increaseActiveIndex: () => void
    resetActiveIndex: () => void
    setData: (terminals: any[], persons: any[]) => void
    hasSearchResult: () => boolean
    clearSearchResult: () => void
    getActiveLink: () => string
    setLoading: (loading: boolean) => void
  }
}

const HeaderSearchContext = React.createContext<HeaderSearchProviderValue | null>(null)

class HeaderSearchProvider extends React.Component<{}, HeaderSearchProviderValue> {
  static readonly TERMINALS_CATEGORY = 'general.terminals'
  static readonly PERSONS_CATEGORY = 'general.people'
  static readonly NOTIFICATIONS_CATEGORY = 'general.notifications'

  private updateActiveIndex = (index: number) => {
    let newIndex = index
    if (newIndex < 0) {
      newIndex = 0
    } else if (newIndex >= this.state.nrOfLink) {
      newIndex = this.state.nrOfLink - 1
    }
    this.setState({ ...this.state, activeIndex: newIndex })
  }

  private setData = (terminals: any[], persons: any[]) => {
    let searchResult: {
      [key: string]: { url: string; key: number; text: string; index: number }[]
    } = {}

    let currIndex = -1

    searchResult[HeaderSearchProvider.TERMINALS_CATEGORY] = []
    searchResult[HeaderSearchProvider.PERSONS_CATEGORY] = []
    searchResult[HeaderSearchProvider.NOTIFICATIONS_CATEGORY] = []

    const terminalNotifications: any[] = []

    // Categories are placed in rows. To make index grow per column, we add notifications before persons
    terminals.map(t => {
      terminalNotifications.push({
        url: '/notifications?person=' + t.name,
        key: t.id,
        text: t.name + ' - ' + t.description + ' - ' + t.id
      })
      return searchResult[HeaderSearchProvider.TERMINALS_CATEGORY].push({
        url: '/terminals/' + t.id,
        key: t.id,
        text: t.name + ' - ' + t.description + ' - ' + t.id,
        index: ++currIndex
      })
    })
    terminalNotifications.map(termNoti =>
      searchResult[HeaderSearchProvider.NOTIFICATIONS_CATEGORY].push({
        ...termNoti,
        index: ++currIndex
      })
    )
    persons.map(p =>
      searchResult[HeaderSearchProvider.NOTIFICATIONS_CATEGORY].push({
        url: '/notifications?person=' + p.id,
        key: p.id,
        text: p.firstName + ' ' + p.surName + ' - ' + p.id,
        index: ++currIndex
      })
    )

    persons.map(p =>
      searchResult[HeaderSearchProvider.PERSONS_CATEGORY].push({
        url: `/persons?person=${p.id}&selectedPerson=${p.id}`,
        key: p.id,
        text: p.firstName + ' ' + p.surName + ' - ' + p.id,
        index: ++currIndex
      })
    )

    this.setState({ ...this.state, searchResult: searchResult, nrOfLink: currIndex + 1 })
  }

  private getUrlFromSearchResult(index: number): string {
    let result: any = null
    Object.keys(this.state.searchResult).map(category => {
      return !result && (result = this.state.searchResult[category].find(e => e.index === index))
    })

    return result ? result.url : ''
  }

  private getDefaultSearchResult = () => {
    return {
      [HeaderSearchProvider.TERMINALS_CATEGORY]: [],
      [HeaderSearchProvider.PERSONS_CATEGORY]: [],
      [HeaderSearchProvider.NOTIFICATIONS_CATEGORY]: []
    }
  }

  state: HeaderSearchProviderValue = {
    focus: false,
    loading: false,
    searchPhrase: '',
    activeIndex: 0,
    activeLink: '',
    nrOfLink: 0,
    searchResult: this.getDefaultSearchResult(),
    actions: {
      focusSearch: () => this.setState({ ...this.state, focus: true }),
      setSearchPhrase: (searchPhrase: string) =>
        this.setState({ ...this.state, searchPhrase: searchPhrase }),
      decreaseActiveIndex: () => this.updateActiveIndex(this.state.activeIndex - 1),
      increaseActiveIndex: () => this.updateActiveIndex(this.state.activeIndex + 1),
      resetActiveIndex: () => this.updateActiveIndex(0),
      setData: this.setData,
      hasSearchResult: () => {
        const terminalsResult = this.state.searchResult[HeaderSearchProvider.TERMINALS_CATEGORY]
        const personsResult = this.state.searchResult[HeaderSearchProvider.PERSONS_CATEGORY]
        const notiResult = this.state.searchResult[HeaderSearchProvider.NOTIFICATIONS_CATEGORY]
        return terminalsResult.length > 0 || personsResult.length > 0 || notiResult.length > 0
      },
      clearSearchResult: () => {
        this.setState({
          ...this.state,
          searchResult: this.getDefaultSearchResult(),
          nrOfLink: 0,
          focus: false,
          searchPhrase: ''
        })
      },
      getActiveLink: () => {
        this.state.actions.clearSearchResult()
        return this.getUrlFromSearchResult(this.state.activeIndex)
      },
      setLoading: (loading: boolean) => this.setState({ ...this.state, loading: loading })
    }
  }

  render() {
    return (
      <HeaderSearchContext.Provider value={this.state}>
        {this.props.children}
      </HeaderSearchContext.Provider>
    )
  }
}

export { HeaderSearchProvider, HeaderSearchContext }
