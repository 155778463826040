import * as React from 'react'

export interface Props {
  style?: any
  body: string | JSX.Element
  expandedBody?: string | JSX.Element
  right?: JSX.Element
  lowerBody?: string | JSX.Element
}
export interface State {
  expanded: boolean
}

class ListItem extends React.Component<Props, State> {
  private toggleExpandBody = () =>
    this.setState({ expanded: this.props.expandedBody ? !this.state.expanded : false })
  state = { expanded: false }
  render() {
    const { body, expandedBody, right, lowerBody } = this.props
    const { expanded } = this.state
    return (
      <div className={`flex flex-col bg-white flex-1 px-3 py-6`}>
        <div className={['flex flex-row'].join(' ')}>
          <div
            className={`flex flex-row flex-1 ${expandedBody ? 'cursor-pointer' : ''}`}
            onClick={this.toggleExpandBody}
          >
            <div className="flex-1 self-center">
              <div className="text-sm text-gray-800">{body}</div>
              {typeof lowerBody !== 'undefined' && lowerBody}
            </div>
          </div>
          <div className="flex-center flex-col sm:flex-row">{right}</div>
        </div>
        {expanded && expandedBody ? (
          <div className={`${expanded ? 'sm:px-0' : ''}`}>{expandedBody}</div>
        ) : null}
      </div>
    )
  }
}

export default ListItem
