import * as React from 'react'
import { SidebarMenuContext } from 'providers/SidebarMenuProvider'
import { HeaderSearchContext } from 'providers/HeaderSearchProvider'

export interface Props {}

const ContentOverlayContainer: React.SFC<Props> = props => {
  const { children } = props
  return (
    <SidebarMenuContext.Consumer>
      {o => {
        const { hidden, actions } = o ?? {}
        const { closeMenu } = actions ?? {}

        return (
          <HeaderSearchContext.Consumer>
            {p => {
              const { focus } = p ?? {}
              return (
                (!hidden || focus) && (
                  <div
                    onClick={closeMenu}
                    className="h-screen w-full fixed bg-black opacity-10 left-0 top-0 block z-[902]"
                  >
                    {children}
                  </div>
                )
              )
            }}
          </HeaderSearchContext.Consumer>
        )
      }}
    </SidebarMenuContext.Consumer>
  )
}

export default ContentOverlayContainer
