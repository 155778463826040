import ServerDownImage from './ErrorIcon.svg'
import Button from 'base-components/Button'
import React, { FC, useEffect, useState } from 'react'
import { useNetwork } from 'react-use'

const ErrorUpdateMessage: FC = () => {
  const { online: _online } = useNetwork()
  const [online, setOnline] = useState<boolean | null | undefined>(null)

  useEffect(() => {
    if (online == null || online === undefined) {
      setOnline(_online)
    }
  }, [online, _online])

  const [loading, setLoading] = useState(false)
  const handleUpdate = () => {
    setLoading(true)
    window.location.reload()
  }

  if (online == null) {
    return <div />
  }

  return (
    <div className="min-h-screen bg-gray-200 flex items-cente justify-center p-12">
      <div className="flex flex-col items-center max-w-2xl">
        <img src={ServerDownImage} alt="Server down" />
        <h1 className="text-5xl text-center font-bold text-gray-800 mt-10">
          {online && 'Flexime har uppdaterats'}
          {!online && 'Någonting gick fel'}
        </h1>
        <p className="mt-4 text-xl text-gray-500 text-center">
          {online &&
            `Flexime har uppdaterats och för att visa den sidan du försöker komma åt behöver du uppdatera webbläsaren. Klicka på "Ladda om
          sidan" nedan eller gör det direkt i webbläsaren.`}
          {!online &&
            `
            Någonting har gått fel när sidan skulle läsas in och det beror troligtvis på att du saknar internetanslutning. När anslutningen är tillbaka kan du klicka på "Ladda om sidan" nedan 
            eller ladda om direkt i webbläsaren.
          `}
        </p>
        <Button
          disabled={loading}
          onClick={handleUpdate}
          size="xl"
          className="mt-6 max-w-lg w-full text-xl"
        >
          <i className={`material-icons mr-2 ${loading && 'animate-spin'}`}>update</i>
          Ladda om sidan
        </Button>
      </div>
    </div>
  )
}

export default ErrorUpdateMessage
