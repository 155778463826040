import firebase from 'firebase'
import { removeLocalStorageToken } from 'store/localStorage'

const signOut = () => {
  removeLocalStorageToken()
  if (firebase?.auth()?.currentUser) {
    firebase
      ?.auth()
      ?.signOut()
      .then(a => console.log('Logged out', a))
  }

  window.location.reload()
}
export default signOut
