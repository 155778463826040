import { createImmutableMap, ImmutableMap } from 'models/AppState'

export const NOTIFICATION_CHANGE_TYPE = 'CHANGE_TYPE'
export const NOTIFICATION_CHANGE_TYPE_FILTER = 'NOTIFICATION_CHANGE_TYPE_FILTER'
export const NOTIFICATION_CHANGE_DATE_FILTER = 'NOTIFICATION_CHANGE_BEFORE_FILTER'
export const NOTIFICATION_FILTER_SEARCH = 'NOTIFICATION_FILTER_SEARCH'
export const NOTIFICATION_FILTER_FIELD_CHANGED = 'NOTIFICATION_FILTER_FIELD_CHANGED'
export const NOTIFICATION_CHANGE_SEARCH_TEXT = 'NOTIFICATION_CHANGE_SEARCH_TEXT'
export const NOTIFICATION_CHANGE_LIMIT = 'NOTIFICATION_CHANGE_LIMIT'

export interface INotificationsState {
  currentType: any
  searchText: string
  before: number | null
  after: number | null
  type: number | null
  limit: number
}

const initialState = createImmutableMap<INotificationsState>({
  currentType: null,
  searchText: '',
  before: null,
  after: null,
  type: null,
  limit: 10
})
export default function notificationsReducer(
  state: ImmutableMap<INotificationsState> = initialState,
  action: any
) {
  switch (action.type) {
    case NOTIFICATION_CHANGE_TYPE:
      return state.set('currentType', action.payload).set('limit', 10)
    case NOTIFICATION_CHANGE_SEARCH_TEXT:
      return state.set('searchText', action.payload).set('limit', 10)
    case NOTIFICATION_CHANGE_TYPE_FILTER:
      return state.set('type', action.payload).set('limit', 10)
    case NOTIFICATION_CHANGE_DATE_FILTER:
      return state
        .set('before', action.payload.before)
        .set('after', action.payload.after)
        .set('limit', 10)
    case NOTIFICATION_CHANGE_LIMIT:
      return state.set('limit', state.get('limit') + 10)
    default:
      return state
  }
}
