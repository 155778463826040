import { createImmutableMap, ImmutableMap } from 'models/AppState'
import { LOCATION_CHANGE } from 'store/location'

export const RULE_WIZARD_NEXT_STEP = 'RULE_WIZARD_NEXT_STEP'
export const RULE_WIZARD_PREV_STEP = 'IP_ORDER_WIZARD_PREV_STEP'
export const RULE_WIZARD_RESET = 'RULE_WIZARD_RESET'
export const RULE_WIZARD_ADD_PERSON = 'RULE_WIZARD_ADD_PERSON'
export const RULE_WIZARD_IP_ORDER_FETCHED = 'RULE_WIZARD_IP_ORDER_FETCHED'
export const RULE_WIZARD_IP_ORDER_LOADING = 'RULE_WIZARD_IP_ORDER_LOADING'
export const RULE_WIZARD_IP_ORDER_NOT_FOUND = 'RULE_WIZARD_IP_ORDER_NOT_FOUND'

export interface IRuleWizardState {
  stepIndex: number
  finished: boolean
  queryLoading: boolean
  ipOrderNotFound: boolean
  ipOrder: any
  ipOrderId: string | null
}

const initialState = createImmutableMap<IRuleWizardState>({
  stepIndex: 0,
  finished: false,
  queryLoading: false,
  ipOrderNotFound: false,
  ipOrder: null,
  ipOrderId: null
})
export function ipOrderRuleWizardReducer(
  state: ImmutableMap<IRuleWizardState> = initialState,
  action: any
) {
  switch (action.type) {
    case RULE_WIZARD_NEXT_STEP || RULE_WIZARD_ADD_PERSON:
      state = state.set('stepIndex', Number(state.get('stepIndex')) + 1)
      return state.set('finished', Number(state.get('stepIndex')) >= 2)
    case RULE_WIZARD_PREV_STEP:
      const stepIndex = Number(state.get('stepIndex'))
      if (stepIndex > 0) {
        return state
          .set('stepIndex', stepIndex - 1)
          .set('ipOrder', null)
          .set('ipOrderId', null)
      }
      return state
    case RULE_WIZARD_RESET:
      return initialState
    case RULE_WIZARD_IP_ORDER_LOADING:
      return state.set('queryLoading', action.payload)
    case RULE_WIZARD_IP_ORDER_NOT_FOUND:
      return state.set('queryLoading', false)
    case RULE_WIZARD_IP_ORDER_FETCHED:
      return state
        .set('queryLoading', false)
        .set('ipOrder', action.ipOrder)
        .set('stepIndex', 1)
    case LOCATION_CHANGE:
      return initialState
    default:
      return state
  }
}
