import React from 'react'
import { NotificationTypeEnum } from 'types/graphql-global-types'
import { NotificationsTimelineList } from './NotificationsTimelineList'

interface Props {
  dateFrom: Date
  dateTo: Date
}
const AlreadyAttested: React.FC<Props> = ({ dateFrom, dateTo }) => {
  return (
    <NotificationsTimelineList
      dateFrom={dateFrom}
      dateTo={dateTo}
      notificationType={NotificationTypeEnum.WorkdayAlreadyAttested}
    />
  )
}

export default AlreadyAttested
