import gql from 'graphql-tag' 
export default gql`
query ipWorkplacesList($token: String!, $terminalId: Int, $first: Int!, $after: Int!, $filterText: String) {
  viewer(token: $token) {
    id
    ipWorkplacesPaginated(TerminalId: $terminalId, first: $first, after: $after, filterText: $filterText) {
      paginationInfo {
        id
        totalPages
        pageSize
        currentPage
      }
      posts {
        id
        workPlaceName
        workPlaceNo
      }
    }
  }
}
`
