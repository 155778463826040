import * as React from 'react'
import moment, { Moment } from 'moment'
import ActionBase from 'components/Timeline/WorkdayActions/ActionBase/ActionBase'
import { ITimestamp, IIpWorkday, ITimestampRule } from 'models/interfaces'
import mutationAddOrder from 'graphql/mutations/mutationAddOrder'
import { getLocalStorageToken } from 'store/localStorage'

import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { TimestampRuleTypeEnum } from 'types/graphql-global-types'
import { addOrder, addOrderVariables } from 'graphql/mutations/types/addOrder'

export interface Props {
  timestamp: ITimestamp
  workday: IIpWorkday
  outerRules: ITimestampRule[]
  innerRules: ITimestampRule[]
  existsOnTerminal: boolean
}

const ActionAddWorkdayToTerminal: React.FC<Props> = ({
  timestamp,
  workday,
  existsOnTerminal,
  outerRules,
  innerRules
}) => {
  const { t } = useTranslation()
  const [addOrderMutation] = useMutation<addOrder, addOrderVariables>(mutationAddOrder, {
    refetchQueries: ['queryPersonWorkdays', 'queryTerminalWorkplacesOrders']
  })
  const isActive = () => {
    const ruleDuration = [...outerRules, ...innerRules].reduce(
      (a, next) => (next.duration > a ? next.duration : a),
      0
    )

    // All the timestamp rules have the same type, looking at the first is enough
    let ruleType: TimestampRuleTypeEnum | undefined = undefined
    if (outerRules.length > 0) {
      ruleType = outerRules[0].type
    } else if (innerRules.length > 0) {
      ruleType = innerRules[0].type
    }

    let from = moment(workday.originalDateFrom || workday.dateFrom)
    let to = moment(workday.originalDateTo || workday.dateTo)
    const timestamped = moment(timestamp.timeStamped)
    let temp: Moment | null = null

    switch (ruleType) {
      case TimestampRuleTypeEnum.InBeforeStart:
        from.subtract(ruleDuration, 'milliseconds')
        break
      case TimestampRuleTypeEnum.InAfterStart:
        temp = from.clone().add(ruleDuration, 'milliseconds')
        if (temp > to) {
          to = temp
        }
        break
      case TimestampRuleTypeEnum.OutAfterEnd:
        to.add(ruleDuration, 'milliseconds')
        break
      case TimestampRuleTypeEnum.OutBeforeEnd:
        temp = to.clone().subtract(ruleDuration, 'milliseconds')
        if (temp < from) {
          from = temp
        }
        break
      default:
        break
    }

    return !existsOnTerminal && timestamped >= from && timestamped <= to
  }

  const addOrder = async () => {
    try {
      if (workday?.ipOrder?.id) {
        await addOrderMutation({
          variables: {
            token: getLocalStorageToken(),
            terminalId: timestamp.TerminalId,
            ipOrderId: workday.ipOrder.id
          }
        })
      } else {
        throw new Error('workday iporder id was empty')
      }
      toast.success((t('ActionAddWorkdayToTerminal.orderAdded') as string) ?? '')
    } catch (error) {
      toast.error((t('ActionAddWorkdayToTerminal.orderAddedFailed') as string) ?? '')
    }
  }
  const onClick = () => addOrder()
  return (
    <ActionBase
      text={t('ActionAddWorkdayToTerminal.description')}
      onClick={onClick}
      isActive={isActive()}
    />
  )
}

export default ActionAddWorkdayToTerminal
