import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import SidebarMenu from 'components/SidebarMenu/SidebarMenu'

import ContentOverlay from 'containers/ContentOverlay/ContentOverlayContainer'
import Header from 'containers/Header/Header'
import { HeaderSearchProvider } from 'providers/HeaderSearchProvider'
import { OrganisationsProvider } from 'providers/OrganisationsProvider'
import { GoogleMapsLoadedProvider } from 'providers/GoogleMapsLoadedProvider'
import { SidebarMenuProvider } from 'providers/SidebarMenuProvider'
import * as React from 'react'
import { Suspense } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Route, Switch } from 'react-router-dom'
import NewNotifications from 'routes/NewNotifications'

const NotificationsContainer = React.lazy(() =>
  import('containers/Notifications/NotificationsContainer')
)
const UserSettingsContainer = React.lazy(() =>
  import('components/UserSettings/containers/UserSettingsContainer')
)
const NotFound = React.lazy(() => import('routes/404'))
const AdminView = React.lazy(() => import('routes/AdminView/AdminView'))
const Dashboard = React.lazy(() => import('routes/Dashboard/Dashboard'))

const FileImportListView = React.lazy(() => import('routes/FileImportListView/FileImportListView'))
const FileImportView = React.lazy(() => import('routes/FileImportView/FileImportView'))
const PersonsView = React.lazy(() => import('routes/PersonView/PersonView'))
const RulesView = React.lazy(() => import('routes/RuleView/RuleViewContainer'))
const TerminalView = React.lazy(() => import('routes/TerminalView/TerminalView'))
const GeoFenceView = React.lazy(() => import('routes/GeoFenceView/GeoFenceView'))

const SuperAdminView = React.lazy(() => import('routes/SuperAdminView/SuperAdminView'))

export interface IAppLayout {
  changePassword: number
  children: any
  browser: any
  drawerOpen: boolean
  currentRoute: string
  title: string
  toggleDrawer(open: boolean)
  logout()
  selectMenuItem(id: string)
}
const AppLayout: React.FC<IAppLayout> = props => {
  return (
    <div className="min-h-full bg-[#edf2f7]">
      <SidebarMenuProvider>
        <OrganisationsProvider>
          <GoogleMapsLoadedProvider>
            <HeaderSearchProvider>
              <Header />
              <SidebarMenu />
              <ContentOverlay />
            </HeaderSearchProvider>
            <div className="h-[75px] w-full" />
            <div className="flex flex-row ">
              <div className="sm:w-[75px]" />
              <div className="flex-1">
                <ScrollToTop>
                  <Suspense fallback={<Skeleton count={10} />}>
                    <Switch>
                      {props.changePassword && <Route path="/" component={UserSettingsContainer} />}
                      <Route path="/terminals/:id" component={TerminalView} />
                      <Route path="/notifications" component={NotificationsContainer} />
                      <Route path="/newNotifications" component={NewNotifications} />
                      <Route path="/persons" component={PersonsView} />
                      <Route path="/rules" component={RulesView} />
                      <Route path="/geofences" component={GeoFenceView} />
                      <Route path="/admin" component={AdminView} />
                      <Route path="/fileimports/:id" component={FileImportView} />
                      <Route path="/fileimports" component={FileImportListView} />
                      <Route path="/userSettings" component={UserSettingsContainer} />

                      <Route path="/superadmin" component={SuperAdminView} />

                      <Route exact path="/" component={Dashboard} />
                      <Route component={NotFound} />
                    </Switch>
                  </Suspense>
                </ScrollToTop>
              </div>
            </div>
          </GoogleMapsLoadedProvider>
        </OrganisationsProvider>
      </SidebarMenuProvider>
    </div>
  )
}

export default AppLayout
