import * as React from 'react'
import { SearchBoxWrapper, SearchButton, SearchIconBox, SearchTextField } from './SearchBar.style'
import { ISearchBarContainer } from 'components/SearchBar/containers/SearchBarContainer'
import { parse } from 'qs'
import { Translation } from 'react-i18next'

export default class SearchBar extends React.Component<ISearchBarContainer, any> {
  textFieldValue: string = ''

  private searchChange = (e: any) => {
    this.textFieldValue = e.target.value
    if (this.props.searchBarChanged) {
      this.props.searchBarChanged(e.target.value)
    }
  }

  componentDidMount() {
    const params = parse(window.location.search, { ignoreQueryPrefix: true })
    if (this.props.urlParam) {
      const urlParamVal = params[this.props.urlParam]
      if (typeof urlParamVal === 'string' && urlParamVal && urlParamVal !== '') {
        this.props.searchBarChanged(urlParamVal)
        this.props.searchBarSearch(urlParamVal)
      }
    }
  }

  render() {
    const {
      searchBarSearch,
      searchBarCurrentText,
      searchType,
      searchBarId,
      icon,
      style,
      label,
      className
    } = this.props
    const searchButtonClickHandle = () => {
      searchBarSearch(searchBarCurrentText)
    }

    return (
      <SearchBoxWrapper style={style ? style : {}} className={className}>
        <div className="rounded-[5px] h-[40px] flex items-center pt-2 pb-2">
          <SearchIconBox icon={icon} />
          <SearchTextField
            id={searchBarId}
            type={searchType}
            name={searchBarId}
            onChange={this.searchChange}
            value={searchBarCurrentText}
          />
          <Translation>
            {t => (
              <SearchButton
                onClick={searchButtonClickHandle}
                disabled={searchBarId === '' || searchBarId == null || this.textFieldValue === ''}
                type={'submit'}
              >
                {label ? label : t('general.search')}
              </SearchButton>
            )}
          </Translation>
        </div>
      </SearchBoxWrapper>
    )
  }

  componentWillUnmount() {
    this.props.searchBarSearch('')
  }
}
