import React, { useState } from 'react'
import queryNotificationsTree from '../graphql/queries/queryNotificationsTree'
import { getLocalStorageToken } from 'store/localStorage'
import { useMutation, useQuery } from '@apollo/client'
import { FleximeLoader } from 'components/FleximeLoader/FleximeLoader'
import { Error } from 'base-components/Error'
import ActionPanel from 'base-components/ActionPanel'
import { IconButton } from '@material-ui/core'
import {
  notificationsTree,
  notificationsTreeVariables,
  notificationsTree_viewer_notificationsTree_terminals,
  notificationsTree_viewer_notificationsTree_terminals_ipWorkplaces,
  notificationsTree_viewer_notificationsTree_terminals_ipWorkplaces_ipOrders,
  notificationsTree_viewer_notificationsTree_terminals_ipWorkplaces_ipOrders_ipPersons_notifications
} from '../graphql/queries/types/notificationsTree'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import AddIcon from '@material-ui/icons/Add'
import { TerminalIcon } from 'components/TerminalIcon/TerminalIcon'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import ApartmentIcon from '@material-ui/icons/Apartment'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Tooltip from '@material-ui/core/Tooltip'
import mutationAddIpOrder from 'graphql/mutations/mutationAddOrder'
import mutationAddIpWorkplace from 'graphql/mutations/mutationAddIpWorkplace'
import { addOrder, addOrderVariables } from 'graphql/mutations/types/addOrder'
import { addIpWorkplace, addIpWorkplaceVariables } from 'graphql/mutations/types/addIpWorkplace'
import { useConfirmDialog } from 'hooks/useConfirmDialog'
import useUpdateTimestampRemoveNotification from '../hooks/useUpdateTimestampRemoveNotification'
import { SelectObjectDialog } from './SelectObjectDialog'
import { useOrganisations } from 'hooks/useOrganisations'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NotificationTypeEnum } from 'types/graphql-global-types'

interface Props {
  dateFrom: Date
  dateTo: Date
}

const TreeNode: React.FC<{
  text: string | React.ReactElement
  icon: React.ReactNode
  buttonInfo?: { icon: React.ReactNode; tooltip: string; onClick: () => void }
}> = ({ text, icon, buttonInfo, children }) => {
  return (
    <div className="ml-6">
      <div className="hover:bg-gray-200 my-2">
        <div className="flex flex-row items-center">
          <span className="m-1">{icon}</span>
          {text}
          <div className="flex flex-row flex-1 justify-end ml-2">
            {buttonInfo && (
              <Tooltip title={buttonInfo.tooltip}>
                <IconButton size="small" onClick={() => buttonInfo.onClick()}>
                  {buttonInfo.icon}
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}

export const NotificationsOrderWorkplaceMissing: React.FC<Props> = ({ dateFrom, dateTo }) => {
  const { excludeOrganisationIds } = useOrganisations()
  const {
    data: notificationsData,
    loading: notificationsLoading,
    error: notificationsError
  } = useQuery<notificationsTree, notificationsTreeVariables>(queryNotificationsTree, {
    variables: {
      token: getLocalStorageToken(),
      type: NotificationTypeEnum.TerminalMissingOrderOrWorkplace,
      after: dateFrom.valueOf(),
      before: dateTo.valueOf(),
      excludeOrganisationIds
    },
    skip: excludeOrganisationIds === null
  })

  const { t } = useTranslation()

  const [addIpOrder] = useMutation<addOrder, addOrderVariables>(mutationAddIpOrder)
  const [addIpWorkplace] = useMutation<addIpWorkplace, addIpWorkplaceVariables>(
    mutationAddIpWorkplace
  )
  const updateTimestampRemoveNotification = useUpdateTimestampRemoveNotification()

  const { showDialog, dialogComponent } = useConfirmDialog()

  const [updateNotifications, setUpdateNotifications] = useState<
    | (notificationsTree_viewer_notificationsTree_terminals_ipWorkplaces_ipOrders_ipPersons_notifications | null)[]
    | null
    | undefined
  >(undefined)

  if (notificationsLoading) {
    return <FleximeLoader size={32} />
  }
  if (notificationsError) {
    return <Error error={notificationsError} />
  }

  const onWorkplaceClick = (
    ipWorkplace: notificationsTree_viewer_notificationsTree_terminals_ipWorkplaces | null,
    terminal: notificationsTree_viewer_notificationsTree_terminals | null
  ) => {
    showDialog({
      title: t('NewNotifications.NotificationsOrderWorkplaceMissing.dialogAdd.title', {
        itemType: t('general.workplace').toLowerCase()
      }),
      body: t('NewNotifications.NotificationsOrderWorkplaceMissing.dialogAdd.body', {
        itemType: t('general.workplace').toLowerCase(),
        addId: ipWorkplace?.workplaceNo,
        terminalId: terminal?.id
      }),
      confirm: {
        text: t('general.yes'),
        callback: async () => {
          await addIpWorkplace({
            variables: {
              token: getLocalStorageToken(),
              terminalId: terminal?.id,
              ipWorkplaceId: Number(ipWorkplace?.id)
            },
            refetchQueries: ['notificationsTree']
          })
        }
      }
    })
  }

  const onOrderClick = (
    ipOrder: notificationsTree_viewer_notificationsTree_terminals_ipWorkplaces_ipOrders | null,
    terminal: notificationsTree_viewer_notificationsTree_terminals | null
  ) => {
    showDialog({
      title: t('NewNotifications.NotificationsOrderWorkplaceMissing.dialogAdd.title', {
        itemType: t('general.order').toLowerCase()
      }),
      body: t('NewNotifications.NotificationsOrderWorkplaceMissing.dialogAdd.body', {
        itemType: t('general.order').toLowerCase(),
        addId: ipOrder?.id,
        terminalId: terminal?.id
      }),
      confirm: {
        text: t('general.yes'),
        callback: async () => {
          await addIpOrder({
            variables: {
              token: getLocalStorageToken(),
              terminalId: terminal?.id,
              ipOrderId: Number(ipOrder?.id)
            },
            refetchQueries: ['notificationsTree']
          })
        }
      }
    })
  }

  const onPersonClick = async (newTerminalId: number | undefined) => {
    if (
      typeof updateNotifications === 'undefined' ||
      !Array.isArray(updateNotifications) ||
      updateNotifications.length === 0 ||
      typeof newTerminalId === 'undefined'
    ) {
      console.log('Missing argument to onPersonclick')
      return
    }
    await updateTimestampRemoveNotification({
      notifications: updateNotifications,
      timeSend: new Date().valueOf(),
      sendAttempts: 5,
      terminalId: newTerminalId,
      successText: t('NewNotifications.NotificationsOrderWorkplaceMissing.moveTimestamps.success', {
        newTerminalId
      })
    })
  }
  const empty = !Boolean(notificationsData?.viewer?.notificationsTree?.terminals?.length)
  if (empty) {
    return <div className="py-8 text-lg">{t('NewNotifications.noNotifications')} 🎉</div>
  }
  return (
    <>
      <SelectObjectDialog
        title={t('NewNotifications.NotificationsOrderWorkplaceMissing.moveDialog.title')}
        text={t('NewNotifications.NotificationsOrderWorkplaceMissing.moveDialog.body')}
        isShowing={typeof updateNotifications !== 'undefined'}
        onSelect={onPersonClick}
        onClose={() => setUpdateNotifications(undefined)}
        type={'Terminal'}
      />
      {dialogComponent}
      <ActionPanel
        className="mt-5"
        title={t('NewNotifications.titles.' + NotificationTypeEnum.TerminalMissingOrderOrWorkplace)}
        content={notificationsData?.viewer?.notificationsTree?.terminals?.map(terminal => {
          return (
            <TreeNode
              key={`${terminal?.id}`}
              text={
                <Link to={`/terminals/${terminal?.id}`}>
                  {terminal?.description} ({terminal?.name})
                </Link>
              }
              icon={<TerminalIcon isVirtual={false} />}
            >
              {terminal?.ipWorkplaces?.map(ipWorkplace => {
                return (
                  <TreeNode
                    key={`${terminal?.id}_${ipWorkplace?.id}`}
                    text={`${ipWorkplace?.workplaceName} (${ipWorkplace?.workplaceNo})`}
                    icon={<ApartmentIcon />}
                    buttonInfo={{
                      icon: <AddIcon />,
                      tooltip: t('NewNotifications.NotificationsOrderWorkplaceMissing.addHover', {
                        itemType: t('general.workplace').toLowerCase(),
                        addId: ipWorkplace?.workplaceNo,
                        terminalId: terminal?.id
                      }),
                      onClick: () => onWorkplaceClick(ipWorkplace, terminal)
                    }}
                  >
                    {ipWorkplace?.ipOrders?.map(ipOrder => {
                      return (
                        <TreeNode
                          key={`${terminal.id}_${ipOrder?.id}`}
                          text={`${ipOrder?.name} (${ipOrder?.id})`}
                          icon={<AttachMoneyIcon />}
                          buttonInfo={{
                            icon: <AddIcon />,
                            tooltip: t(
                              'NewNotifications.NotificationsOrderWorkplaceMissing.addHover',
                              {
                                itemType: t('general.order').toLowerCase(),
                                addId: ipOrder?.id,
                                terminalId: terminal?.id
                              }
                            ),
                            onClick: () => onOrderClick(ipOrder, terminal)
                          }}
                        >
                          {ipOrder?.ipPersons?.map(ipPerson => {
                            return (
                              <TreeNode
                                key={`${terminal.id}_${ipOrder.id}_${ipPerson?.id}`}
                                text={`${ipPerson?.firstName} ${ipPerson?.surName} (${ipPerson?.id}) har ${ipPerson?.notifications?.length} tider`}
                                icon={<SupervisorAccountIcon />}
                                buttonInfo={{
                                  icon: <SyncAltIcon />,
                                  tooltip: t(
                                    'NewNotifications.NotificationsOrderWorkplaceMissing.moveHover',
                                    { ipPersonId: ipPerson?.id }
                                  ),
                                  onClick: () => {
                                    setUpdateNotifications(ipPerson?.notifications ?? [])
                                  }
                                }}
                              />
                            )
                          })}
                        </TreeNode>
                      )
                    })}
                  </TreeNode>
                )
              })}
            </TreeNode>
          )
        })}
      />
    </>
  )
}
