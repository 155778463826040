import { useQuery } from '@apollo/client'
import queryCustomerSettings from 'graphql/queries/queryCustomerSettings'
import {
  CustomerSettings,
  CustomerSettings_customerSettings
} from 'graphql/queries/types/CustomerSettings'

interface LoadingInterface {
  loading: boolean
}
type TestType = CustomerSettings_customerSettings & LoadingInterface

export function useCustomerSettings(): TestType {
  const { data, loading } = useQuery<CustomerSettings>(queryCustomerSettings)
  const customerSettings = data?.customerSettings
  if (loading) {
    return { loading } as any
  } else {
    if (customerSettings) {
      return { ...customerSettings, loading }
    } else {
      return { loading: false } as any
    }
  }
}
