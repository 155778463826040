import { DATA_TABLE_CHANGE_PAGE } from 'components/DataTable/containers/dataTableReducer'
import { ADMIN_USERS_ID, ADMIN_USERS_PAGE_SIZE } from './AdminUsersContainer'
import { AppState } from 'models/AppState'

export const ADMIN_CREATE_USER_UPDATE = 'ADMIN_CREATE_USER_UPDATE'

export interface IAdminUsersConnectedState {
  createUserForm: any
  createUserStatus: { success: boolean; message: string }
}
export const adminUsersMapStateToProps = (state: AppState): IAdminUsersConnectedState => ({
  createUserStatus: { success: true, message: '' }, // state.admin.get('createUserStatus'),
  createUserForm: 0 // state.form.createUser,
})

export interface IAdminUsersConnectedDispatch {
  resetPagination: () => void
}
export const adminUsersMapDispatchToProps: IAdminUsersConnectedDispatch = {
  resetPagination: () => ({
    type: DATA_TABLE_CHANGE_PAGE,
    payload: { id: ADMIN_USERS_ID, pageInfo: { first: 0, after: ADMIN_USERS_PAGE_SIZE } }
  })
}
