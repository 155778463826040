import toast from 'react-hot-toast'
import { getLocalStorageToken } from 'store/localStorage'
import { TextField, Dialog, Button, DialogTitle, DialogContent } from '@material-ui/core'
import * as React from 'react'
import { CSSProperties, useState } from 'react'

import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { updatePassword, updatePasswordVariables } from './types/updatePassword'

export interface Props {
  userId: number | null
  onClose: () => void
}
export interface State {
  password1: string
  password2: string
  error: string
}

const CHANGE_PASSWORD = gql`
  mutation updatePassword($id: Int!, $token: String!, $newPassword: String!) {
    updateUser(id: $id, token: $token, newPassword: $newPassword)
  }
`

const AdminChangeUserPassword: React.FC<Props> = ({ userId, onClose }) => {
  const [error, setError] = useState('')
  const [password, setPassword] = useState({ password1: '', password2: '' })
  const { t } = useTranslation()
  const [changePassword] = useMutation<updatePassword, updatePasswordVariables>(CHANGE_PASSWORD, {
    onCompleted: () => {
      toast.success((t('AdminChangeUserPassword.passwordChanged') as string) ?? '')
      onClose()
    }
  })

  const onChange = (event: any) => {
    setPassword({ ...password, [event.target.name]: event.target.value })
  }
  const save = () => {
    const { password1, password2 } = password
    if (password1.length < 5 || password2.length < 5) {
      setError(t('Password.incorrectLength'))
    } else if (password1 !== password2) {
      setError(t('AdminChangeUserPassword.passwordNotMatching'))
    } else {
      if (userId) {
        changePassword({
          variables: { id: userId, token: getLocalStorageToken(), newPassword: password1 }
        })
      }
    }
  }
  const actions = [
    <Button key={2} color="primary" onClick={save}>
      {t('general.save')}
    </Button>,
    <Button key={1} color="secondary" onClick={onClose}>
      {t('general.cancel')}
    </Button>
  ]
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{t('AdminChangeUserPassword.changePassword')}</DialogTitle>
      <DialogContent>
        <div style={styles.dialogContentStyle}>
          <div style={styles.iconContainer}>
            <i className={'zmdi zmdi-key'} />
          </div>
          <TextField
            label={t('AdminChangeUserPassword.newPassword')}
            style={styles.textField}
            type="password"
            onChange={onChange}
            value={password.password1}
            name={'password1'}
          />
          <div style={styles.iconContainer}>
            <i className={'zmdi zmdi-key'} />
          </div>
          <TextField
            label={t('AdminChangeUserPassword.newPasswordRepeat')}
            type="password"
            onChange={onChange}
            value={password.password2}
            name={'password2'}
          />
        </div>
        <span style={styles.errorText}>{error ?? null}</span>
      </DialogContent>
      {actions}
    </Dialog>
  )
}

const styles: { [s: string]: CSSProperties } = {
  textField: { marginRight: 30 },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
    display: 'flex'
  },
  dialogContentStyle: {
    flexDirection: 'row',
    display: 'flex'
  },
  errorText: {
    color: 'red'
  }
}

export default AdminChangeUserPassword
