import { createImmutableMap, ImmutableMap } from 'models/AppState'
import {
  getLocalStorageToken,
  removeLocalStorageToken,
  setLocalStorage
} from '../../store/localStorage'

export const LOGIN_UPDATE_TOKEN = 'LOGIN_UPDATE_TOKEN'
export const LOGIN_SUBMIT = 'LOGIN_SUBMIT'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_REMOVE_TOKEN = 'LOGIN_REMOVE_TOKEN'

export interface ILoginState {
  loggingIn: boolean
  loginError: boolean
  token: string
}

const initialState = createImmutableMap<ILoginState>({
  loggingIn: false,
  loginError: false,
  token: ''
})
export function loginReducer(state: ImmutableMap<ILoginState> = initialState, action: any) {
  switch (action.type) {
    case LOGIN_REMOVE_TOKEN:
      removeLocalStorageToken()
      return state.set('token', action.payload)
    case LOGIN_UPDATE_TOKEN:
      setLocalStorage('token', action.payload)
      return state
        .set('loggingIn', false)
        .set('loginError', false)
        .set('token', action.payload)
    case LOGIN_SUBMIT:
      return state.set('loggingIn', true).set('loginError', false)
    case LOGIN_ERROR:
      return state.set('loggingIn', false).set('loginError', true)
    default:
      return getLocalStorageToken() && !state.get('token')
        ? state.set('token', getLocalStorageToken())
        : state
  }
}
