import * as React from 'react'
import * as get from 'lodash.get'
import GraphQLTable, { DataExtractor } from 'components/GraphQLTable'
import queryOrganisationsList from 'graphql/queries/queryOrganisationsList'
import { useLayer } from 'react-laag'
import { IColInfo } from 'components/Table/Table'
import { getLocalStorageToken } from 'store/localStorage'
import { AiFillDelete } from 'react-icons/ai'
import { useConfirmDialog } from 'hooks/useConfirmDialog'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import { FaPen } from 'react-icons/fa'
import Button from 'base-components/Button'
import { useForm, useWatch } from 'react-hook-form'

interface OrganisationNameFieldProps {
  organisation: { name: string; id: number }
  onSave: (organisation: { name: string; id: number }) => void
}
const OrganisationNameField: React.FC<OrganisationNameFieldProps> = ({ organisation, onSave }) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = React.useState(false)

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: () => setOpen(false)
  })
  const { register, control, setValue } = useForm()
  const inputValues = useWatch({ control })
  const handleOpenEditTooltip = organisation => () => {
    setValue('name', organisation.name)
    setOpen(!isOpen)
  }
  const handleSave = () => {
    onSave({ id: organisation.id, name: inputValues.name })
    setOpen(false)
  }
  return (
    <>
      <button
        {...triggerProps}
        onClick={handleOpenEditTooltip(organisation)}
        className="flex items-center justify-start flex-row"
      >
        <FaPen />
        <span className="ml-2">{organisation.name}</span>
      </button>
      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            className="bg-white rounded-xl p-2 shadow-lg sm:space-x-2 sm:space-y-0 sm:flex-row flex flex-col items-center justify-center space-y-2"
          >
            <input
              {...register('name')}
              placeholder={t('general.name')}
              className="outline-none focus:ring-1 w-full my-2 h-8 bg-gray-50 border-gray-200 border rounded-full placeholder-gray-600 px-2.5 text-sm"
            />
            <Button onClick={handleSave}>{t('general.save')}</Button>
            <Button variant="secondary" onClick={() => setOpen(false)}>
              {t('general.cancel')}
            </Button>
          </div>
        )}
    </>
  )
}

const colInfo: IColInfo[] = [
  { key: 'id', titleTranslationKey: 'general.id', sortable: true },
  { key: 'name', titleTranslationKey: 'general.name', sortable: true },
  { key: 'removeOrganisation', sortable: false }
]
export interface Props {
  searchText: string
  removeOrganisation: (id: number) => void
  updateOrganisationName: (OrganisationId: number, newName: string) => void
}
export interface State {}

const OrganisationsList: React.FC<Props> = props => {
  const { dialogComponent, showDialog } = useConfirmDialog()
  const { t } = useTranslation()

  const extractData: DataExtractor = (data: any) => {
    const { removeOrganisation, updateOrganisationName } = props
    const organisations = get(data, 'viewer.organisationsPaginated.posts', [])
    const nrOfPages = get(data, 'viewer.organisationsPaginated.paginationInfo.totalPages', 1)
    const handleSave = (organisation: { name: string; id: number }) => {
      updateOrganisationName(organisation.id, organisation.name)
    }
    const rows = organisations.map(organisation => ({
      id: organisation.id,
      colData: {
        id: organisation.id,
        name: <OrganisationNameField onSave={handleSave} organisation={organisation} />,
        removeOrganisation: (
          <div className="w-full flex justify-end">
            <Tooltip arrow={true} placement="top" title={t('general.remove') ?? ''}>
              <button
                onClick={() => {
                  showDialog({
                    title: t('general.remove'),
                    body: (
                      <span>
                        {t('OrganisationsContainer.dialogTitle')} ({organisation.name})
                      </span>
                    ),
                    confirm: {
                      text: t('general.yes'),
                      callback: async () => removeOrganisation(organisation.id)
                    },
                    cancel: {
                      text: t('general.no')
                    }
                  })
                }}
              >
                <AiFillDelete />
              </button>
            </Tooltip>
          </div>
        )
      }
    }))

    return { rows: rows, nrOfPages }
  }
  const { searchText } = props

  const variables = {
    token: getLocalStorageToken(),
    filterText: searchText
  }

  return (
    <>
      {dialogComponent}
      <GraphQLTable
        colInfo={colInfo}
        extractData={extractData}
        pageSize={5}
        query={queryOrganisationsList}
        queryVariables={variables}
        fetchCacheAndNetwork={true}
      />
    </>
  )
}
export default OrganisationsList
