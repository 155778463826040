import Input from 'base-components/Input'
import SelectMenu from 'base-components/SelectMenu'
import DashboardCard from 'components/DashboardCard/DashboardCard'
import { useDefault } from 'react-use'
import React, { useEffect } from 'react'
import Button from 'base-components/Button'
import Modal from 'base-components/Modal'
import useOpenState from 'hooks/useOpenState'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { queryTimestampRules_viewer_timestampRules } from 'graphql/queries/types/queryTimestampRules'
import { OnUpdateTimestampRule } from './ScheduleSection'
import {
  TimestampRuleAttestTypeEnum,
  TimestampRuleDurationTypeEnum,
  TimestampRuleRoundingTypeEnum,
  TimestampRuleTypeEnum
} from 'types/graphql-global-types'
interface Props {
  timestampRule: queryTimestampRules_viewer_timestampRules | undefined | null
  isLast?: boolean
  onDelete?: (id: number) => void
  onUpdate?: OnUpdateTimestampRule
}

const EditRule: React.FC<Props> = ({ timestampRule, isLast, onDelete, onUpdate }) => {
  const { t } = useTranslation()
  const {
    duration = 0,
    type = TimestampRuleTypeEnum.InBeforeStart,
    attestType = TimestampRuleAttestTypeEnum.None,
    roundingType = TimestampRuleRoundingTypeEnum.None,
    roundingTime = 0,
    durationType = TimestampRuleDurationTypeEnum.Time,
    id
  } = timestampRule || {}

  const durationTypes = useMemo(
    () => [
      { id: TimestampRuleDurationTypeEnum.Time, title: t('EditRule.capitalMinutes') },
      { id: TimestampRuleDurationTypeEnum.Rest, title: t('EditRule.restOfWorkday') }
    ],
    [t]
  )
  const attestTypes = useMemo(
    () => [
      { id: TimestampRuleAttestTypeEnum.None, title: t('EditRule.noAttest') },
      { id: TimestampRuleAttestTypeEnum.Attest, title: t('EditRule.attest') }
    ],
    [t]
  )
  const roundingTypes = useMemo(
    () => [
      { id: TimestampRuleRoundingTypeEnum.None, title: t('EditRule.notRounding') },
      {
        id: TimestampRuleRoundingTypeEnum.OriginalDate,
        title:
          type === TimestampRuleTypeEnum.InBeforeStart ||
          type === TimestampRuleTypeEnum.InAfterStart
            ? t('EditRule.roundingToWorkdayStart')
            : t('EditRule.roundingToWorkdayEnd')
      },
      { id: TimestampRuleRoundingTypeEnum.ToMinutes, title: t('EditRule.roundingToWholeMinutes') }
    ],
    [t, type]
  )

  const [currentDuration, setCurrentDuration] = useDefault(duration, duration)
  const normalizedDuration = (currentDuration ?? 0) / 1000 / 60

  const [currentAttestType, setCurrentAttestType] = useDefault(
    attestTypes[0],
    attestTypes.find(a => a.id === attestType) ?? attestTypes[0]
  )
  const [currentRoundingType, setCurrentRoundingType] = useDefault(
    roundingTypes[0],
    roundingTypes.find(r => r.id === roundingType) ?? roundingTypes[0]
  )
  const [currentDurationType, setCurrentDurationType] = useDefault(
    durationTypes[0],
    durationTypes.find(d => d.id === durationType) ?? durationTypes[0]
  )

  const [currentRoundingTime, setCurrentRoundingTime] = useDefault(roundingTime, roundingTime)
  const normalizedRoundingTime = (currentRoundingTime ?? 0) / 1000 / 60

  const handleChangeDuration = e => setCurrentDuration(e.target.value * 1000 * 60)
  const handleChangeAttestType = item => setCurrentAttestType(item)
  const handleChangeRoundingType = item => setCurrentRoundingType(item)
  const handleChangeDurationType = item => setCurrentDurationType(item)
  const handleChangeRoundingTime = e => setCurrentRoundingTime(e.target.value * 1000 * 60)

  useEffect(() => {
    setCurrentDuration(duration)
    setCurrentAttestType(attestTypes.find(a => a.id === attestType) ?? attestTypes[0])
    setCurrentRoundingType(roundingTypes.find(r => r.id === roundingType) ?? roundingTypes[0])
    setCurrentDurationType(durationTypes.find(d => d.id === durationType) ?? durationTypes[0])
    setCurrentRoundingTime(roundingTime)
  }, [
    duration,
    type,
    attestType,
    attestTypes,
    durationTypes,
    roundingTypes,
    roundingType,
    roundingTime,
    durationType,
    id,
    setCurrentDuration,
    setCurrentAttestType,
    setCurrentRoundingType,
    setCurrentDurationType,
    setCurrentRoundingTime
  ])

  const [open, handleOpen, handleClose] = useOpenState()
  const handleDelete = () => {
    if (!timestampRule) {
      return
    }
    if (timestampRule.id) {
      onDelete?.(timestampRule.id)
    }
    handleClose()
    toast.success('Regel borttagen')
  }

  const handleUpdateTimestampRule = async () => {
    if (id) {
      await onUpdate?.([
        {
          id: id,
          duration: currentDuration ?? undefined,
          attestType: currentAttestType.id,
          roundingType: currentRoundingType.id,
          durationType: currentDurationType.id,
          roundingTime: currentRoundingTime ?? undefined
        }
      ])
    }
  }

  if (!timestampRule) {
    return (
      <div className="flex-1 w-full h-32 border-dashed border-2 pt-32 mb- 2 lg:m-2 rounded-lg border-gray-300" />
    )
  }
  return (
    <DashboardCard rootClass="overflow-visible" rootStyle={{ height: 'fit-content' }}>
      <Modal
        onCancel={handleClose}
        onApprove={handleDelete}
        approveButtonText="Ja"
        cancelButtonText="Nej"
        open={open}
        titleText={'Ta bort regel'}
      >
        Vill du verkligen ta bort den här regeln?
      </Modal>

      <div>
        <label className="block text-sm leading-5 text-gray-700 font-bold mb-2">
          {t('EditRule.ruleType')}
        </label>
        {(type === TimestampRuleTypeEnum.InAfterStart ||
          type === TimestampRuleTypeEnum.OutBeforeEnd) &&
          isLast && (
            <SelectMenu
              onChange={handleChangeDurationType}
              selectedItem={currentDurationType}
              items={durationTypes}
            />
          )}
        {(currentDurationType?.id === TimestampRuleDurationTypeEnum.Time ||
          type === TimestampRuleTypeEnum.InBeforeStart ||
          type === TimestampRuleTypeEnum.OutAfterEnd) && (
          <Input
            onChange={handleChangeDuration}
            value={normalizedDuration}
            addon={t('EditRule.minutes')}
          />
        )}
      </div>
      <div className="mt-6">
        <label className="block text-sm leading-5 text-gray-700 font-bold mb-2">Attestering</label>
        <SelectMenu
          onChange={handleChangeAttestType}
          selectedItem={currentAttestType}
          items={attestTypes}
        />
      </div>
      <div className="mt-6">
        <label className="block text-sm leading-5 text-gray-700 font-bold mb-2">Avrundning</label>
        <SelectMenu
          onChange={handleChangeRoundingType}
          selectedItem={currentRoundingType}
          items={roundingTypes}
        />
        {currentRoundingType?.id === TimestampRuleRoundingTypeEnum.ToMinutes && (
          <Input
            onChange={handleChangeRoundingTime}
            value={normalizedRoundingTime}
            addon={t('EditRule.wholeMinutes')}
          />
        )}
      </div>
      <div className="mt-6 flex">
        <Button size="xl" onClick={handleUpdateTimestampRule}>
          {t('general.save')}
        </Button>
        <Button variant="secondary" className="ml-4" size="xl" onClick={handleOpen}>
          {t('general.remove')}
        </Button>
      </div>
    </DashboardCard>
  )
}

export default EditRule
