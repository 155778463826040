import * as React from 'react'
import ActionsWarningIcon from '@material-ui/icons/Error'

export interface Props {
  text: string
  onClick: () => void
  isActive: boolean
}
export interface State {}

class ActionBase extends React.Component<Props, State> {
  render() {
    const { text, onClick, isActive } = this.props
    if (!isActive) {
      return null
    }
    return (
      <div
        className="text-white text-[12px] cursor-pointer m-[5px] flex-center hover:underline"
        onClick={onClick}
      >
        <div className="p-[3px]">
          <ActionsWarningIcon className="!w-[18px] !h-[18px] !text-white" />
        </div>
        <div className="flex-1 pl-[3px]">{text}</div>
      </div>
    )
  }
}

export default ActionBase
