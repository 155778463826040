import * as React from 'react'
import { IOrganisationsProps } from '../../containers/AdminOrganisations/OrganisationsContainer'

import DashboardCard from 'components/DashboardCard/DashboardCard'
import OrganisationsList from 'containers/OrganisationsList/OrganisationsList'
import AddOrganisation from 'containers/AddOrganisation/AddOrganisation'
import { useTranslation } from 'react-i18next'
import Input from 'base-components/Input'
import { ChangeEvent, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { useThrottle } from 'hooks/useThrottle'

const Organisations: React.FC<IOrganisationsProps> = ({
  error,
  createOrganisationStatus,
  removeOrganisation,
  updateOrganisationName
}) => {
  const [searchText, setSearchText] = useState<string>('')
  const throttledSearchText = useThrottle(searchText, 300)

  const { t } = useTranslation()
  return (
    <DashboardCard style={{ display: 'block' }}>
      <div className="flex flex-col mb-6">
        <span className="mb-4">{t('AddOrganisation.addOrganisation')}</span>
        <AddOrganisation error={error} createOrganisationStatus={createOrganisationStatus} />
      </div>
      <span>{t('Organisations.handleOrganisations')}</span>
      <div className="w-full flex-center flex-row space-x-2">
        <AiOutlineSearch className="text-gray-600 text-xl" />
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value)
          }}
        />
      </div>
      <OrganisationsList
        searchText={throttledSearchText}
        removeOrganisation={removeOrganisation}
        updateOrganisationName={updateOrganisationName}
      />
    </DashboardCard>
  )
}

export default Organisations
