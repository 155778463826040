import gql from 'graphql-tag'
export default gql`
  query ipOrdersList(
    $token: String!
    $ipOrderId: Int
    $terminalId: Int
    $sortOrder: SortOrder
    $orderBy: String
    $first: Int!
    $after: Int!
    $filterText: String
  ) {
    viewer(token: $token) {
      id
      ipOrdersPaginated(
        ipOrderId: $ipOrderId
        terminalId: $terminalId
        first: $first
        after: $after
        sortOrder: $sortOrder
        orderBy: $orderBy
        filterText: $filterText
      ) {
        id
        paginationInfo {
          id
          totalPages
          pageSize
          currentPage
        }
        posts {
          id
          description
          timestampRules {
            id
          }
          workplace {
            id
            workPlaceName
            timestampRules {
              id
            }
          }
          staffingService {
            name
          }
        }
      }
    }
  }
`
