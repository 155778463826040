import { DocumentNode, useQuery } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { getLocalStorageToken } from 'store/localStorage'
import paginate from 'utils/paginate'
export interface IColInfo {
  key: string
  titleTranslationKey?: string
  sortable: boolean
  hideOnMobile?: boolean
  maxWidth?: number
  minWidth?: number
  layoutWeight?: number
  sortOrder?: 'ASC' | 'DESC' | 'NONE'
  actionColumn?: boolean
}
export interface TableRowData {
  id: number | string
  onClick?: () => void
  colData: {
    [key: string]: string | JSX.Element | number | { title: string; action: () => void }[]
  }
  className?: string
}
interface DataExtractorReturnType {
  nrOfPages: number
  rows: TableRowData[]
}
export type DataExtractor<TQuery = any> = (data: TQuery | undefined) => DataExtractorReturnType

export interface Props<TQuery, TQueryVariables> {
  query: DocumentNode
  queryVariables: TQueryVariables
  colInfo: IColInfo[]
  extractData: DataExtractor<TQuery>
  pageSize: number
  skip?: boolean
  hideGoToLast?: boolean
  grow?: boolean
  tableHeaderRow?: TableRowData
  noLoading?: boolean
  pollInterval?: number
  fetchCacheAndNetwork?: boolean
}
function GraphQLTable<TQuery = any, TQueryVariables = any>({
  query,
  queryVariables,
  extractData,
  colInfo,
  pageSize,
  skip,
  pollInterval,
  fetchCacheAndNetwork
}: Props<TQuery, TQueryVariables>) {
  const [pagination, setPagination] = useState(<div />)
  const [paginationVariables, setPaginationVariables] = useState({ first: 0, after: pageSize })
  const { data, loading } = useQuery<TQuery, TQueryVariables>(query, {
    variables: { ...queryVariables, token: getLocalStorageToken(), ...paginationVariables },
    skip,
    pollInterval,
    fetchPolicy: fetchCacheAndNetwork ? 'cache-and-network' : 'cache-first'
  })

  const handlePageClick = useCallback(
    (pageNr: number) => {
      setPaginationVariables({ first: (pageNr - 1) * pageSize, after: paginationVariables.after })
    },
    [pageSize, paginationVariables.after]
  )
  const { rows, nrOfPages } = extractData(data)
  const { pages, currentPage } = useMemo(
    () =>
      paginate(
        pageSize * nrOfPages,
        Math.floor(paginationVariables.first / pageSize) + 1,
        pageSize,
        4
      ),
    [pageSize, nrOfPages, paginationVariables.first]
  )
  useEffect(() => {
    const currPageFromFirst = Math.ceil((paginationVariables.first + 1) / paginationVariables.after)
    if (!rows.length && currPageFromFirst > 1 && !loading && nrOfPages > 0) {
      handlePageClick(currPageFromFirst - 1)
    }
  }, [
    rows,
    currentPage,
    handlePageClick,
    loading,
    nrOfPages,
    paginationVariables.first,
    paginationVariables.after
  ])
  const { t } = useTranslation()
  const inactivePageClass =
    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer select-none'
  const activePageClass =
    'border-indigo-500 text-indigo-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium cursor-pointer select-none'
  useEffect(() => {
    if (!loading) {
      setPagination(
        <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
          <div className="-mt-px w-0 flex-1 flex">
            <button
              onClick={() => handlePageClick(currentPage - 1)}
              disabled={currentPage === 1 || nrOfPages === 0}
              className={`hover:text-gray-700 hover:border-gray-300 disabled:text-gray-300
       border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 `}
            >
              <svg
                className="mr-3 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              {t('general.previous')}
            </button>
          </div>
          <div className="hidden md:-mt-px md:flex">
            {!pages.includes(1) && pages.length !== 0 && (
              <>
                <span className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                  ...
                </span>
              </>
            )}
            {pages.map(page => (
              <span
                onClick={() => handlePageClick(page)}
                key={page}
                className={page === currentPage ? activePageClass : inactivePageClass}
              >
                {page}
              </span>
            ))}
            {!pages.includes(nrOfPages) && pages.length !== 0 && (
              <>
                <span className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                  ...
                </span>
              </>
            )}
          </div>
          <div className="-mt-px w-0 flex-1 flex justify-end">
            <button
              onClick={() => handlePageClick(currentPage + 1)}
              disabled={currentPage === nrOfPages || nrOfPages === 0}
              className={`hover:text-gray-700 hover:border-gray-300 disabled:text-gray-300
       border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 `}
            >
              {t('general.next')}
              <svg
                className="ml-3 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </nav>
      )
    }
  }, [currentPage, handlePageClick, nrOfPages, pages, loading, t])
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="overflow-hidden sm:rounded-lg">
            <table className="min-w-full ">
              <thead className="">
                <tr>
                  {colInfo.map(col => (
                    <th
                      key={col.key}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {col.titleTranslationKey && t(col.titleTranslationKey)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, i) => (
                  <tr
                    onClick={row.onClick}
                    key={row.id}
                    className={`hover:bg-gray-100 bg-white ${row.onClick ? 'cursor-pointer' : ''} ${
                      row.className ? row.className : ''
                    }`}
                  >
                    {colInfo.map(col => (
                      <td
                        key={col.key}
                        className={`px-6 py-4 whitespace-nowrap text-xs text-gray-500`}
                      >
                        {!col.actionColumn ? row.colData[col.key] : null}
                      </td>
                    ))}
                  </tr>
                ))}
                {typeof pageSize === 'number' && typeof rows.length === 'number'
                  ? Array(Math.max(pageSize - rows.length, 0))
                      .fill(4)
                      .map((_, index) => (
                        <tr key={index} className={'hover:bg-gray-100 bg-white'}>
                          {colInfo.map(col => (
                            <td
                              key={col.key}
                              className={`${
                                !loading ? 'text-transparent' : ''
                              } px-6 py-4 whitespace-nowrap text-xs`}
                            >
                              {loading ? <Skeleton /> : '.'}
                            </td>
                          ))}
                        </tr>
                      ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination}
    </div>
  )
}

export default GraphQLTable
