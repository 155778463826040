import { Popover } from '@material-ui/core'
import Input from 'base-components/Input'
import Spinner from 'base-components/Spinner'
import React, { useMemo } from 'react'
import { UseFormRegister, UseFormReset } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface GeneralSearchResult<T> {
  title: string
  subtitle?: string
  item: T
}

interface FormType {
  searchPhrase: string
}

interface Props<V extends { id: number }> {
  value: { id: number; title: string } | null | undefined
  onChange: (value: GeneralSearchResult<V> | null) => void
  register: UseFormRegister<FormType>
  reset: UseFormReset<FormType>
  results: GeneralSearchResult<V>[]
  size?: 'small' | 'large'
  label?: string
  loading?: boolean
}

function GeneralSearchBase<T extends { id: number }>({
  value,
  onChange,
  results,
  size,
  label,
  loading,
  register,
  reset
}: Props<T>) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    reset({ searchPhrase: '' })
  }

  const handleSelect = (item: GeneralSearchResult<T> | null) => () => {
    onChange(item)
    setAnchorEl(null)
  }
  const handleClear = () => {
    onChange(null)
    reset({ searchPhrase: '' })
  }
  const { t } = useTranslation()

  const textSize = useMemo(() => {
    switch (size) {
      case 'small':
        return 'text-sm'
      case 'large':
      default:
        return 'text-md'
    }
  }, [size])

  const buttonSize = useMemo(() => {
    switch (size) {
      case 'small':
        return 'w-36 h-10'
      case 'large':
      default:
        return 'w-48 h-14'
    }
  }, [size])

  const selectedItem = value

  return (
    <div>
      {Boolean(selectedItem) ? (
        <div
          className={`flex pl-2 flex-row ${textSize} border-2 border-gray-200 bg-white rounded-md ${buttonSize}`}
        >
          <div className="flex flex-col flex-1 h-full justify-center w-10/12">
            <span className="truncate font-semibold">{selectedItem?.title}</span>
          </div>
          <button
            onClick={handleClear}
            className="h-full w-2/12 flex-center leading-none text-gray-500 text-xl"
          >
            x
          </button>
        </div>
      ) : (
        <button
          onClick={handleClick}
          className={`border-dashed border-2 border-gray-400 rounded-md ${buttonSize} py-1 text-gray-400 font-semibold ${
            Boolean(label) ? 'text-sm' : 'text-xl'
          } leading-none`}
        >
          {label ?? '+'}
        </button>
      )}
      <Popover
        classes={{ paper: '!bg-white !p-y !rounded-xl !shadow !z-[1000] !divide-y' }}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Input
          placeholder={t('general.search')}
          useFormRegInfo={{ register, name: 'searchPhrase' }}
          classes={{
            body: 'px-2 !shadow-none',
            input:
              'outline-none focus:ring-1 w-full my-2 h-8 bg-gray-50 border-gray-200 border rounded-full placeholder-gray-600 px-2.5 text-sm'
          }}
          autoComplete="off"
          autoFocus={true}
        />
        {loading && (
          <div className="w-full flex-center">
            <Spinner />
          </div>
        )}
        {results.map(result => (
          <div
            key={result.item?.id ?? ''}
            className="py-2.5 bg-white w-56 flex justify-center px-2  cursor-pointer hover:bg-gray-100 flex-col text-md"
            onClick={handleSelect(result)}
          >
            <span className="truncate cursor-pointer hover:bg-gray-100 text-gray-700 text-sm">
              {result.title}
            </span>
            {result.subtitle && <span className="truncate">{result.subtitle}</span>}
          </div>
        ))}
      </Popover>
    </div>
  )
}
export default GeneralSearchBase
