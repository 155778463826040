import * as React from 'react'
import { Dialog } from '@material-ui/core'
import { PersonTimestamps } from 'components/PersonTimestamps/PersonTimestamps'
import { DialogBox } from 'components/DialogBox/DialogBox'
import { IIpPerson } from 'models/interfaces'
import { Translation } from 'react-i18next'

export interface Props {
  ipPerson: IIpPerson
  open: boolean
  onClose: () => void
}

export default class PersonTimestampsContainer extends React.Component<any, any> {
  render(): any {
    const { ipPerson, open, onClose } = this.props
    if (!ipPerson) {
      return null
    }
    return (
      <Translation>
        {t => (
          <Dialog open={open} onClose={onClose}>
            <DialogBox
              title={`${t('general.timestamps')} - ${ipPerson.firstName} ${ipPerson.surName}`}
              iconClass="zmdi zmdi-info"
              closeDialog={onClose}
            >
              <div className="mt-[50px] p-[10px] rounded-sm">
                <PersonTimestamps ipPerson={ipPerson} />
              </div>
            </DialogBox>
          </Dialog>
        )}
      </Translation>
    )
  }
}
