import * as React from 'react'
import ActionsWarningIcon from '@material-ui/icons/Error'
import { IIpWorkday, ITimestampRule, ITimestamp } from '../../../../models/interfaces'
import ActionNoRules from 'containers/WorkdayActions/ActionNoRules/ActionNoRules'
import ActionAddWorkdayToTerminal from 'containers/WorkdayActions/ActionAddWorkdayToTerminal/ActionAddWorkdayToTerminal'
import WorkdayActionHover from 'components/Timeline/WorkdayActions/WorkdayActionHover/WorkdayActionHover'
import TimelineHover from 'components/Timeline/TimelineHover/TimelineHover'

export interface Props {
  workday: IIpWorkday
  innerRules: ITimestampRule[]
  outerRules: ITimestampRule[]
  timestamp: ITimestamp
  existsOnTerminal: boolean
  workplaceName: string
  description: string
  parentHover?: boolean
  from: string
  to: string
  disableActionClick?: boolean
}
export interface State {
  hover: boolean
  hasActions: boolean
}

class WorkdayActions extends React.Component<Props, State> {
  state = {
    hover: false,
    hasActions: false
  }
  private setHasActions = (hasActions: boolean) => {
    if (hasActions !== this.state.hasActions) {
      this.setState({ hasActions })
    }
  }
  render() {
    const {
      timestamp,
      innerRules,
      outerRules,
      workday,
      existsOnTerminal,
      parentHover,
      workplaceName,
      from,
      to,
      disableActionClick,
      description
    } = this.props
    const { hover, hasActions } = this.state

    return (
      <React.Fragment>
        {hasActions ? (
          <div
            className="flex items-center h-full cursor-auto"
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            {!workday.isBossConfirmed && (
              <ActionsWarningIcon className="!text-white !w-[14px] !h-[14px] z-[904]" />
            )}
          </div>
        ) : null}
        <TimelineHover parentHover={parentHover || hover} width={240}>
          <WorkdayActionHover
            from={from}
            to={to}
            setHasActions={this.setHasActions}
            workplaceName={workplaceName}
            description={description}
            disableActionClick={disableActionClick}
            ipOrderId={workday?.ipOrder?.id ?? 0}
            isBossConfirmed={workday?.isBossConfirmed ?? false}
            punchInDateFrom={workday.punchInDateFrom}
            punchOutDateTo={workday.punchOutDateTo}
          >
            <ActionNoRules
              innerRules={innerRules}
              outerRules={outerRules}
              ipOrderId={workday?.ipOrder?.id ?? 0}
              workday={workday}
              timestamp={timestamp}
            />
            <ActionAddWorkdayToTerminal
              key={2}
              timestamp={timestamp}
              workday={workday}
              existsOnTerminal={existsOnTerminal}
              outerRules={outerRules}
              innerRules={innerRules}
            />
          </WorkdayActionHover>
        </TimelineHover>
      </React.Fragment>
    )
  }
}

export default WorkdayActions
