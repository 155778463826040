import React from 'react'

import { useLayer } from 'react-laag'
import { BiCheck } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { MdArrowDropDown } from 'react-icons/md'
import { useForm, useWatch } from 'react-hook-form'

interface Option {
  key: string | number
  value: string
}
interface OnChangeValue {
  option: Option | undefined
  checked: boolean
}
interface Props {
  options: Option[]
  selectedKeys: (string | number)[]
  includeSelectAll?: boolean
  includeFilter?: boolean
  onChange: (values: OnChangeValue[]) => void
}

const MultiSelect: React.FC<Props> = ({
  options,
  selectedKeys,
  onChange,
  includeFilter,
  includeSelectAll
}) => {
  const [isOpen, setOpen] = React.useState(false)
  const { t } = useTranslation()
  // helper function to close the menu
  function close() {
    setOpen(false)
  }
  const { register, control } = useForm()
  const form = useWatch({ control, defaultValue: { searchText: null } })

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    onDisappear: close, // close the menu when the menu gets scrolled out of sight
    auto: true, // automatically find the best placement
    placement: 'bottom-center' // we prefer to place the menu "top-center"
  })
  const searchFilter = option => {
    if (option.key === 'select all' && Boolean(form.search)) {
      return false
    }
    return form.search
      ? Boolean(option?.value?.toLowerCase().includes(form?.search?.toLowerCase()))
      : true
  }
  const handleSelectAll = () => {
    if (options?.length !== selectedKeys?.length) {
      onChange(options.map(option => ({ option, checked: true })))
    } else {
      onChange(options.map(option => ({ option, checked: false })))
    }
  }
  const handleClickOption = option => () => {
    if (option.key === 'select all') {
      handleSelectAll()
    } else {
      onChange([
        {
          option,
          checked: !Boolean(selectedKeys.find(key => key === option.key))
        }
      ])
    }
  }
  return (
    <>
      <button
        {...triggerProps}
        className="flex flex-row items-center border-b border-gray-400"
        onClick={() => setOpen(!isOpen)}
      >
        {selectedKeys?.length ?? 0} {t('general.selected')}{' '}
        <span className="text-xl">
          <MdArrowDropDown />
        </span>
      </button>
      {renderLayer(
        isOpen && (
          <div {...layerProps} className="bg-white p-y rounded-xl shadow z-[1000] divide-y">
            {includeFilter && (
              <div className="px-2">
                <input
                  {...register('search')}
                  className="outline-none focus:ring-1 w-full my-2 h-8 bg-gray-50 border-gray-200 border rounded-full placeholder-gray-600 px-2.5 text-sm"
                  placeholder={t('general.search')}
                />
              </div>
            )}
            <ul className="divide-y overflow-auto max-h-96">
              {[
                ...(includeSelectAll
                  ? [{ key: 'select all', value: t('MultiPick.selectAll') }]
                  : []),
                ...options
              ]
                .filter(searchFilter)
                .map((option, index) => (
                  <li key={option.key + '0' + index}>
                    <div
                      onClick={handleClickOption(option)}
                      className="flex px-1 py-2.5 flex-row items-center justify-start cursor-pointer hover:bg-gray-100 text-gray-700 text-sm"
                    >
                      <span className="text-lg">
                        <span
                          className={`${
                            selectedKeys.find(key => key === option.key) ||
                            (option.key === 'select all' &&
                              selectedKeys?.length === options?.length)
                              ? 'text-gray-700'
                              : 'text-transparent'
                          }`}
                        >
                          <BiCheck />
                        </span>
                      </span>
                      <span
                        className={`px-2 ${option.key === 'select all' ? 'font-semibold' : ''}`}
                      >
                        {option.value}
                      </span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )
      )}
    </>
  )
}

export default MultiSelect
