import * as React from 'react'
import { Select } from '@material-ui/core'

const TableSelectField: React.SFC<any> = props => {
  const { children, style, ...rest } = props

  const selectStyle: React.CSSProperties = {
    fontSize: '12px',
    ...style
  }

  return (
    <Select style={selectStyle} {...rest}>
      {children}
    </Select>
  )
}

export default TableSelectField
