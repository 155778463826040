import * as React from 'react'
import moment from 'moment'
import TimelineHover from 'components/Timeline/TimelineHover/TimelineHover'
import TimestampMarkerDirectionAction from 'containers/TimestampMarkerActions/TimestampMarkerDirectionAction'
import TimestampMarkerTerminalAction from 'containers/TimestampMarkerActions/TimestampMarkerTerminalAction'
import { Translation } from 'react-i18next'
import { TimestampDirectionEnum } from 'types/graphql-global-types'

export interface Props {
  offset: number
  direction: TimestampDirectionEnum
  timeStamped: moment.Moment
  timestampId: number
  workdayOrderIds: number[]
  comment?: string
}
export interface State {
  hover: boolean
}

class TimestampMarker extends React.PureComponent<Props, State> {
  state = { hover: false }
  private setHover = (hover: boolean) => {
    this.setState({ hover })
  }
  render() {
    const { offset, direction, timeStamped, timestampId, workdayOrderIds, comment } = this.props
    const { hover } = this.state
    const isInDirection =
      direction === TimestampDirectionEnum.In || direction === TimestampDirectionEnum.BreakEnd
    return (
      <Translation>
        {t => {
          const directionText = isInDirection ? t('general.in') : t('general.out')
          const invertedDirectionText = isInDirection ? t('general.out') : t('general.in')
          return (
            <React.Fragment>
              <div
                className="absolute w-[2px] bg-[#f1bfdd]"
                style={{ left: `${offset}%`, height: 'calc(100% + 16px)' }}
              />
              <div
                className="absolute bottom-[-28px] text-[10px] text-purple-700 select-none"
                style={{ left: `calc(${offset}% - 11px)` }}
              >
                {timeStamped.format('HH:mm')}
              </div>
              <div
                className="timestampMarker w-[30px] h-[30px]  rounded-tl-full rounded-tr-full rounded-br-full rounded-bl-none  absolute transform -rotate-45 flex-center bg-purple-400 text-white"
                style={{ left: `calc(${offset}% - 14px)` }}
                onMouseEnter={() => this.setHover(true)}
                onMouseLeave={() => this.setHover(false)}
              >
                <div className="flex-center transform rotate-45 text-sm select-none">
                  {directionText}
                  <TimelineHover parentHover={hover} width={260} maxHeight={120}>
                    {comment ? (
                      <div className="p-[5px] text-xs select-none">"{comment}"</div>
                    ) : null}
                    <TimestampMarkerDirectionAction
                      direction={direction}
                      timestampId={timestampId}
                      text={`${t('general.change')} ${directionText}-${t(
                        'general.timestamp'
                      ).toLocaleLowerCase()} ${t(
                        'general.to'
                      ).toLocaleLowerCase()} ${invertedDirectionText}-${t(
                        'general.timestamp'
                      ).toLocaleLowerCase()}`}
                    />
                    <TimestampMarkerTerminalAction
                      timestampId={timestampId}
                      workdayOrderIds={workdayOrderIds}
                    />
                  </TimelineHover>
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </Translation>
    )
  }
}
export default TimestampMarker
