import { createImmutableMap, ImmutableMap } from 'models/AppState'
import { ISortInfo } from 'components/DataTable/models/SortInfo'
import { IPageInfo } from 'components/DataTable/models/PaginationInfo'

export const DATA_TABLE_ADD_NEW_TEXT_FIELD = 'DATA_TABLE_ADD_NEW_TEXT_FIELD'
export const ADD_NEW_DATA_TABLE = 'ADD_NEW_DATA_TABLE'
export const DATA_TABLE_CHANGE_PAGE = 'DATA_TABLE_CHANGE_PAGE'
export const DATA_TABLE_SORT = 'DATA_TABLE_SORT'
export const DATA_TABLE_TEXT_FIELD_EDIT = 'DATATABLE_TEXTFIELD_EDIT'
export const DATA_TABLE_TEXT_FIELD_SAVE = 'DATATABLE_TEXTFIELD_SAVE'
export const DATA_TABLE_TEXT_FIELD_START_EDIT = 'DATATABLE_TEXTFIELD_START_EDIT'
export const DATA_TABLE_TEXT_FIELD_CANCEL_EDIT = 'DATATABLE_TEXTFIELD_CANCEL_EDIT'

interface IDataTable {
  sortInfo: ISortInfo
  pageInfo: IPageInfo
  pageSize: number
  textFieldEditId: string | null
  textFieldEditMode: boolean
  textFieldEditValue: any
}

export interface IDataTableState {
  [id: string]: ImmutableMap<IDataTable>
}

export const LOCATION_CHANGE = 'LOCATION_CHANGE'
const initialState = createImmutableMap<IDataTableState>({})
export const dataTableReducer = (_state: any = initialState, action: any) => {
  let state: ImmutableMap<IDataTableState> = _state
  switch (action.type) {
    case ADD_NEW_DATA_TABLE:
      const { preSortBy, preSortOrder, pageSize } = action.payload
      return state.set(
        action.payload.id,
        createImmutableMap<IDataTable>({
          sortInfo: {
            sortOrder: preSortOrder ? preSortOrder : null,
            sortBy: preSortBy ? preSortBy : null
          },
          pageInfo: { first: 0, after: pageSize },
          pageSize: action.payload.pageSize,
          textFieldEditId: '',
          textFieldEditMode: false,
          textFieldEditValue: ''
        })
      )
    case DATA_TABLE_SORT:
      return state.set(
        action.payload.id,
        state.get(action.payload.id).set('sortInfo', action.payload.sortInfo)
      )
    case DATA_TABLE_CHANGE_PAGE:
      return state.set(
        action.payload.id,
        state.get(action.payload.id).set('pageInfo', action.payload.pageInfo)
      )
    case DATA_TABLE_TEXT_FIELD_EDIT:
      return state.set(
        action.payload.id,
        state.get(action.payload.id).set('textFieldEditValue', action.payload.value)
      )
    case DATA_TABLE_TEXT_FIELD_SAVE:
      return state.set(
        action.payload.id,
        state
          .get(action.payload.id)
          .set('textFieldEditMode', false)
          .set('textFieldEditId', null)
      )
    case DATA_TABLE_TEXT_FIELD_START_EDIT:
      return state.set(
        action.payload.id,
        state
          .get(action.payload.id)
          .set('textFieldEditMode', true)
          .set('textFieldEditId', action.payload.value)
      )
    case DATA_TABLE_TEXT_FIELD_CANCEL_EDIT:
      return state.set(
        action.payload.id,
        state
          .get(action.payload.id)
          .set('textFieldEditMode', false)
          .set('textFieldEditId', null)
      )
    case LOCATION_CHANGE:
      const loopState: any = state
      loopState.entrySeq().forEach(
        e =>
          (state = state.set(
            e[0],
            state
              .get(e[0])
              .set('textFieldEditMode', false)
              .set('textFieldEditId', null)
          ))
      )
      return state
    default:
      return state
  }
}
