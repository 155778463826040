import gql from 'graphql-tag'
export default gql`
  query personList(
    $token: String!
    $searchText: String
    $sortOrder: SortOrder
    $orderBy: String
    $first: Int
    $after: Int
    $onlyUsersWithMobilecodes: Boolean
  ) {
    viewer(token: $token) {
      id
      ipPersonsPaginated(
        searchText: $searchText
        sortOrder: $sortOrder
        orderBy: $orderBy
        first: $first
        after: $after
        onlyUsersWithMobilecodes: $onlyUsersWithMobilecodes
      ) {
        paginationInfo {
          id
          totalPages
          pageSize
          currentPage
        }
        posts {
          id
          firstName
          surName
          rfid
        }
      }
    }
  }
`
