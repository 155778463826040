import { AppState } from 'models/AppState'
import { DATA_TABLE_CHANGE_PAGE } from 'components/DataTable/containers/dataTableReducer'
import { IAdminUsersConnectedDispatch, IAdminUsersConnectedState } from './adminUsersMapProps'
import * as React from 'react'
import { connect } from 'react-redux'
import removeUserMutation from 'graphql/mutations/RemoveUser'
import createUserMutation from 'graphql/mutations/CreateUser'
import { IReduxFormProps } from 'models/interfaces'
import adminUpdateUserMutation from 'graphql/mutations/AdminUpdateUser'
import AdminUsers from 'components/AdminUsers/AdminUsers'
import {
  ISearchBarMappedProps,
  searchBar
} from '../../components/SearchBar/containers/searchBarDecorator'
import { getLocalStorageToken } from 'store/localStorage'

import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { removeUser, removeUserVariables } from 'graphql/mutations/types/removeUser'
import { createUser, createUserVariables } from 'graphql/mutations/types/createUser'
import { adminUpdateUser, adminUpdateUserVariables } from 'graphql/mutations/types/adminUpdateUser'

export interface IAdminUsersProps
  extends IAdminUsersConnectedState,
    IAdminUsersConnectedDispatch,
    ISearchBarMappedProps,
    IReduxFormProps<ICreateUserForm> {
  paginatedUsers: any
  removeUser: (userId: number) => void
  createUser: (
    values: { username: string; password: string; privilegeLevel: number },
    resetValue: () => void
  ) => void
  updatePrivilegeLevel: (userId: number, privilegeLevel: number) => void
  updateOrganisationId: (userId: number, OrganisationId: number[]) => void
  organisations: any
  refetch: any
  loading: boolean
}

export interface ICreateUserForm {
  username: string
  password: string
  privilegeLevel: number
}

export const ADMIN_USERS_ID = 'adminTable'
export const ADMIN_USERS_PAGE_SIZE = 5

const adminUsersMapStateToProps = (state: AppState): IAdminUsersConnectedState => ({
  createUserStatus: state.admin.get('createUserStatus'),
  createUserForm: state.form.createUser
})

const adminUsersMapDispatchToProps: IAdminUsersConnectedDispatch = {
  resetPagination: () => ({
    type: DATA_TABLE_CHANGE_PAGE,
    payload: { id: ADMIN_USERS_ID, pageInfo: { first: 0, after: ADMIN_USERS_PAGE_SIZE } }
  })
}

export const AdminUsersContainerClass: React.FC<any> = props => {
  const { t } = useTranslation()
  const [remove] = useMutation<removeUser, removeUserVariables>(removeUserMutation, {
    refetchQueries: ['usersList']
  })
  const [create] = useMutation<createUser, createUserVariables>(createUserMutation, {
    refetchQueries: ['usersList']
  })
  const [update] = useMutation<adminUpdateUser, adminUpdateUserVariables>(adminUpdateUserMutation, {
    refetchQueries: ['usersList', 'organisations', 'terminalsList'],
    update: cache => {
      cache.modify({
        fields: {
          terminalsPaginated(_, { DELETE }) {
            return DELETE
          }
        }
      })
    }
  })

  const removeUser = async (userId: number) => {
    await remove({ variables: { token: getLocalStorageToken(), userId } })
    toast.success((t('AdminUsersContainer.userRemoved') as string) ?? '')
  }
  const createUser = async (
    values: {
      username: string
      password: string
      privilegeLevel: number
    },
    resetValues: () => void
  ) => {
    let errorMessage = null
    const { username, password, privilegeLevel } = values
    !username && (errorMessage = t('AdminUsersContainer.emailMissing'))
    !password && (errorMessage = t('AdminUsersContainer.passwordMissing'))
    password.length < 5 && (errorMessage = t('Password.incorrectLength'))
    privilegeLevel === null && (errorMessage = t('AdminUsersContainer.missingPrivilegeLevel'))
    if (errorMessage) {
      toast.error(errorMessage)
      return
    }
    const res = await create({
      variables: {
        token: getLocalStorageToken(),
        username,
        password,
        privilegeLevel
      }
    })
    resetValues()
    res?.data?.createUser
      ? toast.success((t('AdminUsersContainer.userCreated') as string) ?? '')
      : toast.error((t('AdminUsersContainer.userCreatedFailed') as string) ?? '')
  }
  const updatePrivilegeLevel = async (userId: number, privilegeLevel: number) => {
    await update({
      variables: { token: getLocalStorageToken(), userId, privilegeLevel }
    })
    toast.success((t('AdminUsersContainer.userUpdated') as string) ?? '')
  }
  const updateOrganisationId = async (userId: number, OrganisationId: number[]) => {
    await update({
      variables: {
        token: getLocalStorageToken(),
        userId,
        OrganisationIds: OrganisationId
      }
    })
    toast.success((t('AdminUsersContainer.userUpdated') as string) ?? '')
  }
  return (
    <AdminUsers
      {...props}
      {...{
        updatePrivilegeLevel,
        updateOrganisationId,
        createUser,
        removeUser
      }}
    />
  )
}

export const AdminUsersContainer: any = connect<any, any, any, any>(
  adminUsersMapStateToProps,
  adminUsersMapDispatchToProps
)(searchBar({ id: ADMIN_USERS_ID })(AdminUsersContainerClass))
