import gql from 'graphql-tag'
export default gql`
  query terminals(
    $token: String!
    $excludeOrganisationIds: [Int]
    $ids: [Int]
    $limit: Int
    $filterText: String
  ) {
    viewer(token: $token) {
      id
      terminals(
        ids: $ids
        excludeOrganisationIds: $excludeOrganisationIds
        limit: $limit
        filterText: $filterText
      ) {
        id
        name
        description
      }
    }
  }
`
