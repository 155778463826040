import {
  IUserSettings,
  UserSettings
} from 'components/UserSettings/components/UserSettings/UserSettings'
import { useCustomerSettings } from 'hooks/useCustomerSettings'
import * as Immutable from 'immutable'
import { TOGGLE_USER_SETTINGS } from 'layouts/AppLayout/containers/AppLayoutContainer'
import * as React from 'react'
import { Translation } from 'react-i18next'
import { connect } from 'react-redux'
import isSSOLoggedIn from 'utils/isSSOLoggedIn'
import { useHistory } from 'react-router-dom'

const initialState = Immutable.Map({})
export function userSettingsReducer(state: any = initialState) {
  return state
}

const mapStateToProps = state => ({
  userSettingsOpen: state.appLayout.get('userSettingsOpen')
})

const mapDispatchToProps = {
  closeUserSettings: () => ({
    type: TOGGLE_USER_SETTINGS,
    payload: false
  })
}
const UserSettingsContainerClass: React.FC<IUserSettings> = props => {
  const customerSettings = useCustomerSettings()
  const history = useHistory()
  React.useEffect(() => {
    if (isSSOLoggedIn()) {
      history.push('/')
    }
  }, [history])
  return (
    <Translation>
      {t => (
        <div className="w-full flex-center p-2 sm:p-4">
          <div className="flex flex-wrap flex-col max-w-xl bg-white rounded-lg shadow-sm p-6">
            {customerSettings.emailLogin && <UserSettings {...props} />}
          </div>
        </div>
      )}
    </Translation>
  )
}

const UserSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettingsContainerClass)
export default UserSettingsContainer
