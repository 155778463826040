import gql from 'graphql-tag'
export default gql`
  query notificationsTree(
    $token: String!
    $type: NotificationTypeEnum!
    $after: Date!
    $before: Date!
    $excludeOrganisationIds: [Int]
  ) {
    viewer(token: $token) {
      id
      notificationsTree(
        after: $after
        before: $before
        type: $type
        excludeOrganisationIds: $excludeOrganisationIds
      ) {
        terminals {
          id
          name
          description
          ipWorkplaces {
            id
            workplaceName
            workplaceNo
            ipOrders {
              id
              ipOrderId
              name
              ipPersons {
                id
                firstName
                surName
                notifications {
                  id
                  timestamp {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
