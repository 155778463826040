import * as jwtDecode from 'jwt-decode'
import { getLocalStorageToken } from 'store/localStorage'

export interface IUserObject {
  id: number
  username: string
  privilegeLevel: number
  Organisations: {
    name: string
    id: number
  }[]
}

export const getUser = (): IUserObject | null => {
  const userToken = getLocalStorageToken()
  if (userToken) {
    try {
      return jwtDecode<any>(userToken).user
    } catch (err) {
      return null
    }
  }

  return null
}
