import * as React from 'react'
import TimelineHover from 'components/Timeline/TimelineHover/TimelineHover'
import { ITimestampRule } from 'models/interfaces'
import WorkdayRuleHover from 'components/Timeline/WorkdayRuleHover/WorkdayRuleHover'
import { TimestampRuleDurationTypeEnum, TimestampRuleTypeEnum } from 'types/graphql-global-types'

export interface Props {
  style?: any
  timestampRule: ITimestampRule
  index: number
  parentWidth: number
  parentHover: boolean
  setParentHover: (hover: boolean, innerRule: boolean) => void
  overflowTime: number
  underflowTime: number
  parentOffset: number
}
export interface State {
  hover: boolean
}

class WorkdayInnerRule extends React.Component<Props, State> {
  state = {
    hover: false
  }

  private setHover = (hover: boolean) => {
    this.props.setParentHover(hover, true)
    if (this.state.hover !== hover) {
      this.setState({ hover })
    }
  }

  render() {
    const {
      timestampRule,
      parentWidth,
      parentHover,
      overflowTime,
      underflowTime,
      parentOffset
    } = this.props
    const { hover } = this.state
    const duration = timestampRule?.durationWithOffset
      ? timestampRule.durationWithOffset - overflowTime - underflowTime
      : 0
    // The duration of this rule is not visible due to the workday overflowing outside the graph
    if (timestampRule.durationType === TimestampRuleDurationTypeEnum.Time && duration <= 0) {
      return null
    }
    let width =
      timestampRule.durationType === TimestampRuleDurationTypeEnum.Time
        ? (duration / (1000 * 60 * 60)) * 100
        : parentWidth
    const onLeft = timestampRule.type === TimestampRuleTypeEnum.InAfterStart
    const hasHover = hover || parentHover
    const widthStyle = hasHover ? `${width}%` : '0px'
    const containerStyle: React.CSSProperties = onLeft
      ? { left: `${parentOffset}%`, width: widthStyle }
      : { left: `${parentWidth + parentOffset - width}%`, width: widthStyle }
    const ruleStyle: React.CSSProperties = {
      width: hasHover ? '100%' : 0
    }

    const asLongAsParent = width >= parentWidth

    const maskClasses = [
      'overflow-hidden w-full h-full left-0 top-0 absolute rounded-[15px] border box-border'
    ]
    if (!asLongAsParent) {
      if (onLeft) {
        maskClasses.push('rounded-tr-none rounded-br-none')
      } else {
        maskClasses.push('rounded-tl-none rounded-bl-none')
      }
    }
    if (onLeft && underflowTime > 0) {
      maskClasses.push('rounded-tl-none rounded-bl-none')
    } else if (!onLeft && overflowTime > 0) {
      maskClasses.push('rounded-tr-none rounded-br-none')
    }
    return (
      <div
        className={`h-[26px] bottom-[17px] absolute ${hasHover ? 'block' : 'hidden'}`}
        onMouseEnter={() => this.setHover(true)}
        onMouseLeave={() => this.setHover(false)}
        style={{ ...containerStyle, zIndex: 902 }}
      >
        <div className={maskClasses.join(' ')}>
          <div
            className={[
              'left-0 top-0 absolute h-full bg-blue-500 opacity-50',
              hover ? 'bg-blue-500 opacity-50' : null,
              hasHover ? 'border-2' : null,
              onLeft ? 'border-l-0' : 'border-l-0',
              asLongAsParent &&
                (onLeft
                  ? 'rounded-tr-[15px] rounded-br-[15px]'
                  : 'rounded-tl-[15px] rounded-bl-[15px]')
            ].join(' ')}
            style={{
              ...ruleStyle,
              zIndex: 902
            }}
          />
        </div>
        <TimelineHover
          parentHover={hover}
          color={'blue'}
          width={120}
          disableHover={true}
          elevated={true}
        >
          <WorkdayRuleHover onLeft={onLeft} timestampRule={timestampRule} offset={width / 2} />
        </TimelineHover>
      </div>
    )
  }
}

export default WorkdayInnerRule
