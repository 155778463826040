import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { getLocalStorageToken } from 'store/localStorage'
import { FleximeLoader } from 'components/FleximeLoader/FleximeLoader'
import {
  notificationsTree,
  notificationsTreeVariables
} from '../graphql/queries/types/notificationsTree'
import queryNotificationsTree from '../graphql/queries/queryNotificationsTree'
import Schedule from 'components/NewRulesView/Schedule'
import EditRule from 'components/NewRulesView/EditRule'
import { useList } from 'react-use'
import Checkbox from 'base-components/Checkbox'
import { timestampRuleTemplate_viewer_timestampRulesTemplates_TimestampRules } from 'graphql/queries/types/timestampRuleTemplate'
import {
  OnCreateTimestampRule,
  OnUpdateTimestampRule
} from 'components/NewRulesView/ScheduleSection'
import { useHistory, useLocation } from 'react-router'
import mutationCreateTimestampRules from 'graphql/mutations/mutationCreateTimestampRules'
import {
  createTimestampRules as createTimestampRulesType,
  createTimestampRulesVariables
} from 'graphql/mutations/types/createTimestampRules'
import Button from 'base-components/Button'

import DashboardCard from 'components/DashboardCard/DashboardCard'
import { groupArrayBy } from 'utils/groupArrayBy'
import { useOrganisations } from 'hooks/useOrganisations'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import {
  NotificationTypeEnum,
  TimestampRuleAttestTypeEnum,
  TimestampRuleDurationTypeEnum,
  TimestampRuleRoundingTypeEnum,
  TimestampRuleTypeEnum
} from 'types/graphql-global-types'
interface Props {
  dateFrom: Date
  dateTo: Date
}
const standardRules: timestampRuleTemplate_viewer_timestampRulesTemplates_TimestampRules[] = [
  {
    __typename: 'timestampRule',
    attestType: TimestampRuleAttestTypeEnum.Attest,
    duration: 15 * 60 * 1000,
    durationType: TimestampRuleDurationTypeEnum.Time,
    roundingTime: 0,
    roundingType: TimestampRuleRoundingTypeEnum.OriginalDate,
    type: TimestampRuleTypeEnum.InBeforeStart,
    id: 0,
    sortOrder: 0
  },
  {
    __typename: 'timestampRule',
    attestType: TimestampRuleAttestTypeEnum.None,
    duration: 60 * 60 * 1000,
    durationType: TimestampRuleDurationTypeEnum.Time,
    roundingTime: 0,
    roundingType: TimestampRuleRoundingTypeEnum.None,
    type: TimestampRuleTypeEnum.InBeforeStart,
    id: 1,
    sortOrder: 1
  },
  {
    __typename: 'timestampRule',
    attestType: TimestampRuleAttestTypeEnum.Attest,
    duration: 5 * 60 * 1000,
    durationType: TimestampRuleDurationTypeEnum.Time,
    roundingTime: 0,
    roundingType: TimestampRuleRoundingTypeEnum.OriginalDate,
    type: TimestampRuleTypeEnum.InAfterStart,
    id: 2,
    sortOrder: 0
  },
  {
    __typename: 'timestampRule',
    attestType: TimestampRuleAttestTypeEnum.None,
    duration: 0,
    durationType: TimestampRuleDurationTypeEnum.Rest,
    roundingTime: 0,
    roundingType: TimestampRuleRoundingTypeEnum.None,
    type: TimestampRuleTypeEnum.InAfterStart,
    id: 3,
    sortOrder: 1
  },
  {
    __typename: 'timestampRule',
    attestType: TimestampRuleAttestTypeEnum.Attest,
    duration: 5 * 60 * 1000,
    durationType: TimestampRuleDurationTypeEnum.Time,
    roundingTime: 0,
    roundingType: TimestampRuleRoundingTypeEnum.OriginalDate,
    type: TimestampRuleTypeEnum.OutBeforeEnd,
    id: 4,
    sortOrder: 0
  },
  {
    __typename: 'timestampRule',
    attestType: TimestampRuleAttestTypeEnum.None,
    duration: 0,
    durationType: TimestampRuleDurationTypeEnum.Rest,
    roundingTime: 0,
    roundingType: TimestampRuleRoundingTypeEnum.None,
    type: TimestampRuleTypeEnum.OutBeforeEnd,
    id: 5,
    sortOrder: 1
  },
  {
    __typename: 'timestampRule',
    attestType: TimestampRuleAttestTypeEnum.Attest,
    duration: 15 * 60 * 1000,
    durationType: TimestampRuleDurationTypeEnum.Time,
    roundingTime: 0,
    roundingType: TimestampRuleRoundingTypeEnum.OriginalDate,
    type: TimestampRuleTypeEnum.OutAfterEnd,
    id: 6,
    sortOrder: 0
  },
  {
    __typename: 'timestampRule',
    attestType: TimestampRuleAttestTypeEnum.None,
    duration: 60 * 60 * 1000,
    durationType: TimestampRuleDurationTypeEnum.Time,
    roundingTime: 0,
    roundingType: TimestampRuleRoundingTypeEnum.None,
    type: TimestampRuleTypeEnum.OutAfterEnd,
    id: 7,
    sortOrder: 1
  }
]
const MissingRules: React.FC<Props> = ({ dateFrom, dateTo }) => {
  const [
    selectedWorkplaces,
    { push: pushWorkplace, filter: filterWorkplace, clear: clearSelectedWorkplaces }
  ] = useList<string>([])
  const [
    selectedOrders,
    { push: pushOrder, filter: filterOrders, clear: clearSelectedOrders }
  ] = useList<string>([])
  const [
    timestampRules,
    {
      push: pushTimestampRule,
      filter: filterTimestampRules,
      updateAt: updateTimestampRuleAt,
      set: setTimestampRules
    }
  ] = useList<timestampRuleTemplate_viewer_timestampRulesTemplates_TimestampRules>([])
  const generateId = timestampRule => Number(`1${timestampRule.type}${timestampRule.sortOrder}`)
  const location = useLocation()
  const history = useHistory()
  const searchString = new URLSearchParams(location.search)
  const { t } = useTranslation()

  const selectedTimestampruleQueryString =
    Number(searchString.get('selectedTimestampRule')) ?? undefined
  const selectedTimestampRule = selectedTimestampruleQueryString
    ? timestampRules.find(
        timestampRule => generateId(timestampRule) === selectedTimestampruleQueryString
      )
    : undefined
  const [createTimestampRules] = useMutation<
    createTimestampRulesType,
    createTimestampRulesVariables
  >(mutationCreateTimestampRules, { refetchQueries: ['notificationsTree'] })
  const { excludeOrganisationIds } = useOrganisations()
  const { data, loading } = useQuery<notificationsTree, notificationsTreeVariables>(
    queryNotificationsTree,
    {
      skip: excludeOrganisationIds === null,
      variables: {
        token: getLocalStorageToken(),
        type: NotificationTypeEnum.TerminalMissingRules,
        after: dateFrom.valueOf(),
        before: dateTo.valueOf(),
        excludeOrganisationIds
      }
    }
  )

  const handleCreateTimestampRule: OnCreateTimestampRule = async newTimestampRules => {
    for (const timestampRule of newTimestampRules) {
      pushTimestampRule({
        ...timestampRule,
        __typename: 'timestampRule',
        id: generateId(timestampRule)
      })
    }
    searchString.set(
      'selectedTimestampRule',
      String(generateId(newTimestampRules[newTimestampRules.length - 1]))
    )
    history.replace({
      search: searchString?.toString()
    })
    return []
  }
  const handleDeleteTimestamprule = (id: number) => {
    filterTimestampRules(rule => generateId(rule) !== id)
  }
  const handleUpdateTimestamprule: OnUpdateTimestampRule = async updatedTimestampRules => {
    for (const updatedTimestampRule of updatedTimestampRules) {
      timestampRules.forEach((timestampRule, index) => {
        if (generateId(timestampRule) === updatedTimestampRule.id) {
          if (timestampRule) {
            updateTimestampRuleAt(index, { ...timestampRule, ...updatedTimestampRule })
          }
        }
      })
    }
    return []
  }
  const handleLoadStandardRules = () => {
    setTimestampRules(
      standardRules.map(standardRule => ({ ...standardRule, id: generateId(standardRule) }))
    )
  }
  const handleSave = async () => {
    let timestampRulesToSave: any[] = []
    for (const order of selectedOrders) {
      timestampRulesToSave = [
        ...timestampRulesToSave,
        ...timestampRules.map(timestampRule => {
          const { id, __typename, ...rest } = timestampRule ?? {}
          return { ...rest, ipOrderId: Number(order) }
        })
      ]
    }
    for (const workplace of selectedWorkplaces) {
      timestampRulesToSave = [
        ...timestampRulesToSave,
        ...timestampRules.map(timestampRule => {
          const { id, __typename, ...rest } = timestampRule ?? {}
          return { ...rest, ipWorkplaceId: Number(workplace) }
        })
      ]
    }
    try {
      await createTimestampRules({
        variables: {
          token: getLocalStorageToken(),
          timestampRules: timestampRulesToSave
        }
      })
      toast.success(t('NewNotifications.MissingRules.rulesAdded').toString())
    } catch {
      toast.error(t('NewNotifications.MissingRules.rulesAddedFailed').toString())
    }
    clearSelectedWorkplaces()
    clearSelectedOrders()
  }
  if (loading) {
    return <FleximeLoader size={40} />
  }
  const wp: any =
    groupArrayBy(
      data?.viewer?.notificationsTree?.terminals
        ?.map(terminal => {
          return terminal?.ipWorkplaces
        })
        .flat() ?? [],
      'id'
    ) ?? []

  const empty = !Boolean(Object.values<any>(wp)?.length)
  if (empty) {
    return <div className="py-8 text-lg">{t('NewNotifications.noNotifications')} 🎉</div>
  }
  return (
    <div className="w-full flex xl:flex-row flex-wrap flex-col">
      <DashboardCard rootClass="mb-auto">
        <h3>{t('NewNotifications.MissingRules.title')}</h3>
        <h4 className="text-sm mb-4">{t('NewNotifications.MissingRules.subTitle')}</h4>
        {Object.values<any>(wp).map(workplaceArray => {
          const workplace = workplaceArray[0]
          const ipOrders: any = groupArrayBy(
            Object.values<any>(workplaceArray)
              ?.map(w => w.ipOrders)
              .flat(),
            'id'
          )
          const isWorkplaceChecked = Boolean(
            selectedWorkplaces.find(selected => selected === workplace?.id)
          )
          const handleClickWorkplace = () => {
            if (!isWorkplaceChecked) {
              workplace?.id && pushWorkplace(workplace?.id)
              filterOrders(
                order => !Boolean(workplace?.ipOrders?.find(ipOrder => ipOrder?.id === order))
              )
            } else {
              filterWorkplace(selected => selected !== workplace?.id)
            }
          }
          return (
            <div key={workplace?.id ?? ''}>
              <div className="flex items-center">
                <Checkbox checked={isWorkplaceChecked} onChange={handleClickWorkplace} />
                {workplace?.workplaceName} ({workplace?.workplaceNo})
              </div>
              {Object.values<any>(ipOrders).map(ipOrderArray => {
                const ipOrder = ipOrderArray[0]
                const isOrderChecked = Boolean(
                  selectedOrders.find(selected => selected === ipOrder?.id)
                )
                const handleClickOrder = () => {
                  if (!isOrderChecked) {
                    ipOrder?.id && pushOrder(ipOrder?.id)
                  } else {
                    filterOrders(selected => selected !== ipOrder?.id)
                  }
                }
                return (
                  <div
                    key={ipOrder?.id ?? ''}
                    className={`pl-4 flex items-center ${
                      isWorkplaceChecked ? 'text-gray-600' : ''
                    }`}
                  >
                    <Checkbox
                      disabled={isWorkplaceChecked}
                      checked={isOrderChecked}
                      onChange={handleClickOrder}
                    />
                    {ipOrder?.name} ({ipOrder?.id})
                  </div>
                )
              })}
            </div>
          )
        })}
        <div className="mt-8">
          <Button
            onClick={handleSave}
            disabled={
              (!Boolean(selectedWorkplaces.length) && !Boolean(selectedOrders.length)) ||
              !Boolean(timestampRules.length)
            }
          >
            {t('NewNotifications.MissingRules.saveRules')}
          </Button>
        </div>
      </DashboardCard>
      <Schedule timestampRules={timestampRules} onCreate={handleCreateTimestampRule}>
        <Button onClick={handleLoadStandardRules} variant={'secondary'}>
          {t('NewNotifications.MissingRules.loadStandardRules')}
        </Button>
      </Schedule>
      <EditRule
        onDelete={handleDeleteTimestamprule}
        onUpdate={handleUpdateTimestamprule}
        timestampRule={selectedTimestampRule}
      />
    </div>
  )
}

export default MissingRules
