import { ImmutableMap, createImmutableMap } from 'models/AppState'
import { LOCATION_CHANGE } from 'store/location'

export const ADD_NEW_SEARCH_BAR = 'ADD_NEW_SEARCH_BAR'
export const SEARCH_BAR_CHANGED = 'SEARCH_BAR_CHANGED'
export const SEARCH_BAR_SEARCH = 'SEARCH_BAR_SEARCH'

export interface ISearchBarAction {
  type: string
  payload: {
    id: string
    value: any
  }
}

interface ISearchBar {
  searchBarCurrentText: string
  searchBarSearchText: string
}

export interface ISearchBarState {
  [id: string]: ImmutableMap<ISearchBar>
}

const initialState = createImmutableMap<ISearchBarState>({})
export const searchBarReducer = (_state: any = initialState, action: ISearchBarAction) => {
  const state: ImmutableMap<ISearchBarState> = _state
  switch (action.type) {
    case ADD_NEW_SEARCH_BAR:
      return state.set(
        action.payload.id,
        createImmutableMap<ISearchBar>({ searchBarCurrentText: '', searchBarSearchText: '' })
      )
    case SEARCH_BAR_CHANGED:
      if (action.payload.value === '') {
        return state.set(
          action.payload.id,
          state
            .get(action.payload.id)
            .set('searchBarSearchText', '')
            .set('searchBarCurrentText', '')
        )
      } else {
        return state.set(
          action.payload.id,
          state.get(action.payload.id).set('searchBarCurrentText', action.payload.value)
        )
      }
    case SEARCH_BAR_SEARCH:
      return state.set(
        action.payload.id,
        state.get(action.payload.id).set('searchBarSearchText', action.payload.value)
      )
    case LOCATION_CHANGE:
      // const loopState: any = state
      // loopState.entrySeq().forEach((e) => state = state.set(e[0], state.get(e[0]).set('textFieldEditMode', false).set('textFieldEditId', null)))
      return state
    default:
      return state
  }
}
