import gql from 'graphql-tag' 
export default gql`
query searchAll($searchPhrase: String!, $token: String!) {
  viewer(token: $token) {
    id
    terminals(filterText: $searchPhrase, limit: 3) {
      id
      name
      description
    }
    ipPersons(searchPhrase: $searchPhrase, limit: 3) {
      id
      surName
      firstName
    }
  }
}
`
