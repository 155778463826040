import * as React from 'react'

import updateUserPassword from 'graphql/mutations/updateUserPassword'
import { getLocalStorageToken } from 'store/localStorage'
import { getUser } from 'utils/getUser'

import PasswordChanger from 'components/UserSettings/components/PasswordChanger/PasswordChanger'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  updateUserPasswordVariables,
  updateUserPassword as updateUserPasswordType
} from 'graphql/mutations/types/updateUserPassword'
export interface Props {
  style?: any
}
export interface State {
  formValues: {
    currentPassword: string
    newPassword: string
    repeatedPassword: string
  }
}

const PasswordChangerContainer: React.FC<Props> = props => {
  const [formValues, setFormValues] = useState({
    currentPassword: '',
    newPassword: '',
    repeatedPassword: ''
  })
  const [updateTerminalDescription] = useMutation<
    updateUserPasswordType,
    updateUserPasswordVariables
  >(updateUserPassword, {
    refetchQueries: ['viewerQuery']
  })
  const { t } = useTranslation()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newFormValues: any = {}
    newFormValues[e.currentTarget.name] = e.currentTarget.value
    setFormValues({ ...formValues, ...newFormValues })
  }

  const handlePasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { currentPassword, newPassword, repeatedPassword } = formValues
    if (currentPassword === '' || repeatedPassword === '' || newPassword === '') {
      return toast.error((t('PasswordChangerContainer.missingFields') as string) ?? '')
    }

    if (newPassword !== repeatedPassword) {
      return toast.error((t('PasswordChangerContainer.passwordsNotMatching') as string) ?? '')
    }

    if (newPassword.length < 5 || newPassword.length > 32) {
      return toast.error((t('PasswordChangerContainer.invalidPassword') as string) ?? '')
    }

    const user = getUser()
    if (user) {
      try {
        const result: any = await updateTerminalDescription({
          variables: {
            token: getLocalStorageToken(),
            id: user.id,
            currentPassword: formValues.currentPassword,
            newPassword: formValues.newPassword
          }
        })
        if (result.data.updateUser) {
          toast.success((t('PasswordChangerContainer.passwordChanged') as string) ?? '')
          setFormValues({ currentPassword: '', newPassword: '', repeatedPassword: '' })
        } else {
          toast.error((t('PasswordChangerContainer.incorrectPassword') as string) ?? '')
        }
      } catch (error) {
        toast.error((t('PasswordChangerContainer.networkError') as string) ?? '')
      }
    }
  }

  return (
    <PasswordChanger
      onChange={onChange}
      onSubmit={handlePasswordChange}
      currentPasswordVal={formValues.currentPassword}
      newPasswordVal={formValues.newPassword}
      repeatedPasswordVal={formValues.repeatedPassword}
    />
  )
}
export default PasswordChangerContainer
