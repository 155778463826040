import * as React from 'react'
import moment from 'moment-timezone'
import Timeline from 'components/Timeline/Timeline'
import { ITimestamp, IIpWorkday } from 'models/interfaces'

export interface Props {
  from: moment.Moment
  to: moment.Moment
  timestamp: ITimestamp
  workdays: IIpWorkday[]
  terminalOrderIds: number[]
  terminalIpWorkplaceIds: number[]
  loading: boolean
  refetch: () => any
}
export interface State {}

class UnexpectedTimestamp extends React.Component<Props, State> {
  render() {
    const {
      from,
      to,
      workdays,
      timestamp,
      terminalOrderIds,
      terminalIpWorkplaceIds,
      loading,
      refetch
    } = this.props
    return (
      <div className="pb-[20px]">
        <Timeline
          from={from}
          to={to}
          textFreq={4}
          workdays={workdays}
          timestamp={timestamp}
          terminalOrderIds={terminalOrderIds}
          terminalIpWorkplaceIds={terminalIpWorkplaceIds}
          loading={loading}
          refetch={refetch}
        />
      </div>
    )
  }
}

export default UnexpectedTimestamp
