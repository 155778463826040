import * as React from 'react'

export interface Props {
  style?: any
  left: JSX.Element | null
  label: string | JSX.Element | null
  body: string | JSX.Element
  expandedBody?: string | JSX.Element
  right?: JSX.Element
  animationDelay?: number
  animate?: boolean
  lowerBody?: string | JSX.Element
}
export interface State {
  expanded: boolean
}

class ListItem extends React.Component<Props, State> {
  private toggleExpandBody = () =>
    this.setState({ expanded: this.props.expandedBody ? !this.state.expanded : false })
  state = { expanded: false }
  render() {
    const { left, label, body, expandedBody, right, lowerBody } = this.props
    const { expanded } = this.state
    return (
      <div
        className={`flex flex-col bg-white flex-1 max-w-full first:pb-3 pt-3 first:pt-2 p-2 last:mb-0 border-b border-gray-200 last:border-none`}
      >
        <div className="flex flex-row">
          <div
            className={`flex flex-col flex-1 ${expandedBody ? 'cursor-pointer' : ''}`}
            onClick={this.toggleExpandBody}
          >
            <div className="flex flex-row w-full mb-2">
              {left ? <div className="pr-2 flex-center">{left}</div> : <></>}
              {label ? (
                <div className="flex items-center text-sm text-gray-800 font-semibold">{label}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex flex-row w-full justify-between">
              <div className={`flex flex-row `}>
                <div className="flex-1 self-center">
                  <div className="text-sm text-gray-800">
                    <div>{body}</div>
                  </div>
                  {typeof lowerBody !== 'undefined' && lowerBody}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center flex-col sm:flex-row">{right}</div>
        </div>
        {expanded && expandedBody ? (
          <div className={`${expanded ? 'sm:px-0' : ''}`}>{expandedBody}</div>
        ) : (
          <></>
        )}
      </div>
    )
  }
}

export default ListItem
