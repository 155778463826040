import { MenuItem } from '@material-ui/core'
import { IPaginationResponse } from 'components/DataTable/models/PaginationInfo'
import MultiSelect from 'components/MultiSelect'
import TableSelectField from 'components/Table/components/TableSelectField/TableSelectField'
import { IColInfo } from 'components/Table/Table'
import AdminChangeUserPassword from 'containers/AdminChangeUserPassword/AdminChangeUserPassword'
import GraphQLTable, { DataExtractor } from 'components/GraphQLTable'
import usersQuery from 'graphql/queries/UsersQuery'
import { useCustomerSettings } from 'hooks/useCustomerSettings'
import { IOrganisation } from 'models/interfaces'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalStorageToken } from 'store/localStorage'
import { getUser, IUserObject } from 'utils/getUser'
import { RiLockPasswordFill } from 'react-icons/ri'
import { AiFillDelete } from 'react-icons/ai'
import { useConfirmDialog } from 'hooks/useConfirmDialog'
import Tooltip from '@material-ui/core/Tooltip'
import Button from 'base-components/Button'
import { useMutation } from '@apollo/client'
import mutationCreateUsersFileExport from 'graphql/mutations/mutationCreateUsersFileExport'
import downloadURI from 'utils/downloadURI'

const colInfo: IColInfo[] = [
  { key: 'username', titleTranslationKey: 'general.username', sortable: true, layoutWeight: 3.0 },
  { key: 'privilegeLevel', titleTranslationKey: 'general.privilegeLevel', sortable: true },
  {
    key: 'organisation',
    titleTranslationKey: 'general.organisations',
    sortable: false,
    maxWidth: 100
  },
  { key: 'changePassword', sortable: false },
  { key: 'removeUser', sortable: false }
]
export interface Props {
  searchText: string
  removeUser: (userId: number) => void
  updatePrivilegeLevel: (userId: number, privilegeLevel: number) => void
  updateOrganisationId: (userId: number, OrganisationIds: number[]) => void
}
export interface State {
  changePassword: boolean
  selectedUserId: number
}

const AdminUserList: React.FC<Props> = props => {
  const [changePassword, setChangePassword] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
  const variables = {
    filterText: props.searchText,
    token: getLocalStorageToken()
  }
  const customerSettings = useCustomerSettings()
  const { dialogComponent, showDialog } = useConfirmDialog()
  const { t } = useTranslation()
  const userPrivLevel = getUser()?.privilegeLevel ?? 1
  const [createUsersFileExport] = useMutation(mutationCreateUsersFileExport)

  const handleExport = async () => {
    const res = await createUsersFileExport()
    if (res.data?.createUsersFileExport) {
      downloadURI(res.data.createUsersFileExport, `userlist.xlsx`)
    }
  }
  const extractData: DataExtractor = (data: any) => {
    const currentUser = getUser()
    const { updateOrganisationId, updatePrivilegeLevel, removeUser } = props

    if (!data || !data.viewer) {
      return { rows: [], nrOfPages: 1 }
    }
    const paginatedUsers: IPaginationResponse<IUserObject> = data.viewer
      ? data.viewer.usersPaginated
      : undefined
    const organisations: IOrganisation[] = data.viewer ? data.viewer.allOrganisations : undefined
    const users = paginatedUsers ? paginatedUsers.posts : []
    const paginationInfo = paginatedUsers && paginatedUsers.paginationInfo
    let rows: any[] = []
    users.map(user => {
      const updateToUserPrivilege = () => updatePrivilegeLevel(user.id, 0)
      const updateToAdminPrivilege = () => updatePrivilegeLevel(user.id, 3)
      const updateToSuperAdminPrivilege = () => updatePrivilegeLevel(user.id, 4)

      const privilegeLevel = (
        <TableSelectField disabled={currentUser?.id === user.id} value={user.privilegeLevel}>
          <MenuItem value={0} onClick={updateToUserPrivilege}>
            {t('AdminAddUser.privilege.user')}
          </MenuItem>
          <MenuItem onClick={updateToAdminPrivilege} value={3}>
            {t('AdminAddUser.privilege.admin')}
          </MenuItem>
          <MenuItem onClick={updateToSuperAdminPrivilege} value={4} disabled={userPrivLevel < 4}>
            {t('AdminAddUser.privilege.superAdmin')}
          </MenuItem>
        </TableSelectField>
      )

      const handleChangePassword = () => {
        setChangePassword(true)
        setSelectedUserId(user.id)
      }
      const handleRemoveUser = () => {
        showDialog({
          title: t('general.remove'),
          body: (
            <span>
              {t('AdminUsersContainer.dialogText')} ({user.username})
            </span>
          ),
          confirm: {
            text: t('general.yes'),
            callback: async () => removeUser(user.id)
          },
          cancel: {
            text: t('general.no')
          }
        })
      }

      return rows.push({
        id: user.id,
        colData: {
          username: user.username,
          privilegeLevel: privilegeLevel,
          organisation: (
            <div className="w-[100px]">
              <MultiSelect
                options={organisations.map(org => ({ key: org.id, value: org.name }))}
                selectedKeys={user.Organisations.map(org => org.id)}
                includeSelectAll={true}
                includeFilter={true}
                onChange={values => {
                  let selectedOrganisations = [
                    ...user.Organisations.map(org => org.id),
                    ...values.map(value => Number(value?.option?.key))
                  ].filter((val, index, self) => self.indexOf(val) === index)
                  values.forEach(val => {
                    if (!val.checked) {
                      selectedOrganisations.splice(
                        selectedOrganisations.indexOf(Number(val?.option?.key)),
                        1
                      )
                    }
                  })
                  updateOrganisationId(user.id, selectedOrganisations)
                }}
              />
            </div>
          ),
          changePassword: customerSettings.emailLogin ? (
            <Tooltip arrow={true} placement="top" title={t('general.changePassword') ?? ''}>
              <button onClick={handleChangePassword}>
                <RiLockPasswordFill />
              </button>
            </Tooltip>
          ) : (
            <></>
          ),
          removeUser:
            currentUser?.id !== user.id && user.privilegeLevel < 4 ? (
              <Tooltip arrow={true} placement="top" title={t('general.remove') ?? ''}>
                <button onClick={handleRemoveUser}>
                  <AiFillDelete />
                </button>
              </Tooltip>
            ) : (
              <></>
            )
        }
      })
    })
    return { rows: rows, nrOfPages: paginationInfo.totalPages }
  }
  return (
    <React.Fragment>
      {dialogComponent}
      {changePassword ? (
        <AdminChangeUserPassword
          userId={selectedUserId}
          onClose={() => {
            setChangePassword(false)
            setSelectedUserId(null)
          }}
        />
      ) : null}

      <GraphQLTable
        colInfo={colInfo}
        pageSize={5}
        extractData={extractData}
        query={usersQuery}
        queryVariables={variables}
        fetchCacheAndNetwork={true}
      />

      <div className="pt-4">
        <Button variant="third" onClick={handleExport}>
          {t('AdminAddUser.export')}
        </Button>
      </div>
    </React.Fragment>
  )
}
export default AdminUserList
