import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface DialogData {
  title: string
  body: string | JSX.Element
  confirm: { text: string; callback: () => Promise<void> }
  cancel?: { text: string; callback?: () => Promise<void> }
}
export function useConfirmDialog() {
  const [dialogData, setDialogData] = useState<DialogData | undefined>(undefined)
  const { t } = useTranslation()

  const onCancel = () => {
    if (dialogData?.cancel?.callback) {
      dialogData?.cancel?.callback()
    }
    setDialogData(undefined)
  }

  const onConfirm = () => {
    if (dialogData?.confirm?.callback) {
      dialogData?.confirm?.callback()
    }
    setDialogData(undefined)
  }
  const isOpen = typeof dialogData !== 'undefined'
  const showDialog = (dialogData: DialogData) => {
    const data = dialogData
    if (typeof data.cancel === 'undefined') {
      data.cancel = { text: t('general.cancel') }
    }
    setDialogData(dialogData)
  }
  const dialogComponent = (
    <Dialog
      open={isOpen}
      onClose={() => setDialogData(undefined)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{dialogData?.title ?? ''}</DialogTitle>
      <DialogContent>
        {typeof dialogData?.body === 'string' ? (
          <DialogContentText style={{ whiteSpace: 'pre-line' }}>
            {dialogData?.body ?? ''}
          </DialogContentText>
        ) : (
          dialogData?.body
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          {dialogData?.cancel?.text ?? ''}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {dialogData?.confirm?.text ?? ''}
        </Button>
      </DialogActions>
    </Dialog>
  )
  return { showDialog, dialogComponent }
}
