import * as React from 'react'
import TerminalIcon from '@material-ui/icons/PhonelinkRing'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import NotificationsIcon from '@material-ui/icons/NotificationsActive'
import { HeaderSearchContext, HeaderSearchProvider } from 'providers/HeaderSearchProvider'
import { useTranslation, Translation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

export interface SearchResultsProps {
  goToLink: (url: string) => void
}

export interface Props {
  title: string
  icon: any
}

const SearchResultsTitle: React.SFC<Props> = props => {
  const { title, icon } = props
  const { t } = useTranslation()

  return (
    <div className="font-semibold flex items-center flex-row text-gray-500 mb-[10px]">
      <div className="mr-[10px] flex-center">{icon}</div>
      {t(title)}
    </div>
  )
}
const SearchResultsCategory: React.SFC = props => {
  const { children } = props
  return (
    <div className="list-none cursor-pointer text-gray-500 flex flex-col w-[50%] pb-2 min-w-[230px]">
      {children}
    </div>
  )
}

const SearchResults: React.SFC<SearchResultsProps> = props => {
  const { goToLink } = props

  const icons = {
    [HeaderSearchProvider.TERMINALS_CATEGORY]: (
      <TerminalIcon className="!w-[18px] !h-[18px] text-gray-700" />
    ),
    [HeaderSearchProvider.PERSONS_CATEGORY]: (
      <SupervisorAccountIcon className="!w-[18px] !h-[18px] text-gray-700" />
    ),
    [HeaderSearchProvider.NOTIFICATIONS_CATEGORY]: (
      <NotificationsIcon className="!w-[18px] !h-[18px] text-gray-700" />
    )
  }

  return (
    <HeaderSearchContext.Consumer>
      {p => {
        const { loading, searchResult, actions } = p ?? {}
        const { hasSearchResult } = actions ?? {}
        return loading || hasSearchResult?.() ? (
          <div
            className="absolute top-[74px] text-white shadow bg-white p-2 flex max-w-[600px] flex-wrap"
            style={{ width: 'calc(100% - 90px)', zIndex: 1000 }}
          >
            {Object.keys(searchResult ?? {}).map((category, index) => {
              return (
                <SearchResultsCategory key={category + index}>
                  <SearchResultsTitle title={category} icon={icons[category]} />
                  {loading ? (
                    <div>
                      <Skeleton />
                    </div>
                  ) : (
                    searchResult?.[category].map(content => {
                      return (
                        <div
                          className="pl-[29px] py-1 !text-gray-500 truncate block overflow-hidden hover:bg-gray-200 cursor-pointer"
                          style={{ width: 'calc(100% - 29px' }}
                          onMouseDown={() => goToLink(content.url)}
                          key={content.text + content.key}
                        >
                          {content.text}
                        </div>
                      )
                    })
                  )}
                  {searchResult?.[category].length === 0 && !loading ? (
                    <Translation>
                      {t => (
                        <div className="text-gray-500 pl-[29px] pt-[3px]">
                          {t('HeaderSearchBar.noResult')}
                        </div>
                      )}
                    </Translation>
                  ) : null}
                </SearchResultsCategory>
              )
            })}
          </div>
        ) : null
      }}
    </HeaderSearchContext.Consumer>
  )
}

export default SearchResults
