import * as React from 'react'
import { HeaderSearchContext } from 'providers/HeaderSearchProvider'
import { Translation } from 'react-i18next'

export interface Props {
  onSearch: () => void
  onCancel: () => void
  onFocus: () => void
  goToLink: (url: string) => void
}
export interface State {}

class HeaderSearchBar extends React.Component<Props, State> {
  private lastSearchPhrase: string | undefined = ''

  render() {
    const { children, onSearch, onCancel, onFocus, goToLink } = this.props
    return (
      <Translation>
        {t => (
          <div className="flex items-center">
            <div className={'my-0 ml-3 sm:mx-16 flex-grow'}>
              <HeaderSearchContext.Consumer>
                {p => {
                  const { searchPhrase, actions } = p ?? {}
                  const {
                    setSearchPhrase,
                    increaseActiveIndex,
                    decreaseActiveIndex,
                    resetActiveIndex,
                    getActiveLink
                  } = actions ?? {}
                  return (
                    <input
                      placeholder={t('general.search_placeholder')}
                      onBlur={() => {
                        this.lastSearchPhrase = ''
                        onCancel()
                      }}
                      onFocus={onFocus}
                      onChange={e => setSearchPhrase?.(e.target.value)}
                      onKeyDown={e => {
                        if (e.key === 'Escape') {
                          e.currentTarget.blur()
                        } else if (e.key === 'ArrowUp') {
                          decreaseActiveIndex?.()
                        } else if (e.key === 'ArrowDown') {
                          increaseActiveIndex?.()
                        }
                        if (e.ctrlKey) {
                          if (e.key === 'j') {
                            increaseActiveIndex?.()
                          } else if (e.key === 'k') {
                            decreaseActiveIndex?.()
                          }
                        }
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter' && searchPhrase !== '') {
                          if (this.lastSearchPhrase !== searchPhrase) {
                            this.lastSearchPhrase = searchPhrase
                            resetActiveIndex?.()
                            onSearch()
                          } else {
                            this.lastSearchPhrase = ''
                            getActiveLink && goToLink(getActiveLink())
                            e.currentTarget.blur()
                          }
                        }
                      }}
                      value={searchPhrase}
                      className="placeholder-gray-400 text-sm border-none text-gray-700 outline-none w-full h-[30px]"
                    />
                  )
                }}
              </HeaderSearchContext.Consumer>
              {children}
            </div>
          </div>
        )}
      </Translation>
    )
  }
}

export default HeaderSearchBar
