import * as React from 'react'
import { connect } from 'react-redux'
import {
  ISearchBarConnectedDispatch,
  ISearchBarConnectedState,
  searchBarMapDispatchToProps,
  searchBarMapStateToProps
} from './searchBarMapProps'
import SearchBar from 'components/SearchBar/components/SearchBar'

interface ISearchBarInputProps {
  searchBarId: string
  searchType: 'number' | 'text'
  hintText: string
  icon?: string
  style?: React.CSSProperties
  className?: string
  label?: string
  urlParam?: string
}

export interface ISearchBarContainer
  extends ISearchBarConnectedState,
    ISearchBarConnectedDispatch,
    ISearchBarInputProps {
  searchBarDidUpdate: (filterText: string) => void
}

class SearchBarContainerClass extends React.Component<ISearchBarInputProps, any> {
  render() {
    let props: ISearchBarContainer = this.props as ISearchBarContainer
    props = { ...props }
    return (
      <div className="w-full flex-center">
        <SearchBar {...props} />
      </div>
    )
  }
}
const SearchBarContainer = connect<any, any, any, any>(
  searchBarMapStateToProps,
  searchBarMapDispatchToProps
)(SearchBarContainerClass)
export { SearchBarContainer }
