import viewerQuery from 'graphql/queries/viewerQuery'
import AppLayout from 'layouts/AppLayout/components/AppLayout'
import { AppState, createImmutableMap, ImmutableMap } from 'models/AppState'
import * as React from 'react'
import { connect } from 'react-redux'
import { getLocalStorageToken } from 'store/localStorage'
import { browserSelector } from 'store/reducers'
import { useQuery } from '@apollo/client'
import {
  viewerQuery as viewerQueryType,
  viewerQueryVariables
} from 'graphql/queries/types/viewerQuery'

export const LOGOUT = 'LOGOUT'
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'
export const TOGGLE_USER_SETTINGS = 'TOGGLE_USER_SETTINGS'

export interface IAppLayoutState {
  drawerOpen: boolean
  userSettingsOpen: boolean
}

const initialState = createImmutableMap<IAppLayoutState>({
  drawerOpen: false,
  userSettingsOpen: false
})
export default function appLayoutReducer(
  state: ImmutableMap<IAppLayoutState> = initialState,
  action: any
) {
  switch (action.type) {
    case LOGOUT:
      return state.set('userSettingsOpen', false)
    case TOGGLE_DRAWER:
      return state.set('drawerOpen', action.payload)
    case TOGGLE_USER_SETTINGS:
      return state.set('userSettingsOpen', action.payload)
    default:
      return state
  }
}

const mapDispatchToProps = {
  toggleDrawer: (open: boolean) => ({ type: TOGGLE_DRAWER, payload: open })
}

const mapStateToProps = (state: AppState) => ({
  drawerOpen: state.appLayout.get('drawerOpen'),
  current: ''
})

const AppLayoutContainerClass: React.FC<any> = props => {
  const { data, loading } = useQuery<viewerQueryType, viewerQueryVariables>(viewerQuery, {
    variables: { token: getLocalStorageToken() }
  })
  return data && !loading ? (
    <AppLayout {...{ ...props, changePassword: Boolean(data?.viewer?.changePassword) }} />
  ) : null
}

const AppLayoutContainer: any = connect<any, any, any, any>(browserSelector)(
  connect(mapStateToProps, mapDispatchToProps)(AppLayoutContainerClass)
)
export { AppLayoutContainer }
