import moment from 'moment-timezone'

export function getFormattedDateString(date: string | Date | undefined) {
  if (!date) {
    return ''
  }

  const parsedDate = typeof date === 'string' ? new Date(date) : date

  return moment(parsedDate)
    .tz('Europe/Stockholm')
    .format('YYYY-MM-DD HH:mm:ss')
}
