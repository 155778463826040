import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import ActionPanel from 'base-components/ActionPanel'
import Button from 'base-components/Button'
import { Route, useRouteMatch, Link, useLocation, useHistory } from 'react-router-dom'
import AngleLeft from '@iconscout/react-unicons-solid/icons/uis-angle-left'
import AngleRight from '@iconscout/react-unicons-solid/icons/uis-angle-right'
import { addMonths, endOfDay, format, startOfDay, subMonths } from 'date-fns'
import { NotificationsRfidMissing } from './NotificationsRfidMissing'
import { notificationTypeCountQuery } from '../graphql/queries/queryNotificationTypeCount'
import { useQuery } from '@apollo/client'
import {
  notificationTypeCount,
  notificationTypeCountVariables
} from '../graphql/queries/types/notificationTypeCount'
import { getLocalStorageToken } from 'store/localStorage'
import AlreadyAttested from './AlreadyAttested'
import { NotificationsOrderWorkplaceMissing } from './NotificationsOrderWorkplaceMissing'
import MissingRules from './MissingRules'
import { NotificationsWorkdayNotFound } from './NotificationsWorkdayNotFound'
import { useOrganisations } from 'hooks/useOrganisations'
import { NotificationIncorrectAbsence } from './NotificationIncorrectAbsence'
import { useTranslation } from 'react-i18next'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { NotificationTypeEnum } from 'types/graphql-global-types'
import { NotificationsPersonMissingVirtual } from './NotificationsPersonMissingVirtual'
const NOTIFICATION_GROUPS_INFORMATION: {
  [key: string]: {
    title: string
    description: string
    component: React.FC<{ dateFrom: Date; dateTo: Date }>
  }
} = {
  [NotificationTypeEnum.TerminalMissingRules]: {
    title: 'NewNotifications.titles.' + NotificationTypeEnum.TerminalMissingRules,
    description: 'NewNotifications.description.' + NotificationTypeEnum.TerminalMissingRules,
    component: MissingRules
  },
  [NotificationTypeEnum.WorkdayAlreadyAttested]: {
    title: 'NewNotifications.titles.' + NotificationTypeEnum.WorkdayAlreadyAttested,
    description: 'NewNotifications.description.' + NotificationTypeEnum.WorkdayAlreadyAttested,
    component: AlreadyAttested
  },
  [NotificationTypeEnum.RfidNotFound]: {
    title: 'NewNotifications.titles.' + NotificationTypeEnum.RfidNotFound,
    description: 'NewNotifications.description.' + NotificationTypeEnum.RfidNotFound,
    component: props => <NotificationsRfidMissing dateFrom={props.dateFrom} dateTo={props.dateTo} />
  },
  [NotificationTypeEnum.TerminalMissingOrderOrWorkplace]: {
    title: 'NewNotifications.titles.' + NotificationTypeEnum.TerminalMissingOrderOrWorkplace,
    description:
      'NewNotifications.description.' + NotificationTypeEnum.TerminalMissingOrderOrWorkplace,
    component: props => (
      <NotificationsOrderWorkplaceMissing dateFrom={props.dateFrom} dateTo={props.dateTo} />
    )
  },
  [NotificationTypeEnum.WorkdayNotFound]: {
    title: 'NewNotifications.titles.' + NotificationTypeEnum.WorkdayNotFound,
    description: 'NewNotifications.description.' + NotificationTypeEnum.WorkdayNotFound,
    component: props => (
      <NotificationsWorkdayNotFound dateFrom={props.dateFrom} dateTo={props.dateTo} />
    )
  },
  [NotificationTypeEnum.IncorrectNrOfTimestamps]: {
    title: 'NewNotifications.titles.' + NotificationTypeEnum.IncorrectNrOfTimestamps,
    description: 'NewNotifications.description.' + NotificationTypeEnum.IncorrectNrOfTimestamps,
    component: props => (
      <NotificationIncorrectAbsence
        title={'NewNotifications.titles.' + NotificationTypeEnum.IncorrectNrOfTimestamps}
        dateFrom={props.dateFrom}
        dateTo={props.dateTo}
        notificationType={NotificationTypeEnum.IncorrectNrOfTimestamps}
        description={'NewNotifications.IncorrectNrOfTimestamps.component.description'}
      />
    )
  },
  [NotificationTypeEnum.IncorrectAbsence]: {
    title: 'NewNotifications.titles.' + NotificationTypeEnum.IncorrectAbsence,
    description: 'NewNotifications.description.' + NotificationTypeEnum.IncorrectAbsence,
    component: props => (
      <NotificationIncorrectAbsence
        dateFrom={props.dateFrom}
        dateTo={props.dateTo}
        notificationType={NotificationTypeEnum.IncorrectAbsence}
        title={'Felaktiga raststämplingar'}
        description={'NewNotifications.IncorrectAbsence.component.description'}
      />
    )
  },
  [NotificationTypeEnum.PersonMissingInVirtualTimestamp]: {
    title: 'NewNotifications.titles.' + NotificationTypeEnum.PersonMissingInVirtualTimestamp,
    description:
      'NewNotifications.description.' + NotificationTypeEnum.PersonMissingInVirtualTimestamp,
    component: props => (
      <NotificationsPersonMissingVirtual dateFrom={props.dateFrom} dateTo={props.dateTo} />
    )
  }
}

const Notifications: React.FC = () => {
  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const { excludeOrganisationIds } = useOrganisations()
  const searchString = useMemo(() => new URLSearchParams(location.search), [location.search])
  const searchStringDateTo = searchString.get('dateTo')

  const dateTo = useMemo(
    () => (searchStringDateTo ? endOfDay(new Date(searchStringDateTo) ?? 0) : endOfDay(new Date())),
    [searchStringDateTo]
  )
  const dateFrom = useMemo(() => startOfDay(subMonths(dateTo, 1)), [dateTo])
  const isDateToToday = useMemo(
    () => format(dateTo, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd'),
    [dateTo]
  )

  const handlePrev = useCallback(() => {
    const searchParams = new URLSearchParams()
    searchParams.set('dateTo', format(subMonths(new Date(dateTo), 1), 'yyyy-MM-dd'))
    history.replace({
      search: searchParams.toString()
    })
  }, [dateTo, history])
  const handleNext = () => {
    const dateString = format(addMonths(new Date(dateTo), 1), 'yyyy-MM-dd')

    const searchParams = new URLSearchParams()
    searchParams.set('dateTo', dateString)
    history.replace({
      search: searchParams.toString()
    })
  }

  const { data, loading, refetch } = useQuery<
    notificationTypeCount,
    notificationTypeCountVariables
  >(notificationTypeCountQuery, {
    variables: {
      token: getLocalStorageToken(),
      to: dateTo,
      from: dateFrom,
      excludeOrganisationIds
    },
    skip: excludeOrganisationIds === null
  })
  const refetchNotificationTypeCount = useRef(refetch)
  useEffect(() => {
    if (/newNotifications\/?$/.test(location.pathname)) {
      refetchNotificationTypeCount.current?.()
    }
  }, [location])
  const notificationGroups = data?.viewer?.summary?.countPerNotificationType ?? []

  const notificationGroupComponents = useMemo(
    () =>
      Object.keys(NOTIFICATION_GROUPS_INFORMATION).map(type => {
        const Component = NOTIFICATION_GROUPS_INFORMATION[type].component
        return (
          <Route key={type} path={`${path}/${type}`}>
            <Component dateFrom={dateFrom} dateTo={dateTo} />
          </Route>
        )
      }),
    [dateFrom, dateTo, path]
  )
  return (
    <div className="grid grid-cols-1 gap-2 p-2 sm:gap-4 sm:p-4">
      <div className="flex flex-col flex-center w-full">
        {location.pathname.split('/').length > 2 && (
          <div className="w-full flex-center">
            <div
              onClick={() => history.goBack()}
              className="bg-white px-3 py-1 rounded-xl flex-center pr-2 border border-gray-300 hover:bg-gray-100 hover:border-gray-400 mb-2 cursor-pointer w-48"
            >
              <span className="pr-2">{t('general.previous')}</span> <RiArrowGoBackLine />
            </div>
          </div>
        )}
        <div className={`flex-center`}>
          <nav className="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <AngleLeft />
            </button>
            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
              {t('NewNotifications.header.dateFromTo', {
                dateFrom: format(new Date(dateFrom), 'yyyy-MM-dd'),
                dateTo: format(new Date(dateTo), 'yyyy-MM-dd')
              })}
            </span>
            <button
              disabled={isDateToToday}
              onClick={handleNext}
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 ${
                isDateToToday ? 'cursor-not-allowed bg-gray-100' : 'hover:bg-gray-50'
              }`}
            >
              <AngleRight />
            </button>
          </nav>
        </div>

        {notificationGroupComponents}
        <Route path={path} exact>
          {notificationGroups.length === 0 && !loading && (
            <div className="py-8 text-lg">{t('NewNotifications.noNotifications')} 🎉</div>
          )}
          <div className="flex-center flex-col">
            {notificationGroups.map(notificationGroup => {
              const info = notificationGroup?.type
                ? NOTIFICATION_GROUPS_INFORMATION[notificationGroup.type]
                : null
              const {
                title = 'NewNotifications.titles.unknown',
                description = 'NewNotifications.description.unknown'
              } = info ?? {}
              const action = info ? (
                <Link
                  to={{
                    pathname: `${path}/${notificationGroup?.type}`,
                    search: location.search
                  }}
                >
                  <Button>{t('NewNotifications.handle')}</Button>
                </Link>
              ) : null
              return (
                <ActionPanel
                  key={notificationGroup?.type ?? ''}
                  className="mt-4 w-full max-w-xl"
                  content={t(description)}
                  title={t(title)}
                  subtitle={`${notificationGroup?.count} tid${
                    (notificationGroup?.count ?? 0) > 1 ? 'er' : ''
                  }`}
                  action={action}
                />
              )
            })}
          </div>
        </Route>
      </div>
    </div>
  )
}

export default Notifications
