import { AppState } from 'models/AppState'
import { LOGIN_ERROR, LOGIN_SUBMIT, LOGIN_UPDATE_TOKEN, LOGIN_REMOVE_TOKEN } from './loginReducer'

export interface ILoginConnectedState {
  loginForm: any
  loginError: boolean
  loggingIn: boolean
}

export interface ILoginConnectedDispatch {
  login: (token: string) => void
  logout: () => void
  loginSubmit: () => void
  authenticationError: (error: string) => void
  goToStart: () => void
}

export const loginMapStateToProps = (state: AppState): ILoginConnectedState => ({
  loginForm: state.form.login,
  loginError: state.login.get('loginError'),
  loggingIn: state.login.get('loggingIn')
})

export const loginMapDispatchToProps: any = {
  login: (token: string) => ({ type: LOGIN_UPDATE_TOKEN, payload: token }),
  logout: () => ({ type: LOGIN_REMOVE_TOKEN }),
  loginSubmit: () => ({ type: LOGIN_SUBMIT }),
  authenticationError: (error: string) => ({ type: LOGIN_ERROR, payload: error })
}
