import React from 'react'
import ErrorUpdateMessage from './ErrorUpdateMessage'

export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    console.log(error)
    if (error.name === 'ChunkLoadError') {
      return { hasError: true }
    } else {
      return { hasError: false }
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorUpdateMessage />
    }
    return this.props.children
  }
}
