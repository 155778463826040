import { createImmutableMap, ImmutableMap } from 'models/AppState'
import { ADMIN_CREATE_ORGANISATION_UPDATE } from './organisationsMapProps'

export interface IOrganisationsState {
  createOrganisationStatus: { success: boolean; message: string }
}

const initialState = createImmutableMap<IOrganisationsState>({
  createOrganisationStatus: {
    success: false,
    message: ''
  }
})
export function organisationsReducer(
  state: ImmutableMap<IOrganisationsState> = initialState,
  action: any
) {
  switch (action.type) {
    case ADMIN_CREATE_ORGANISATION_UPDATE:
      return state.set('createOrganisationStatus', action.payload)
    default:
      return state
  }
}
