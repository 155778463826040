import * as Immutable from 'immutable'
import { IAppLayoutState } from 'layouts/AppLayout/containers/AppLayoutContainer'
import { ICreateUserForm } from 'containers/AdminUsers/AdminUsersContainer'
import { IAdminUsersState } from 'containers/AdminUsers/adminUsersReducer'
import { IOrganisationsState } from 'containers/AdminOrganisations/organisationsReducer'
import { IDataTableState } from 'components/DataTable/containers/dataTableReducer'
import { INotificationsState } from 'containers/Notifications/notificationsReducer'
import { ISearchBarState } from 'components/SearchBar/containers/searchBarReducer'
import { ILoginState } from 'containers/Login/loginReducer'
import { IRuleWizardState } from 'containers/RuleNewWizardContainer/RuleNewWizardReducer'

interface IForms {
  createUser: IReduxFormValues<ICreateUserForm>
  [key: string]: any
}

export interface AppState {
  form: IForms
  routing: any
  appLayout: ImmutableMap<IAppLayoutState>
  dataTable: ImmutableMap<IDataTableState>
  searchBar: ImmutableMap<ISearchBarState>
  login: ImmutableMap<ILoginState>
  admin: ImmutableMap<IAdminUsersState>
  organisations: ImmutableMap<IOrganisationsState>
  notifications: ImmutableMap<INotificationsState>
  ipOrderRuleWizard: ImmutableMap<IRuleWizardState>
}

export interface ImmutableMap<T> {
  get<K extends keyof T>(name: K): T[K]
  set<K extends keyof T>(o: K, val: T[K]): ImmutableMap<T>
}

export const createImmutableMap = <T>(initValues: T): any => Immutable.Map(initValues)

interface IReduxFormValues<TProps> {
  anyTouched: boolean
  fields: any[]
  registeredFields: any[]
  values: TProps
}
