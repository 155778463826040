import * as React from 'react'
import UnexpectedTimestamp from 'components/UnexpectedTimestamp/UnexpectedTimestamp'
import queryPersonWorkdays from 'graphql/queries/queryPersonWorkdays'
import queryTerminalWorkplacesOrders from 'graphql/queries/queryTerminalWorkplacesOrders'
import { useQuery } from '@apollo/client'
import { getLocalStorageToken } from 'store/localStorage'
import { INotificaion } from 'models/interfaces'
import moment from 'moment-timezone'
import {
  queryPersonWorkdays as queryPersonWorkdaysType,
  queryPersonWorkdaysVariables
} from 'graphql/queries/types/queryPersonWorkdays'
import {
  queryTerminalWorkplacesOrders as queryTerminalWorkplacesOrdersType,
  queryTerminalWorkplacesOrdersVariables
} from 'graphql/queries/types/queryTerminalWorkplacesOrders'

export interface Props {
  ipPersonId?: number
  hoursBefore: number
  hoursAfter: number
  notification: INotificaion
}
export interface State {}

const UnexpectedTimestampContainer: React.FC<Props> = props => {
  const { ipPersonId, hoursBefore, hoursAfter, notification } = props

  const centerTime = moment(notification.timestamp.timeStamped).startOf('hour')
  const from = moment(centerTime).subtract(hoursBefore, 'hours')
  const to = moment(centerTime).add(hoursAfter, 'hours')
  const fetchFrom = moment(from).subtract(24, 'hours')

  const workdayVars = {
    token: getLocalStorageToken(),
    from: fetchFrom,
    to: to,
    ipPersonId: ipPersonId ?? 0
  }
  const termOrdersVars = {
    token: getLocalStorageToken(),
    terminalId: notification.terminal?.id ?? 0
  }
  const { data: workdaysData, loading: loadingWorkdays, refetch: refetchWorkdays } = useQuery<
    queryPersonWorkdaysType,
    queryPersonWorkdaysVariables
  >(queryPersonWorkdays, { variables: workdayVars, skip: !ipPersonId })
  const { data: terminalData, loading: loadingOrders, refetch: refetchOrders } = useQuery<
    queryTerminalWorkplacesOrdersType,
    queryTerminalWorkplacesOrdersVariables
  >(queryTerminalWorkplacesOrders, { variables: termOrdersVars, skip: !notification.terminal?.id })

  const workdays = workdaysData?.viewer?.ipWorkdays ?? []
  const terminalOrderIds = (terminalData?.viewer?.terminal?.orders ?? []).map(t => t?.ipOrderId)
  const terminalIpWorkplaceIds = (terminalData?.viewer?.terminal?.ipWorkplaces ?? []).map(
    t => t?.id
  )
  const refetch = () => {
    refetchWorkdays()
    refetchOrders()
  }
  return (
    <UnexpectedTimestamp
      from={from}
      to={to}
      timestamp={notification.timestamp}
      workdays={workdays as any}
      terminalOrderIds={terminalOrderIds as any}
      terminalIpWorkplaceIds={terminalIpWorkplaceIds as any}
      loading={loadingWorkdays || loadingOrders}
      refetch={refetch}
    />
  )
}

export default UnexpectedTimestampContainer
