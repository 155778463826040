import { useState, useEffect, useRef } from 'react'

export function useThrottle<T>(value: T, limit: number) {
  const [throttledValue, setThrottledValue] = useState<T>(value)
  const lastRan = useRef(Date.now())

  useEffect(() => {
    const handler = setTimeout(function() {
      if (Date.now() - lastRan.current >= limit) {
        setThrottledValue(value)
        lastRan.current = Date.now()
      }
    }, limit)

    return () => {
      clearTimeout(handler)
    }
  }, [value, limit])

  return throttledValue
}
