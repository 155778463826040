import gql from 'graphql-tag'
export default gql`
  query timestampsTerminals($token: String!, $timestampId: Int!) {
    viewer(token: $token) {
      id
      timestamps(ids: [$timestampId]) {
        id
        TerminalId
      }
      terminals {
        description
        name
        id
      }
    }
  }
`
