import { OrganisationsContext } from 'providers/OrganisationsProvider'
import { useContext, useEffect } from 'react'

export function useOrganisations() {
  const context = useContext(OrganisationsContext)

  useEffect(() => {
    try {
      const item = window.localStorage.getItem('excludeOrganisationIds')
      context?.actions.setExcludedOrganisationsIds(item ? JSON.parse(item) : [])
    } catch (error) {
      context?.actions.setExcludedOrganisationsIds([])
    }
  }, [context?.actions])

  const setExcludedOrganisations = (val: number[]) => {
    window.localStorage.setItem('excludeOrganisationIds', JSON.stringify(val))
    context?.actions.setExcludedOrganisationsIds(val)
  }

  return { excludeOrganisationIds: context?.excludeOrganisationIds ?? [], setExcludedOrganisations }
}
