import { HttpLink, InMemoryCache, ApolloClient, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getLocalStorageToken } from 'store/localStorage'
import cursorBasedPagination from 'utils/cursorBasedPagination'
import { onError } from '@apollo/client/link/error'
import signOut from 'utils/signOut'

const errorLink = onError(error => {
  const { graphQLErrors } = error
  console.log(graphQLErrors)
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          signOut()
          break
      }
    })
  }
})
const getClient = (url?: any) => {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getLocalStorageToken()
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })
  const httpLink = new HttpLink({
    uri: url
  })
  return new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            cursorPaginatedTimestamps: cursorBasedPagination(['ipPersonId', 'limit', 'TerminalId']),
            cursorPaginatedDebugData: cursorBasedPagination(['TimestampId', 'limit'])
          }
        }
      }
    }),
    name: 'frontend',
    version: '1.0.0'
  })
}

// ========================================================
// Apollo client Instantiation
// ========================================================

export { getClient }
