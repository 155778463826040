import * as React from 'react'
import moment from 'moment-timezone'

export interface Props {
  workplaceName: string
  description: string
  from: string
  to: string
  setHasActions: (hasActions: boolean) => void
  disableActionClick?: boolean
  punchInDateFrom?: Date
  punchOutDateTo?: Date
  ipOrderId: number
  isBossConfirmed: boolean
}
export interface State {}

class WorkdayActionHover extends React.Component<Props, State> {
  private actionsContainer: HTMLElement | null = null

  private setActionsRef = elem => {
    this.actionsContainer = elem
    if (this.actionsContainer) {
      this.props.setHasActions(this.actionsContainer.childElementCount > 0)
    }
  }
  componentDidUpdate() {
    this.props.setHasActions(
      this.actionsContainer && this.actionsContainer.childElementCount > 0 ? true : false
    )
  }
  private openRule = ipOrderId => () =>
    window?.open?.(`/rules?selectedOrder=${ipOrderId}`, '_blank')?.focus()

  render() {
    const {
      children,
      workplaceName,
      to,
      from,
      disableActionClick,
      description,
      punchInDateFrom,
      punchOutDateTo,
      ipOrderId,
      isBossConfirmed
    } = this.props
    return (
      <React.Fragment>
        <div
          className="text-white text-sm font-normal text-center truncate whitespace-nowrap overflow-hidden"
          onClick={this.openRule(ipOrderId)}
        >
          {workplaceName} - {description}
        </div>
        <div className="text-[11px] text-white my-[3px] mx-0 text-center">
          <span className="my-0 mx-[5px] text-gray-400">
            {punchInDateFrom ? `(${moment(punchInDateFrom).format('HH:mm')})` : ''}
          </span>
          <span className="text-[13px]">
            {from} - {to}
          </span>
          <span className="my-0 mx-[5px] text-gray-300">
            {punchOutDateTo ? `(${moment(punchOutDateTo).format('HH:mm')})` : ''}
          </span>
        </div>
        <div
          ref={this.setActionsRef}
          className={[disableActionClick ? 'pointer-events-none' : null].join(' ')}
        >
          {!isBossConfirmed ? (
            children
          ) : (
            <span className="text-white text-[13px] w-full flex-center">
              Arbetspasset är redan attesterat
            </span>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default WorkdayActionHover
