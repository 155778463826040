import React from 'react'
import { useForm } from 'react-hook-form'
import { useThrottle } from 'react-use'
import GeneralSearchBase, { GeneralSearchResult } from './GeneralSearchBase'

interface Props<V extends { id: number }> {
  value: { id: number; title: string } | null | undefined
  onChange: (value: GeneralSearchResult<V> | null) => void
  prefetchedResult: GeneralSearchResult<V>[] | undefined
  size?: 'small' | 'large'
  label?: string
  loading?: boolean
}

function GeneralSearchPrefetched<T extends { id: number }>({
  value,
  onChange,
  prefetchedResult,
  size,
  label,
  loading
}: Props<T>) {
  const { register, watch, reset } = useForm<{ searchPhrase: string }>()
  const searchPhrase = useThrottle<string | null>(watch('searchPhrase'), 300)

  const prefetchFilter = (result: GeneralSearchResult<any>) => {
    for (const obj of [result, result.item]) {
      for (const key in obj) {
        if (
          !searchPhrase ||
          (typeof obj[key] === 'string' &&
            obj[key] &&
            typeof searchPhrase === 'string' &&
            Boolean(obj[key].match(new RegExp(searchPhrase, 'i'))))
        ) {
          return true
        }
      }
    }

    return false
  }

  const results = prefetchedResult?.filter(prefetchFilter).slice(0, 5) ?? []

  return (
    <GeneralSearchBase
      value={value}
      onChange={onChange}
      results={results}
      reset={reset}
      register={register}
      size={size}
      label={label}
      loading={loading}
    />
  )
}

export default GeneralSearchPrefetched
