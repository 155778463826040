/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AbsenceOutputTypeEnum {
  BreakMinutes = "BreakMinutes",
  SplitWorkday = "SplitWorkday",
}

export enum AssociatedGeofenceTypeEnum {
  Order = "Order",
  Workplace = "Workplace",
}

export enum BreakStyleEnum {
  BreakMinutes = "BreakMinutes",
  BreakPositions = "BreakPositions",
}

export enum FileExportFileTypeEnum {
  Excel = "Excel",
  PDF = "PDF",
}

export enum FileImportCellErrorEnum {
  BreakOutsideWorkday = "BreakOutsideWorkday",
  CalendarItemCollision = "CalendarItemCollision",
  FailedToAddIpArticles = "FailedToAddIpArticles",
  FailedToAttestIpWorkday = "FailedToAttestIpWorkday",
  FailedToCreateIpAbsence = "FailedToCreateIpAbsence",
  FailedToCreateIpAbsencePeriod = "FailedToCreateIpAbsencePeriod",
  FailedToCreateIpWorkday = "FailedToCreateIpWorkday",
  FailedUpdatingExistingIpObject = "FailedUpdatingExistingIpObject",
  IncorrectCellType = "IncorrectCellType",
  IpAbsenceCodeDoesNotExist = "IpAbsenceCodeDoesNotExist",
  IpArticleDoesNotExist = "IpArticleDoesNotExist",
  IpOrderDoesNotExist = "IpOrderDoesNotExist",
  IpPersonDoesNotExist = "IpPersonDoesNotExist",
  MissingAbsenceCodeMapping = "MissingAbsenceCodeMapping",
  MissingArticleMapping = "MissingArticleMapping",
  MissingDateFrom = "MissingDateFrom",
  MissingDateTo = "MissingDateTo",
  MissingIpObjectId = "MissingIpObjectId",
  MissingIpValidationData = "MissingIpValidationData",
  MissingOrderMapping = "MissingOrderMapping",
  MissingPersonMapping = "MissingPersonMapping",
  MultipleBreakTypes = "MultipleBreakTypes",
  NoExistingWorkday = "NoExistingWorkday",
  TooFarOutsideExistingIpObject = "TooFarOutsideExistingIpObject",
  UnsafeCell = "UnsafeCell",
  WorkdayWithErrorSameDay = "WorkdayWithErrorSameDay",
}

export enum FileImportCellStatusEnum {
  Error = "Error",
  Ignore = "Ignore",
  Ready = "Ready",
  Sending = "Sending",
  Sent = "Sent",
}

export enum FileImportCellTypeEnum {
  Absence = "Absence",
  Workday = "Workday",
}

export enum FileImportSettingsTypeEnum {
  AbsenceCode = "AbsenceCode",
  Boolean = "Boolean",
  Number = "Number",
  Order = "Order",
  Organisation = "Organisation",
  Person = "Person",
  Terminal = "Terminal",
  Workplace = "Workplace",
}

export enum FileImportStatusEnum {
  Completed = "Completed",
  Error = "Error",
  FailedProcessing = "FailedProcessing",
  NotCompleted = "NotCompleted",
  Processing = "Processing",
  Sending = "Sending",
}

export enum FileTypeEnum {
  Agda = "Agda",
  Article = "Article",
  DSV = "DSV",
  EON = "EON",
  Fieldglass = "Fieldglass",
  FlexHRM = "FlexHRM",
  FleximeStandard = "FleximeStandard",
  LN4 = "LN4",
  Looker = "Looker",
  LookerArticles = "LookerArticles",
  Quinyx = "Quinyx",
  Schedule = "Schedule",
  Teleopti = "Teleopti",
  Tidomat = "Tidomat",
}

export enum NotificationGroupTypeEnum {
  PersonTimestampGroup = "PersonTimestampGroup",
  TerminalGroup = "TerminalGroup",
}

export enum NotificationTypeEnum {
  IncorrectAbsence = "IncorrectAbsence",
  IncorrectNrOfTimestamps = "IncorrectNrOfTimestamps",
  NetworkError = "NetworkError",
  PersonMissingInVirtualTimestamp = "PersonMissingInVirtualTimestamp",
  RfidNotFound = "RfidNotFound",
  TerminalMissingOrderOrWorkplace = "TerminalMissingOrderOrWorkplace",
  TerminalMissingRules = "TerminalMissingRules",
  UnknownError = "UnknownError",
  WorkdayAlreadyAttested = "WorkdayAlreadyAttested",
  WorkdayNotFound = "WorkdayNotFound",
}

export enum TimestampDirectionEnum {
  BreakEnd = "BreakEnd",
  BreakStart = "BreakStart",
  In = "In",
  InOrder = "InOrder",
  Out = "Out",
  OutAbsence = "OutAbsence",
  SwitchOrder = "SwitchOrder",
  Unknown = "Unknown",
}

export enum TimestampRuleAttestTypeEnum {
  Attest = "Attest",
  None = "None",
}

export enum TimestampRuleDurationTypeEnum {
  Rest = "Rest",
  Time = "Time",
}

export enum TimestampRuleRoundingTypeEnum {
  None = "None",
  OriginalDate = "OriginalDate",
  ToMinutes = "ToMinutes",
}

export enum TimestampRuleTypeEnum {
  InAfterStart = "InAfterStart",
  InBeforeStart = "InBeforeStart",
  OutAfterEnd = "OutAfterEnd",
  OutBeforeEnd = "OutBeforeEnd",
}

export enum automationActionEnum {
  AddOrganisationToUser = "AddOrganisationToUser",
  CreateGeoFence = "CreateGeoFence",
  CreateTerminal = "CreateTerminal",
  SlackBotMessage = "SlackBotMessage",
  WebHook = "WebHook",
}

export enum automationActionInputEnumType {
  InputData = "InputData",
  Json = "Json",
  Order = "Order",
  Organisation = "Organisation",
  String = "String",
  Time = "Time",
  User = "User",
}

export enum automationTriggerEnum {
  FailedProcessingWarehouseEntry = "FailedProcessingWarehouseEntry",
  FileImportCreated = "FileImportCreated",
  FileImportFailedProcessing = "FileImportFailedProcessing",
  NotificationCreated = "NotificationCreated",
  OrderAdded = "OrderAdded",
  OrderUpdated = "OrderUpdated",
  OrganisationCreated = "OrganisationCreated",
  PersonAdded = "PersonAdded",
  PersonUpdated = "PersonUpdated",
  TimedEvent = "TimedEvent",
  WebHook = "WebHook",
  WorkplaceAdded = "WorkplaceAdded",
  WorkplaceUpdated = "WorkplaceUpdated",
}

export enum automationTriggerInputEnumType {
  FileImportFileType = "FileImportFileType",
  NotificationType = "NotificationType",
  String = "String",
  TimeInterval = "TimeInterval",
}

export enum geoFenceMetaParentTypeEnum {
  Order = "Order",
  Terminal = "Terminal",
  Unknown = "Unknown",
  Workplace = "Workplace",
}

export enum ipArticleTypes {
  Absence = "Absence",
  Fee = "Fee",
  Material = "Material",
  Salary = "Salary",
  Time = "Time",
}

export enum positionAccuracyEnum {
  Approximate = "Approximate",
  GeometricCenter = "GeometricCenter",
  RangeInterpolated = "RangeInterpolated",
  Rooftop = "Rooftop",
  Unknown = "Unknown",
}

export interface CreateAutomationAction {
  type: automationActionEnum;
  inputs?: CreateAutomationActionInput[] | null;
}

export interface CreateAutomationActionInput {
  name: string;
  value: string;
}

export interface CreateAutomationTriggerInput {
  name: string;
  value: string;
  type: automationTriggerInputEnumType;
}

export interface CreateIpAbsencePeriodType {
  ipAbsenceCode?: number | null;
  dateFrom?: string | null;
  dateTo?: string | null;
  ipPersonId?: number | null;
  isBossConfirmed?: boolean | null;
  ipOrderId?: number | null;
}

export interface FileImportCellBreakPositionType {
  dateFrom?: any | null;
  dateTo?: any | null;
}

export interface FileImportCellsShowOptions {
  sent?: boolean | null;
  error?: boolean | null;
  unsent?: boolean | null;
}

export interface FileImportFileInfo {
  filename: string;
  mimetype: string;
  url: string;
  size: number;
}

export interface FileImportSettingInput {
  type: FileImportSettingsTypeEnum;
  value: string;
  name: string;
}

export interface TimestampRule {
  type?: TimestampRuleTypeEnum | null;
  durationType?: TimestampRuleDurationTypeEnum | null;
  duration?: number | null;
  roundingType?: TimestampRuleRoundingTypeEnum | null;
  roundingTime?: number | null;
  attestType?: TimestampRuleAttestTypeEnum | null;
  sortOrder?: number | null;
  ipOrderId?: number | null;
  OrderId?: number | null;
  TimestampRulesTemplateId?: number | null;
  ipWorkplaceId?: number | null;
  WorkplaceId?: number | null;
}

export interface TimestampRulesChanges {
  type?: TimestampRuleTypeEnum | null;
  durationType?: TimestampRuleDurationTypeEnum | null;
  duration?: number | null;
  roundingType?: TimestampRuleRoundingTypeEnum | null;
  roundingTime?: number | null;
  attestType?: TimestampRuleAttestTypeEnum | null;
  sortOrder?: number | null;
  id?: number | null;
  TimestampRulesTemplateId?: number | null;
  ipWorkplaceId?: number | null;
}

export interface UpdateAutomationAction {
  id?: number | null;
  type: automationActionEnum;
  inputs?: UpdateAutomationActionInput[] | null;
}

export interface UpdateAutomationActionInput {
  id?: number | null;
  name: string;
  value: string;
}

export interface UpdateFileImportCellInputType {
  id: number;
  dateFrom?: any | null;
  dateTo?: any | null;
  ipPersonId?: number | null;
  ipOrderId?: number | null;
  ipAbsenceCodeId?: number | null;
  ipObjectId?: number | null;
  updateIpObjectId?: number | null;
  status?: FileImportCellStatusEnum | null;
  breakMinutes?: number | null;
  statusMessage?: string | null;
  validatedAt?: any | null;
  error?: FileImportCellErrorEnum | null;
  errorInfo?: string | null;
  hasCreatedAbsence?: boolean | null;
  breakPositions?: FileImportCellBreakPositionType[] | null;
}

export interface UpdateFileImportErrorGroupCellType {
  dateFrom?: any | null;
  dateTo?: any | null;
  ipPersonId?: number | null;
  ipOrderId?: number | null;
  ipAbsenceCodeId?: number | null;
  ipObjectId?: number | null;
  status?: FileImportCellStatusEnum | null;
  statusMessage?: string | null;
  error?: FileImportCellErrorEnum | null;
  errorInfo?: string | null;
}

export interface UpdateFileImportErrorSelectGroupType {
  error: FileImportCellErrorEnum;
  errorInfo?: string | null;
  ipAbsenceCodeId?: number | null;
  ipArticleId?: number | null;
  ipOrderId?: number | null;
  ipPersonId?: number | null;
  externalAbsenceCodeId?: string | null;
  externalOrderId?: string | null;
  externalPersonId?: string | null;
  id?: number | null;
}

export interface UpdateInput {
  id: number;
  value?: string | null;
}

export interface UpdateIpArticlesValues {
  ipArticleId?: number | null;
  externalArticleId?: string | null;
}

export interface UpdateTimestampsType {
  id: number;
  timeSent?: any | null;
  timeSend?: any | null;
  direction?: TimestampDirectionEnum | null;
  status?: number | null;
  terminalId?: number | null;
  sendAttempts?: number | null;
  ipPersonId?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
