import gql from 'graphql-tag'
export default gql`
  mutation updateTimestamp(
    $id: Int!
    $token: String!
    $timeSend: Date
    $direction: TimestampDirectionEnum
    $terminalId: Int
    $sendAttempts: Int
    $ipPersonId: Int
  ) {
    updateTimestamp(
      id: $id
      token: $token
      timeSend: $timeSend
      direction: $direction
      terminalId: $terminalId
      sendAttempts: $sendAttempts
      ipPersonId: $ipPersonId
    )
  }
`
