import gql from 'graphql-tag'
export default gql`
  query personTimestamps(
    $token: String!
    $ipPersonId: Int!
    $first: Int
    $after: Int
    $sortOrder: String
    $orderBy: String
    $dateFrom: Date
    $dateTo: Date
  ) {
    viewer(token: $token) {
      id
      terminals {
        id
        name
        description
      }
      paginatedTimestamps(
        first: $first
        after: $after
        orderBy: $orderBy
        sortOrder: $sortOrder
        ipPersonId: $ipPersonId
        from: $dateFrom
        to: $dateTo
      ) {
        paginationInfo {
          id
          totalPages
          pageSize
          currentPage
        }
        posts {
          TerminalId
          rfid
          id
          timeStamped
          timeSent
          direction
          simpleNotification {
            id
            type
          }
        }
      }
    }
  }
`
