import { createImmutableMap, ImmutableMap } from 'models/AppState'

export interface IAdminTerminalsState {}

const initialState = createImmutableMap<IAdminTerminalsState>({})
export function adminTerminalsReducer(
  state: ImmutableMap<IAdminTerminalsState> = initialState,
  action: any
) {
  switch (action.type) {
    default:
      return state
  }
}
