import * as React from 'react'
import moment from 'moment-timezone'
import * as get from 'lodash.get'
import Workday from './Workday/Workday'
import { IIpWorkday, ITimestamp } from 'models/interfaces'
import { FleximeLoader } from 'components/FleximeLoader/FleximeLoader'
import TimestampMarker from './TimestampMarker/TimestampMarker'
import ResetSearchIcon from '@material-ui/icons/YoutubeSearchedFor'
import RefreshIcon from '@material-ui/icons/Refresh'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import { IconButton } from '@material-ui/core'
import { Translation } from 'react-i18next'
import { TimestampDirectionEnum } from 'types/graphql-global-types'

export interface Props {
  from: moment.Moment
  to: moment.Moment
  textFreq: number
  workdays: IIpWorkday[]
  timestamp: ITimestamp
  terminalOrderIds: number[]
  terminalIpWorkplaceIds?: number[]
  loading: boolean
  refetch: () => any
  expandRules?: boolean
  overwrittenXOffset?: number
  disableActionClick?: boolean
}
export interface State {
  xOffset: number
  zoom: number
  isMobile: boolean
}

interface TimeMap {
  [key: string]: JSX.Element
}

class Timeline extends React.Component<Props, State> {
  private TIME_STORE_FORMAT = 'MM-DD HH'
  private ZOOM_TICK = 3
  private ZOOM_MOB_MIN = 250
  private ZOOM_MOB_MAX = 500
  private ZOOM_MIN = 100
  private ZOOM_MAX = 250

  private scrollStart = 0
  private currentScroll = this.scrollStart
  private container: HTMLElement | null = null
  private innerContainer: HTMLElement | null = null

  state = {
    xOffset: this.currentScroll,
    zoom: 100,
    isMobile: false
  }

  private existsOnTerminal = (workday: IIpWorkday) => {
    const { terminalOrderIds, terminalIpWorkplaceIds } = this.props
    const orderId = get(workday, 'ipOrder.id', null)
    const ipWorkplaceId = get(workday, 'ipOrder.workplace.id', null)
    return (
      (orderId && terminalOrderIds.indexOf(orderId) !== -1) ||
      (ipWorkplaceId && terminalIpWorkplaceIds?.indexOf(ipWorkplaceId) !== -1)
    )
  }

  private getSortedWorkdays = (
    workdays: IIpWorkday[],
    from: moment.Moment,
    to: moment.Moment
  ): TimeMap => {
    const map: TimeMap = {}
    const { terminalIpWorkplaceIds, timestamp, expandRules, disableActionClick } = this.props
    let sortedWorkdays = [...workdays].sort((workdayA, workdayB) =>
      (workdayA.originalDateTo || workdayA.dateTo) < (workdayB.originalDateTo || workdayB.dateTo)
        ? 1
        : -1
    )
    sortedWorkdays.map(workday => {
      let hour = 0
      if (new Date(workday.originalDateFrom || workday.dateFrom) < from) {
        hour = from.format(this.TIME_STORE_FORMAT)
      } else if (new Date(workday.originalDateTo || workday.dateTo) > to) {
        hour = to.format(this.TIME_STORE_FORMAT)
      } else {
        hour = moment(workday.originalDateFrom || workday.dateFrom)
          .startOf('hour')
          .format(this.TIME_STORE_FORMAT)
      }

      if (!map[hour]) {
        map[hour] = (
          <Workday
            workday={workday}
            maxDate={to}
            minDate={from}
            existsOnTerminal={this.existsOnTerminal(workday)}
            timestamp={timestamp}
            expandRules={expandRules}
            disableActionClick={disableActionClick}
            terminalIpWorkplaceIds={terminalIpWorkplaceIds ?? []}
          />
        )
      }
      return map
    })
    return map
  }

  private getTimeline = (
    from: moment.Moment,
    to: moment.Moment,
    textFreq: number,
    sortedWorkdays: TimeMap,
    timestamp: ITimestamp,
    workdays: any[]
  ) => {
    let timeline: JSX.Element[] = []
    // Adding one empty hour in the beginning of the timeline to match the last hour, which is just a dot
    timeline.push(<div className="flex-1 relative" key={-1} />)
    let currTime = moment(from)
    let timestampMarkerOffset = timestamp?.timeStamped
      ? (((timestamp.timeStamped / 1000 / 60) % 60) / 60) * 100
      : 0
    const timeStamped = moment(timestamp.timeStamped)

    let index = 0
    while (currTime <= to) {
      const timestampMarker =
        timeStamped.format(this.TIME_STORE_FORMAT) === currTime.format(this.TIME_STORE_FORMAT) ? (
          <TimestampMarker
            offset={timestampMarkerOffset}
            direction={timestamp?.direction ?? TimestampDirectionEnum.In}
            timeStamped={timeStamped}
            timestampId={timestamp?.id ?? 0}
            workdayOrderIds={workdays.map(workday => workday.ipOrder.id)}
            comment={timestamp.comment}
          />
        ) : null
      timeline.push(
        <div className="flex-1 relative" key={index}>
          {timestampMarker}
          {sortedWorkdays[currTime.format(this.TIME_STORE_FORMAT)]}

          <div className="w-[5px] h-[5px] rounded-[2.5px] bg-gray-400 absolute bottom-0 left-0" />
          {currTime < to ? (
            <div className="h-[1px] bg-gray-400 absolute bottom-[2.5px] left-0 w-full" />
          ) : null}
          {index % textFreq === 0 ? (
            <div className="absolute -bottom-3 -left-3 text-xs text-gray-500 select-none">
              {currTime.format('HH:mm')}
            </div>
          ) : null}
        </div>
      )
      currTime.add(1, 'hours')
      index++
    }
    return timeline
  }

  private setOffsetZoom = (xOffset: number, zoom?: number) => {
    let newZoom = zoom
    if (typeof zoom === 'undefined') {
      newZoom = this.state.zoom
    }

    const maxOffset =
      this.container && newZoom
        ? this.container.getBoundingClientRect().width * (newZoom / 100) -
          this.container.getBoundingClientRect().width
        : 0

    let newOffset = Math.min(Math.max(xOffset, -maxOffset), 0)

    this.setState({
      zoom: newZoom ?? 0,
      xOffset: newOffset
    })
  }

  private onMouseMove = (event: any) => {
    if (event.buttons === 1) {
      this.setOffsetZoom(this.currentScroll + event.pageX - this.scrollStart)
    } else if (event.type === 'touchmove') {
      if (event.targetTouches.length === 1) {
        this.setOffsetZoom(this.currentScroll + event.targetTouches[0].pageX - this.scrollStart)
      }
    }
  }

  private onMouseDown = (event: any) => {
    if (event.type === 'touchstart') {
      if (event.targetTouches.length > 0) {
        this.scrollStart = event.targetTouches[event.targetTouches.length - 1].pageX
      }
    } else {
      this.scrollStart = event.pageX
    }
  }

  private onMouseUp = () => {
    this.currentScroll = this.state.xOffset
  }

  private zoomOnPos = (pageX: number, newZoom: number) => {
    newZoom = Math.min(
      Math.max(newZoom, this.state.isMobile ? this.ZOOM_MAX : this.ZOOM_MIN),
      this.state.isMobile ? this.ZOOM_MOB_MAX : this.ZOOM_MOB_MIN
    )
    const boundingRect = this.container?.getBoundingClientRect()
    const innerBoundingRect: any = this.innerContainer?.getBoundingClientRect()
    const absScale = 1 + (newZoom - 100) / 100

    const distFromLeft = pageX - innerBoundingRect.x
    const distLeftPerc = distFromLeft / innerBoundingRect.width
    const newWidth = boundingRect ? boundingRect.width * absScale : 0
    const targetLeftPerc = newWidth * distLeftPerc
    const distLeftDiff = targetLeftPerc - distFromLeft

    this.currentScroll = this.currentScroll - distLeftDiff
    this.setOffsetZoom(this.currentScroll, newZoom)
  }

  private onWheel = (event: any) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault()
      if (event.deltaY < 0) {
        this.zoomOnPos(event.pageX, this.state.zoom + this.ZOOM_TICK)
      } else if (event.deltaY > 0) {
        this.zoomOnPos(event.pageX, this.state.zoom - this.ZOOM_TICK)
      }
    }
  }

  private setInnerContainer = elem => {
    this.innerContainer = elem
  }
  private setContainer = elem => {
    this.container = elem
  }

  componentDidUpdate(oldProps: any) {
    const containerBounding = this.container?.getBoundingClientRect()
    if (containerBounding && containerBounding.width < 600 && !this.state.isMobile) {
      this.setState({ isMobile: true })
    } else if (containerBounding && containerBounding.width > 600 && this.state.isMobile) {
      this.setState({ isMobile: false })
    }

    if (this.props.overwrittenXOffset !== oldProps.overwrittenXOffset && this.state.isMobile) {
      this.setState({ xOffset: this.props.overwrittenXOffset ?? 0 })
    }
  }

  componentDidMount() {
    const containerBounding: any = this.container?.getBoundingClientRect()
    if (containerBounding.width < 600) {
      this.zoomOnPos(containerBounding.x + containerBounding.width / 2, 250)
    }
  }

  render() {
    const { from, to, textFreq, workdays, timestamp, loading, refetch } = this.props
    const { xOffset, zoom, isMobile } = this.state
    const sortedWorkdays = this.getSortedWorkdays(workdays, from, to)
    return (
      <div
        className="container w-full h-[240px] relative overflow-hidden pb-[20px]"
        style={{ cursor: 'grab' }}
        onMouseMove={this.onMouseMove}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onTouchStart={this.onMouseDown}
        onTouchEnd={this.onMouseUp}
        onTouchMove={this.onMouseMove}
        ref={this.setContainer}
      >
        {loading && <FleximeLoader size={30} />}
        <div className="absolute top-[10px] left-[10px]">
          <IconButton
            className="resetZoomButton"
            onClick={() => {
              const containerBounding = this.container?.getBoundingClientRect()
              const newZoom = isMobile ? this.ZOOM_MOB_MIN : this.ZOOM_MIN
              this.currentScroll = containerBounding
                ? (containerBounding.width - containerBounding.width * (newZoom / 100)) / 2
                : 0
              this.setOffsetZoom(this.currentScroll, newZoom)
            }}
          >
            <ResetSearchIcon />
          </IconButton>
          <IconButton
            className="refreshButton"
            onClick={() => {
              refetch()
            }}
          >
            <RefreshIcon />
          </IconButton>
        </div>
        <div className="absolute top-[10px] right-[10px]">
          <IconButton
            className="zoomOutButton"
            onClick={() => {
              const containerBounding: any = this.container?.getBoundingClientRect()
              this.zoomOnPos(
                containerBounding.x + containerBounding.width / 2,
                this.state.zoom - 20
              )
            }}
          >
            <ZoomOutIcon />
          </IconButton>
          <IconButton
            className="zoomInButton"
            onClick={() => {
              const containerBounding: any = this.container?.getBoundingClientRect()
              this.zoomOnPos(
                containerBounding.x + containerBounding.width / 2,
                this.state.zoom + 20
              )
            }}
          >
            <ZoomInIcon />
          </IconButton>
        </div>
        <div
          className="h-[100px] flex absolute bottom-[30px]"
          style={{ transform: `translate3d(${xOffset}px, 0, 0)`, width: `${zoom}%` }}
          ref={this.setInnerContainer}
          onWheel={this.onWheel}
        >
          {this.getTimeline(from, to, textFreq, sortedWorkdays, timestamp, workdays)}
        </div>
        {Object.keys(sortedWorkdays).length === 0 && !loading ? (
          <div className="absolute left-0 top-0 w-full h-[50%] flex-center pointer-events-none z-[-12]">
            <Translation>
              {t => <div className="text-xl text-gray-400">{t('Timeline.noWorkdays')}</div>}
            </Translation>
          </div>
        ) : null}
      </div>
    )
  }
}

export default Timeline
