export function groupArrayBy<T>(
  arr: any[],
  fieldName: string,
  valueConverter?: (value?: any) => string | number
): { [key: string]: T[] } {
  const res: { [key: string]: T[] } = {}
  for (const current of arr) {
    let field = current
    for (const subField of fieldName.split('.')) {
      field = field?.[subField]
    }
    if (valueConverter) {
      field = valueConverter(field)
    }
    if (typeof res[field] === 'undefined') {
      res[field] = []
    }
    res[field].push(current)
  }
  return res
}
