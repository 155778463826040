import gql from 'graphql-tag'
export default gql`
  query usersList(
    $token: String!
    $first: Int
    $after: Int
    $sortOrder: SortOrder
    $orderBy: String
    $filterText: String
  ) {
    viewer(token: $token) {
      id
      allOrganisations {
        id
        name
      }
      usersPaginated(
        first: $first
        after: $after
        sortOrder: $sortOrder
        orderBy: $orderBy
        filterText: $filterText
      ) {
        paginationInfo {
          id
          totalPages
          pageSize
          currentPage
        }
        posts {
          Organisations {
            name
            id
          }
          id
          username
          privilegeLevel
        }
      }
    }
  }
`
