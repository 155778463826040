import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { timestampRuleTemplate_viewer_timestampRulesTemplates_TimestampRules } from 'graphql/queries/types/timestampRuleTemplate'
import {
  TimestampRuleAttestTypeEnum,
  TimestampRuleDurationTypeEnum,
  TimestampRuleRoundingTypeEnum,
  TimestampRuleTypeEnum
} from 'types/graphql-global-types'

const maximumNumberOfRules = 4
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle, selected) => {
  const style = {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // styles we need to apply on draggables
    ...draggableStyle
  }

  if (isDragging) {
    style.opacity = '0.7'
  }
  if (selected) {
    style.opacity = '0.7'
  }

  return style
}

const getListStyle = () => ({
  overflow: 'hidden'
})
export type OnCreateTimestampRule = (
  data: {
    type: TimestampRuleTypeEnum
    sortOrder: number
    roundingType: TimestampRuleRoundingTypeEnum
    roundingTime: number
    ipOrderId: number | undefined
    ipWorkplaceId: number | undefined
    durationType: TimestampRuleDurationTypeEnum
    duration: number
    attestType: TimestampRuleAttestTypeEnum
  }[]
) => Promise<number[] | undefined>

export type OnUpdateTimestampRule = (
  changes: {
    id: number
    sortOrder?: number
    durationType?: TimestampRuleDurationTypeEnum
    duration?: number
    roundingType?: TimestampRuleRoundingTypeEnum
    roundingTime?: number
    attestType?: TimestampRuleAttestTypeEnum
  }[]
) => Promise<number[] | undefined>
interface Props {
  reverse?: boolean
  timestampRules: timestampRuleTemplate_viewer_timestampRulesTemplates_TimestampRules[] | null
  type: TimestampRuleTypeEnum
  editDisabled?: boolean
  onCreate?: OnCreateTimestampRule
  onUpdate?: OnUpdateTimestampRule
}
const ScheduleSection: React.FC<Props> = ({
  reverse = false,
  timestampRules,
  type,
  editDisabled,
  onCreate,
  onUpdate
}) => {
  const location = useLocation()
  const history = useHistory()
  const searchString = new URLSearchParams(location.search)
  const ipOrderId = Number(searchString.get('selectedOrder'))
  const ipWorkplaceId = Number(searchString.get('selectedWorkplace'))
  const selectedTimestampRuleId = editDisabled
    ? undefined
    : Number(searchString.get('selectedTimestampRule'))
  const { t } = useTranslation()
  const handleSelectTimestampRule = (timestampRuleId: number | undefined | null) => () => {
    if (!timestampRuleId) {
      return
    }
    searchString.set('selectedTimestampRule', String(timestampRuleId))
    history.replace({
      search: searchString?.toString()
    })
  }

  const sortedRules = timestampRules?.sort((a, b) => {
    if (a === null || a?.sortOrder === undefined || a?.sortOrder === null) {
      return 0
    }
    if (b === null || b?.sortOrder === undefined || b?.sortOrder === null) {
      return 0
    }
    if (reverse) {
      return a?.sortOrder < b?.sortOrder ? 1 : -1
    } else {
      return a.sortOrder < b.sortOrder ? -1 : 1
    }
  })

  const handleCreateTimestampRule = async () => {
    if (onCreate) {
      const createdIds = await onCreate([
        {
          type,
          sortOrder: sortedRules?.length ?? 0,
          roundingType: TimestampRuleRoundingTypeEnum.None,
          roundingTime: 0,
          ipOrderId: ipOrderId !== 0 ? ipOrderId : undefined,
          ipWorkplaceId: ipWorkplaceId !== 0 ? ipWorkplaceId : undefined,
          durationType: TimestampRuleDurationTypeEnum.Time,
          duration: 900000,
          attestType: TimestampRuleAttestTypeEnum.None
        }
      ])
      const timestampRuleId = createdIds?.[0]
      if (typeof timestampRuleId === 'number') {
        handleSelectTimestampRule(timestampRuleId)()
      }
    }
  }
  const onDragEnd = async result => {
    if (!result.source || !result.destination) {
      return
    }
    const source = result.source.index
    const destination = result.destination.index
    if (source === destination) {
      return
    }

    const reorderedRules: any = reorder(sortedRules, source, destination)
    const changes: any[] = []
    for (let i = 0; i < reorderedRules.length; i++) {
      changes.push({
        id: reorderedRules[i].id,
        sortOrder: reverse ? reorderedRules.length - i - 1 : i
      })
    }
    onUpdate?.(changes)
  }
  const fillOuts: JSX.Element[] = []
  for (let i = 0; i < maximumNumberOfRules - (sortedRules?.length ?? 0); i++) {
    const coversRest = sortedRules?.find(
      rule => rule?.durationType === TimestampRuleDurationTypeEnum.Rest
    )
    if (i === 0 && !coversRest && !editDisabled) {
      fillOuts.push(
        <button onClick={handleCreateTimestampRule} key={i} className="w-full">
          <div className="mb-1 p-1 text-black font-semibold border-dashed border-t-2 bg-white">
            +
          </div>
        </button>
      )
    } else {
      fillOuts.push(
        <div
          key={i}
          className={`mb-1 p-1 text-white font-semibold ${
            coversRest ? 'bg-gray-100' : 'border-dashed border-t-2 bg-white'
          }  `}
        >
          &nbsp;&nbsp;
        </div>
      )
    }
  }
  return (
    <div className="px-4">
      <DragDropContext onDragEnd={onDragEnd}>
        {reverse && fillOuts.reverse()}
        <Droppable droppableId="droppable" isDropDisabled={false}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
              {sortedRules?.map((item, index) => (
                <Draggable
                  key={item?.id}
                  draggableId={String(item?.id)}
                  index={index}
                  isDragDisabled={
                    item?.durationType === TimestampRuleDurationTypeEnum.Rest || editDisabled
                  }
                >
                  {(provided, snapshot) => (
                    <div
                      onClick={handleSelectTimestampRule(item?.id)}
                      className="mb-1 p-1 w-full text-white font-semibold text-center bg-indigo-800"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                        selectedTimestampRuleId === item?.id
                      )}
                    >
                      {item?.durationType === TimestampRuleDurationTypeEnum.Time
                        ? `${(item?.duration ?? 0) / 1000 / 60} min`
                        : t('Schedule.restOfWorkday')}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        {!reverse && fillOuts}
      </DragDropContext>
    </div>
  )
}
export default ScheduleSection
