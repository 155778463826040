import gql from 'graphql-tag' 
export default gql`
query queryTerminalWorkplacesOrders($token: String!, $terminalId: Int!) {
  viewer(token: $token) {
    id
    terminal(id: $terminalId) {
      id
      orders {
        ipOrderId
      }
      ipWorkplaces {
        id
      }
    }
  }
}
`

