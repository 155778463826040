import { getLocalStorageToken } from 'store/localStorage'
import * as React from 'react'
import logo from 'assets/icon.svg'
import hamburger from './assets/hamburger.svg'
import { Link } from 'react-router-dom'
import UserAvatar from 'components/UserAvatar/UserAvatar'
import userQuery from 'graphql/queries/viewerQuery'
import { SidebarMenuContext } from 'providers/SidebarMenuProvider'
import SearchBar from 'containers/SearchBar/SearchBar'
import { WhatsNewDialog } from 'components/WhatsNewDialog/WhatsNewDialog'
import { useQuery } from '@apollo/client'
import { viewerQuery, viewerQueryVariables } from 'graphql/queries/types/viewerQuery'

const Header: React.FC = () => {
  const { data } = useQuery<viewerQuery, viewerQueryVariables>(userQuery, {
    variables: { token: getLocalStorageToken() }
  })
  const username = data && data.viewer ? data.viewer.username : ''
  return (
    <div className="w-full h-[75px] fixed top-0 left-0 bg-white flex z-[903] border-b border-[#edf2f7]">
      <Link to="/" className="hidden sm:block">
        <div className="w-[76px] h-[75px] border-r border-[#edf2f7] content-center flex-center cursor-pointer">
          <img alt="Logo" src={logo} className="w-8 h-8 sm:w-[42px] sm:h-[42px]" />
        </div>
      </Link>

      <SidebarMenuContext.Consumer>
        {p => {
          const { hidden, actions } = p ?? {}
          const { openMenu, closeMenu } = actions ?? {}
          return (
            <div
              className="block sm:hidden"
              onClick={() => {
                hidden ? openMenu?.() : closeMenu?.()
              }}
            >
              <div className="w-[76px] h-[75px] border-r border-[#edf2f7] content-center flex-center cursor-pointer">
                <img
                  alt="Logo"
                  src={hidden ? hamburger : logo}
                  className="w-8 h-8 sm:w-[42px] sm:h-[42px]"
                />
              </div>
            </div>
          )
        }}
      </SidebarMenuContext.Consumer>

      <div className="flex-grow flex items-center">
        <div className="flex-grow">
          <SearchBar />
        </div>
        <WhatsNewDialog />
        <div className="py-0 px-8 flex items-center border-l border-[#E6E6E6] h-full">
          <UserAvatar username={username ?? ''} />
        </div>
      </div>
    </div>
  )
}

export default Header
