import * as React from 'react'
import get from 'lodash.get'
import moment from 'moment-timezone'
import HomeIcon from '@material-ui/icons/Home'
import NetworkProblemIcon from '@material-ui/icons/SignalWifiOff'
import NotificationButtons from './NotificationButtons'
import UnexcpetedErrorIcon from '@material-ui/icons/Error'
import UnexpectedTimestampIcon from '@material-ui/icons/AccessTime'
import UnregisteredCardIcon from '@material-ui/icons/CreditCard'
import NrOfTimestampsIcon from '@material-ui/icons/Input'
import TooLongAbsenceIcon from '@material-ui/icons/FreeBreakfast'
import LockIcon from '@material-ui/icons/Lock'
import VirtualIcon from '@material-ui/icons/PhoneIphone'
import UnexpectedTimestampContainer from 'containers/UnexpectedTimestampContainer/UnexpectedTimestampContainer'
import { useMutation, useQuery } from '@apollo/client'
import ListItem from './ListItem'
import mutationRemoveNotification from 'graphql/mutations/mutationRemoveNotification'
import mutationUpdateTimestamp from 'graphql/mutations/mutationUpdateTimestamp'
import { getLocalStorageToken } from 'store/localStorage'
import { Trans, useTranslation } from 'react-i18next'

import queryAbsenceCodes from 'graphql/queries/queryAbsenceCodes'
import toast from 'react-hot-toast'
import { NotificationTypeEnum, TimestampDirectionEnum } from 'types/graphql-global-types'
import { notificationGroup_viewer_notificationGroup_notifications } from 'graphql/queries/types/notificationGroup'
import { absenceCodes, absenceCodesVariables } from 'graphql/queries/types/absenceCodes'
import {
  removeNotification,
  removeNotificationVariables
} from 'graphql/mutations/types/removeNotification'
import { updateTimestamp, updateTimestampVariables } from 'graphql/mutations/types/updateTimestamp'
import { getFormattedDateString } from 'utils/getFormattedDateString'

export interface Props {
  notification: notificationGroup_viewer_notificationGroup_notifications | null
  openPersonTimestampsDialog: (ipPerson: any) => void
  animationDelay?: number
  hideLabel?: boolean
  hideLeft?: boolean
  animate?: boolean
}
export interface State {}

const NotificationListItem: React.FC<Props> = props => {
  const openTerminal = (terminalId: number) => () =>
    window?.open(`/terminals/${terminalId}`, '_blank')?.focus()

  const getDirectionText = (direction: TimestampDirectionEnum, t: (key: string) => string) => {
    switch (direction) {
      case TimestampDirectionEnum.In:
        return t('general.in').toLowerCase()
      case TimestampDirectionEnum.Out:
        return t('general.out').toLowerCase()
      case TimestampDirectionEnum.OutAbsence:
        return t('general.outAbsence').toLowerCase()
      default:
        return ''
    }
  }
  const { data: absenceCodesData } = useQuery<absenceCodes, absenceCodesVariables>(
    queryAbsenceCodes,
    { variables: { token: getLocalStorageToken() } }
  )
  const [removeNotificationMutation] = useMutation<removeNotification, removeNotificationVariables>(
    mutationRemoveNotification,
    {
      refetchQueries: [
        'notificationGroups',
        'notificationGroup',
        'notificationsList',
        'notificationsPaginated'
      ]
    }
  )
  const [updateTimestampMutation] = useMutation<updateTimestamp, updateTimestampVariables>(
    mutationUpdateTimestamp
  )
  const { t } = useTranslation()

  const {
    notification,
    openPersonTimestampsDialog,
    animationDelay,
    hideLabel = false,
    hideLeft = false,
    animate = true
  } = props

  const absenceCodesMapping = (absenceCodesData?.viewer?.ipAbsenceCodes ?? []).reduce(
    (all, next) => {
      return { ...all, [next.id]: next.name }
    },
    {}
  )

  const notificationsInfo = (
    notification,
    handleRemove,
    handleUpdateTimestamp,
    openPersonTimestampsDialog
  ) => {
    let Icon: any = HomeIcon
    let label = ''
    let body: JSX.Element | string = ''
    let lowerBody: JSX.Element | string | undefined = undefined
    let expandedBody: string | JSX.Element = ''
    let colorClass = ''
    const person = get(notification, 'timestamp.ipPerson', null)
    const rfid = `RFID: ${get(notification, 'timestamp.rfid', '')}`
    const direction = getDirectionText(get(notification, 'timestamp.direction'), t)
    const timeText = moment(get(notification, 'timestamp.timeStamped', Date.now()))
      .tz('Europe/Stockholm')
      .format('DD/MM @ HH:mm')
    const personText = person
      ? `${person.firstName ?? ''} ${person.surName ?? ''} (${person.id})`
      : rfid
    const terminalDescription = get(notification, 'terminal.description', 'okänd plats')
    const absenceType = notification?.timestamp?.absenceType ?? undefined
    body = (
      <Trans>
        {{ personText }} stämplade {{ direction }}
        {typeof absenceType != 'undefined'
          ? ` (${absenceCodesMapping[absenceType] ?? absenceType}) `
          : ''}{' '}
        på{' '}
        <span className="underline cursor-pointer" onClick={openTerminal(notification.terminal.id)}>
          {{ terminalDescription }}
        </span>{' '}
        {{ timeText }}
      </Trans>
    )
    const tryAgainText = (
      <>
        {t('NotificationListItem.tryAgain')} - {body}
      </>
    )

    if (
      typeof notification?.timestamp?.sendAttempts !== 'undefined' &&
      typeof notification?.timestamp?.timeSend !== 'undefined'
    ) {
      const sendAttempts = notification?.timestamp?.sendAttempts
      const formattedTimeSend = getFormattedDateString(notification?.timestamp?.timeSend)
      lowerBody = (
        <div className="text-xs text-gray-400">
          {sendAttempts > 0
            ? t('NotificationListItem.tryingToResend', {
                timeSend: formattedTimeSend
              })
            : t('NotificationListItem.failedResending')}
        </div>
      )
    }
    const buttons = (
      <NotificationButtons
        handleRemove={handleRemove}
        handleUpdateTimestamp={handleUpdateTimestamp}
        notification={notification}
        openPersonTimestampsDialog={openPersonTimestampsDialog}
      />
    )
    switch (notification.type) {
      case NotificationTypeEnum.NetworkError:
        Icon = NetworkProblemIcon
        label = t('NotificationListItem.networkProblem')
        body = tryAgainText
        colorClass = '!bg-blue-500'
        break
      case NotificationTypeEnum.WorkdayNotFound:
      case NotificationTypeEnum.TerminalMissingOrderOrWorkplace:
      case NotificationTypeEnum.TerminalMissingRules:
        Icon = UnexpectedTimestampIcon
        label = t('NotificationListItem.unScheduled')
        expandedBody = (
          <UnexpectedTimestampContainer
            ipPersonId={person ? person.id : null}
            hoursBefore={12}
            hoursAfter={12}
            notification={notification}
          />
        )
        colorClass = '!bg-purple-500'
        break
      case NotificationTypeEnum.UnknownError:
        Icon = UnexcpetedErrorIcon
        label = t('NotificationListItem.unKnownError')
        body = tryAgainText
        colorClass = '!bg-secondary-500'
        break
      case NotificationTypeEnum.RfidNotFound:
        Icon = UnregisteredCardIcon
        label = t('NotificationListItem.unregisteredCard')
        colorClass = '!bg-yellow-500'
        break
      case NotificationTypeEnum.IncorrectNrOfTimestamps:
        Icon = NrOfTimestampsIcon
        label = t('NotificationListItem.incorrectNrOfTimestamps')
        colorClass = '!bg-green-400'
        break
      case NotificationTypeEnum.IncorrectAbsence:
        Icon = TooLongAbsenceIcon
        label = t('NotificationListItem.tooLongAbsence')
        colorClass = '!bg-pink-400'
        break
      case NotificationTypeEnum.WorkdayAlreadyAttested:
        Icon = LockIcon
        label = t('NotificationListItem.workdayAlreadyAttested')
        colorClass = '!bg-red-400'
        expandedBody = (
          <UnexpectedTimestampContainer
            ipPersonId={person ? person.id : null}
            hoursBefore={12}
            hoursAfter={12}
            notification={notification}
          />
        )
        break
      case NotificationTypeEnum.PersonMissingInVirtualTimestamp:
        Icon = VirtualIcon
        label = t('NotificationListItem.personMissingInVirtualTimestamp')
        body = tryAgainText
        colorClass = '!bg-secondary-400'
        break
      default:
    }
    return {
      icon: (
        <div className={`w-[34px] h-[34px] flex-center rounded-md ${colorClass}`}>
          <Icon className="!text-white !w-6 !h-6" />
        </div>
      ),
      label,
      body,
      lowerBody,
      expandedBody,
      buttons
    }
  }

  const handleRemove = async (id: number) => {
    try {
      await removeNotificationMutation({
        variables: {
          token: getLocalStorageToken(),
          id,
          updateTimestampTimeSent: true
        }
      })
      toast.success((t('NotificationListItem.notificationRemoved') as string) ?? '')
    } catch (error) {
      toast.error((t('NotificationListItem.notificationRemoveFailed') as string) ?? '')
    }
  }
  const handleUpdateTimestamp = async (id: number) => {
    try {
      await updateTimestampMutation({
        variables: {
          token: getLocalStorageToken(),
          id,
          timeSend: moment().valueOf(),
          sendAttempts: 5
        }
      })
      toast.success((t('NotificationListItem.notificationResend') as string) ?? '')
    } catch (error) {
      toast.error((t('NotificationListItem.notificationResendFailed') as string) ?? '')
    }
  }

  const { icon, label, body, lowerBody, expandedBody, buttons } = notificationsInfo(
    notification,
    handleRemove,
    handleUpdateTimestamp,
    openPersonTimestampsDialog
  )
  return (
    <ListItem
      key={notification?.id}
      animate={animate}
      animationDelay={animationDelay}
      left={hideLeft ? null : icon}
      label={hideLabel ? null : label}
      body={body}
      expandedBody={expandedBody}
      right={buttons}
      lowerBody={lowerBody}
    />
  )
}

export default NotificationListItem
