import gql from 'graphql-tag'
export const queryIpArticlesSearch = gql`
  query IpArticlesSearch($token: String!, $limit: Int!, $filterText: String) {
    viewer(token: $token) {
      ipArticlesSearch(limit: $limit, filterText: $filterText) {
        id
        name
      }
    }
  }
`
