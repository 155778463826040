import gql from 'graphql-tag'
export default gql`
  query queryPersonWorkdays($token: String!, $from: Date!, $to: Date!, $ipPersonId: Int!) {
    viewer(token: $token) {
      id
      ipWorkdays(ipPersonId: $ipPersonId, dateFrom: $from, dateTo: $to) {
        id
        originalDateFrom
        originalDateTo
        dateFrom
        dateTo
        punchInDateFrom
        punchOutDateTo
        isBossConfirmed
        ipOrder {
          id
          workplace {
            id
            workPlaceName
            timestampRules {
              id
              type
              durationType
              duration
              roundingType
              roundingTime
              attestType
              sortOrder
            }
          }
          staffingService {
            name
          }
          timestampRules {
            id
            type
            durationType
            duration
            roundingType
            roundingTime
            attestType
            sortOrder
          }
        }
      }
    }
  }
`
