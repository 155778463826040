import React, { DetailedHTMLProps, FC, useMemo } from 'react'

export interface ButtonProps
  extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  color?: 'indigo' | 'red' | 'green'
  variant?: 'primary' | 'secondary' | 'third'
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl'
  className?: string
  loading?: boolean
}

const Button: FC<ButtonProps> = ({
  children,
  size = 'base',
  variant = 'primary',
  loading,
  color = 'indigo',
  className,
  ...rest
}) => {
  const baseClass = ` font-medium border border-transparent focus:outline-none transition ease-in-out duration-150 inline${
    rest.disabled ? ' cursor-default' : ''
  }`

  const variantClass = useMemo(() => {
    if (variant === 'primary') {
      let className = 'shadow-sm text-white '

      if (color === 'indigo') {
        className += `bg-indigo-600 focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 ${
          !rest.disabled ? 'hover:bg-indigo-500' : ''
        }`
      } else if (color === 'red') {
        className += `bg-red-600 focus:border-red-500 focus:ring-red active:bg-red-700 ${
          !rest.disabled ? 'hover:bg-red-500' : ''
        }`
      } else if (color === 'green') {
        className += `bg-green-500 focus:border-green-500 focus:ring-green active:bg-green-700 ${
          !rest.disabled ? 'hover:bg-green-500' : ''
        }`
      }

      return className
    }

    if (variant === 'secondary') {
      if (color === 'indigo') {
        return `text-indigo-700 bg-indigo-100 ${
          !rest.disabled ? 'hover:bg-indigo-50' : ''
        } focus:ring-indigo active:bg-indigo-200`
      } else if (color === 'red') {
        return `border-red-200 border text-red-700 bg-red-100 ${
          !rest.disabled ? 'hover:bg-red-50' : ''
        } focus:ring-red active:bg-red-200`
      } else if (color === 'green') {
        return `border-green-300 border text-green-700 bg-green-200 ${
          !rest.disabled ? 'hover:bg-green-100' : ''
        } focus:ring-green active:bg-green-200`
      }
    }

    if (variant === 'third') {
      return `border border-gray-300 text-gray-600 ${
        !rest.disabled ? 'hover:bg-gray-200' : ''
      } focus:outline-none focus:border-primary-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150`
    }
  }, [variant, color, rest.disabled])

  const sizeClass = useMemo(() => {
    if (size === 'xs') {
      return 'px-2.5 p-1 text-xs leading-4 rounded-md'
    }

    if (size === 'sm') {
      return 'px-2.5 py-1.5 text-xs leading-5 rounded-md'
    }

    if (size === 'base' || size == null) {
      return 'px-3 py-1.5 text-sm leading-5 rounded-md'
    }

    if (size === 'lg') {
      return 'px-4 py-2 text-base leading-6 rounded-md'
    }

    if (size === 'xl') {
      return 'px-6 py-3 text-base leading-6 rounded-md'
    }
  }, [size])

  return (
    <button
      className={`${className} ${baseClass} ${variantClass} ${sizeClass} ${rest.disabled &&
        'opacity-50 cursor-not-allowed'}`}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
