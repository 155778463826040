import { useEffect } from 'react'
import { Location } from 'history'
import { useLocation, withRouter } from 'react-router'

interface Props {
  location: Location
  children?: any
}

let lastPathName = ''

function ScrollToTop(props: Props) {
  const location = useLocation()
  useEffect(() => {
    const elem = document.getElementById('core-layout')
    if (
      elem &&
      elem.scrollTo &&
      typeof elem.scrollTo === 'function' &&
      location.pathname !== lastPathName
    ) {
      elem.scrollTo(0, 0)
      lastPathName = location.pathname
    }
  }, [location])
  return props.children
}

export default withRouter(ScrollToTop)
