import * as React from 'react'
import { IIpWorkplace, ITimestampRule } from 'models/interfaces'
import { Translation } from 'react-i18next'
import {
  TimestampRuleAttestTypeEnum,
  TimestampRuleDurationTypeEnum,
  TimestampRuleRoundingTypeEnum,
  TimestampRuleTypeEnum
} from 'types/graphql-global-types'

export interface INewIpOrder {
  id: number
  timestampRules: INewTimestampRule[]
  orderType: string
  dateFrom: string
  description: string
  workplace: IIpWorkplace
}

export interface INewTimestampRule {
  id: number
  attestType: number
  duration: number
  durationType: number
  type: number
  roundingType: number
  roundingTime: number
  sortOrder: number
  temporary?: boolean
  ruleStartTime: number
}
export interface Props {
  onLeft: boolean
  timestampRule: ITimestampRule
  offset?: number
}
export interface State {}

class WorkdayRuleHover extends React.Component<Props, State> {
  private getText = (timestampRule: ITimestampRule, t: (string) => string) => {
    const durationInMin = timestampRule.duration / (1000 * 60)
    const text = {
      duration: '',
      rounding: '',
      attest: `${
        timestampRule.attestType === TimestampRuleAttestTypeEnum.None
          ? t('WorkdayRuleHover.not_attested')
          : t('WorkdayRuleHover.attested')
      }`
    }
    switch (timestampRule.roundingType) {
      case TimestampRuleRoundingTypeEnum.None:
        text.rounding = t('WorkdayRuleHover.not_rounded')
        break
      case TimestampRuleRoundingTypeEnum.OriginalDate:
        text.rounding = `${t('WorkdayRuleHover.rounded_original_date')} ${
          timestampRule.type === TimestampRuleTypeEnum.InBeforeStart
            ? t('WorkdayRuleHover.rounded_original_date.start')
            : t('WorkdayRuleHover.rounded_original_date.end')
        }`
        break
      case TimestampRuleRoundingTypeEnum.ToMinutes:
        text.rounding = t('WorkdayRuleHover.rounded_minute')
        break
      default:
        break
    }
    if (timestampRule.durationType === TimestampRuleDurationTypeEnum.Rest) {
      switch (timestampRule.type) {
        case TimestampRuleTypeEnum.InAfterStart:
          text.duration = t('general.duration_until_end')
          break
        case TimestampRuleTypeEnum.OutBeforeEnd:
          text.duration = t('general.duration_until_start')
          break
        default:
          text.duration = '-'
          break
      }
    } else {
      text.duration = `${durationInMin}min`
    }
    return text
  }
  private getHoverOffset = (containerWidth: number, timestampRule: ITimestampRule) => {
    if (
      timestampRule.type === TimestampRuleTypeEnum.InBeforeStart ||
      timestampRule.type === TimestampRuleTypeEnum.OutAfterEnd
    ) {
      // Outer rules
      return -containerWidth / 2 + 15
    } else {
      // Inner rules, their offset needs to be relative the WorkdayRule
      return `calc(${this.props.offset}% - ${containerWidth / 2}px)`
    }
  }
  render() {
    const { timestampRule } = this.props
    // Offset is half of the width
    return (
      <div className="flex-1 text-white text-xs text-center p-[3px] select-none">
        <Translation>
          {t => {
            const text = this.getText(timestampRule, t)
            return (
              <>
                <p className="m-[5px]">{text.duration}</p>
                <p className="m-[5px]">{text.rounding}</p>
                <p className="m-[5px]">{text.attest}</p>
              </>
            )
          }}
        </Translation>
      </div>
    )
  }
}

export default WorkdayRuleHover
