import AdminAddUser from 'components/AdminAddUser/AdminAddUser'
import AdminUserList from 'components/AdminUserList/AdminUserList'
import DashboardCard from 'components/DashboardCard/DashboardCard'
import { SearchBarContainer } from 'components/SearchBar/containers/SearchBarContainer'
import { ADMIN_USERS_ID, IAdminUsersProps } from 'containers/AdminUsers/AdminUsersContainer'
import { useCustomerSettings } from 'hooks/useCustomerSettings'
import * as React from 'react'
import { Translation } from 'react-i18next'

const AdminUsers: React.FC<IAdminUsersProps> = props => {
  const {
    removeUser,
    createUser,
    error,
    handleSubmit = () => {},
    createUserStatus,
    updatePrivilegeLevel,
    updateOrganisationId,
    searchText
  } = props
  const customerSettings = useCustomerSettings()
  return (
    <React.Fragment>
      <Translation>
        {t => (
          <DashboardCard style={{ display: 'block' }}>
            {customerSettings.emailLogin && (
              <div className="flex flex-col mb-6">
                <span className="mb-4">{t('AdminAddUser.addUser')}</span>
                <AdminAddUser
                  createUserStatus={createUserStatus}
                  error={error}
                  handleSubmit={handleSubmit}
                  createUser={createUser}
                />
              </div>
            )}
            {t('AdminUsers.handleUsers')}
            <SearchBarContainer
              searchBarId={ADMIN_USERS_ID}
              searchType="text"
              hintText={t('AdminUsers.search')}
            />
            <AdminUserList
              updatePrivilegeLevel={updatePrivilegeLevel}
              removeUser={removeUser}
              updateOrganisationId={updateOrganisationId}
              searchText={searchText}
            />
          </DashboardCard>
        )}
      </Translation>
    </React.Fragment>
  )
}
export default AdminUsers
