import { useQuery } from '@apollo/client'
import { useOrganisations } from 'hooks/useOrganisations'
import React, { useState } from 'react'
import { getLocalStorageToken } from 'store/localStorage'
import { NotificationTypeEnum } from 'types/graphql-global-types'
import {
  notificationsPaginated,
  notificationsPaginatedVariables
} from '../graphql/queries/types/notificationsPaginated'
import { notificationsPaginatedQuery } from '../graphql/queries/queryNotificationsPaginated'
import NotificationListItem from 'components/Notifications/NotificationListItem'
import ActionPanel from 'base-components/ActionPanel'
import { useTranslation } from 'react-i18next'
import TablePageNumbering from 'components/Table/components/TablePageNumbering/TablePageNumbering'

interface Props {
  dateFrom: Date
  dateTo: Date
}

export const NotificationsPersonMissingVirtual: React.FC<Props> = ({ dateFrom, dateTo }) => {
  const { t } = useTranslation()
  const { excludeOrganisationIds } = useOrganisations()
  const after = 10
  const [first, setFirst] = useState<number>(0)

  const { data: notificationsData } = useQuery<
    notificationsPaginated,
    notificationsPaginatedVariables
  >(notificationsPaginatedQuery, {
    variables: {
      token: getLocalStorageToken(),
      excludeOrganisationIds,
      first,
      after,
      dateFrom: dateFrom.valueOf(),
      dateTo: dateTo.valueOf(),
      type: NotificationTypeEnum.PersonMissingInVirtualTimestamp,
      orderBy: 'createdAt',
      sortOrder: 'DESC'
    }
  })

  const currentPage = notificationsData?.viewer?.notificationsPaginated?.paginationInfo?.currentPage
  const nrOfPages = notificationsData?.viewer?.notificationsPaginated?.paginationInfo?.totalPages

  return (
    <div className="mt-4">
      <ActionPanel
        title={t('NewNotifications.titles.' + NotificationTypeEnum.PersonMissingInVirtualTimestamp)}
        content={
          <div>
            {(notificationsData?.viewer?.notificationsPaginated?.posts ?? []).map(n => (
              <NotificationListItem
                key={n.id}
                notification={(n as unknown) as any}
                openPersonTimestampsDialog={() => undefined}
              />
            ))}
            <div className="mt-4">
              <TablePageNumbering
                currentPage={currentPage != null ? currentPage - 1 : 0}
                nrOfPages={nrOfPages ?? 1}
                onPageClick={(pageNr: number) => {
                  setFirst((pageNr - 1) * after)
                }}
                hideGoToLast={false}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}
