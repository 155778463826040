import * as React from 'react'
import mutationCreateOrganisation from 'graphql/mutations/mutationCreateOrganisation'
import { getLocalStorageToken } from 'store/localStorage'
import TextInput from 'components/TextInput/TextInput'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  createOrganisation,
  createOrganisationVariables
} from 'graphql/mutations/types/createOrganisation'
export interface Props {
  error: string
  createOrganisationStatus: { success: boolean; message: string }
}
export interface State {
  name: string
}

const AddOrganisation: React.FC<Props> = ({ createOrganisationStatus, error }) => {
  const [name, setName] = useState('')
  const { t } = useTranslation()
  const [createOrganisationMutation] = useMutation<createOrganisation, createOrganisationVariables>(
    mutationCreateOrganisation,
    { refetchQueries: ['organisationsList', 'adminTerminalsList', 'ipOrders'] }
  )

  const createOrganisation = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!name) {
      toast.error((t('AddOrganisation.missingOrganisationName') as string) ?? '')
      return
    }

    try {
      await createOrganisationMutation({
        variables: {
          token: getLocalStorageToken(),
          name
        }
      })
      toast.success((t('AddOrganisation.organisationCreated') as string) ?? '')
      setName('')
    } catch (error) {
      toast.error((t('AddOrganisation.organisationCreatedFailed') as string) ?? '')
    }
  }
  return (
    <>
      <form onSubmit={createOrganisation}>
        <div className="flex flex-row">
          <div className="max-w-lg">
            <TextInput
              placeholder={t('general.name')}
              name="password"
              handleChange={(text: string) => setName(text)}
              text={name}
              width="100%"
            />
          </div>
          <div className="flex justify-start mt-4 sm:mt-0">
            <Button variant="outlined" disabled={!name} type="submit">
              {t('general.add')}
            </Button>
          </div>
        </div>
      </form>
      {createOrganisationStatus && createOrganisationStatus.success && (
        <div className="p-[10px] text-[13px] text-green-500">
          {createOrganisationStatus.message}
        </div>
      )}
    </>
  )
}

export default AddOrganisation
