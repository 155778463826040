import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import makeRootReducer from './reducers'

declare const module

const createState = (initialState = {}) => {
  const middleware = [thunk]

  const enhancers = []

  const _compose: any = compose
  const store: any = createStore(
    makeRootReducer({}),
    initialState,
    _compose(applyMiddleware(...middleware), ...enhancers)
  )
  store.asyncReducers = {}

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}
export default createState
