import DashboardCard from 'components/DashboardCard/DashboardCard'
import { timestampRuleTemplate_viewer_timestampRulesTemplates_TimestampRules } from 'graphql/queries/types/timestampRuleTemplate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TimestampRuleTypeEnum } from 'types/graphql-global-types'
import ScheduleSection, { OnCreateTimestampRule, OnUpdateTimestampRule } from './ScheduleSection'

interface Props {
  timestampRules: timestampRuleTemplate_viewer_timestampRulesTemplates_TimestampRules[] | null
  editDisabled?: boolean
  onCreate?: OnCreateTimestampRule
  onUpdate?: OnUpdateTimestampRule
}
const Schedule: React.FC<Props> = ({
  timestampRules,
  children,
  editDisabled,
  onCreate,
  onUpdate
}) => {
  const { t } = useTranslation()
  const filterTimestampRules = (type: TimestampRuleTypeEnum) => {
    if (!timestampRules) {
      return []
    }
    return timestampRules?.filter(timestampRule => type === timestampRule?.type)
  }
  if (!timestampRules) {
    return (
      <div className="flex-1 w-full h-32 border-dashed border-2 pt-32 mb- 2 lg:m-2 rounded-lg border-gray-300" />
    )
  }
  return (
    <DashboardCard>
      <div className="pb-4">
        <span className="font-semibold text-xl ">{t('Schedule.forCheckIns')}</span>
      </div>
      <ScheduleSection
        onCreate={onCreate}
        onUpdate={onUpdate}
        editDisabled={editDisabled}
        type={TimestampRuleTypeEnum.InBeforeStart}
        timestampRules={filterTimestampRules(TimestampRuleTypeEnum.InBeforeStart)}
        reverse
      />
      <Divider>{t('Schedule.startOfWorkday')}</Divider>
      <ScheduleSection
        onCreate={onCreate}
        onUpdate={onUpdate}
        editDisabled={editDisabled}
        type={TimestampRuleTypeEnum.InAfterStart}
        timestampRules={filterTimestampRules(TimestampRuleTypeEnum.InAfterStart)}
      />
      <div className="pb-4 pt-6">
        <span className="font-semibold text-xl ">{t('Schedule.forCheckOuts')}</span>
      </div>
      <ScheduleSection
        onCreate={onCreate}
        onUpdate={onUpdate}
        editDisabled={editDisabled}
        type={TimestampRuleTypeEnum.OutBeforeEnd}
        timestampRules={filterTimestampRules(TimestampRuleTypeEnum.OutBeforeEnd)}
        reverse
      />
      <Divider>{t('Schedule.endOfWorkday')}</Divider>
      <ScheduleSection
        onCreate={onCreate}
        onUpdate={onUpdate}
        editDisabled={editDisabled}
        type={TimestampRuleTypeEnum.OutAfterEnd}
        timestampRules={filterTimestampRules(TimestampRuleTypeEnum.OutAfterEnd)}
      />
      {children}
    </DashboardCard>
  )
}
export default Schedule

const Divider: React.FC = ({ children }) => {
  return (
    <div className="relative flex flex-row justify-center w-full mt-2 mb-4 px-4">
      <div
        style={{ height: 1, borderWidth: 1 }}
        className="absolute top-1/2 w-full border-solid border-black"
      ></div>
      <div className="bg-white z-0 px-2">{children}</div>
    </div>
  )
}
