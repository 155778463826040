import { SEARCH_BAR_CHANGED, SEARCH_BAR_SEARCH } from './searchBarReducer'
import { AppState } from 'models/AppState'
import { ISearchBarContainer } from './SearchBarContainer'

export interface ISearchBarConnectedState {
  searchBarCurrentText: string
  searchBarSearchText: string
}

export interface ISearchBarConnectedDispatch {
  searchBarChanged: (searchText: string) => any
  searchBarSearch: (searchText: string) => any
}

export const searchBarMapStateToProps = (
  state: AppState,
  ownProps: ISearchBarContainer
): ISearchBarConnectedState => ({
  searchBarCurrentText: state.searchBar.get(ownProps.searchBarId).get('searchBarCurrentText'),
  searchBarSearchText: state.searchBar.get(ownProps.searchBarId).get('searchBarSearchText')
})

export const searchBarMapDispatchToProps = (
  dispatch: (action: any) => void,
  ownProps: ISearchBarContainer
): ISearchBarConnectedDispatch => ({
  searchBarChanged: (searchText: string) =>
    dispatch({
      type: SEARCH_BAR_CHANGED,
      payload: { id: ownProps.searchBarId, value: searchText }
    }),
  searchBarSearch: (searchText: string) =>
    dispatch({ type: SEARCH_BAR_SEARCH, payload: { id: ownProps.searchBarId, value: searchText } })
})
