import { ApolloProvider as ApolloHooksProvider, useMutation, useQuery } from '@apollo/client'
import { userSettingsReducer } from 'components/UserSettings/containers/UserSettingsContainer'
import { adminTerminalsReducer } from 'containers/AdminTerminals/AdminTerminalsReducer'
import * as jwtDecode from 'jwt-decode'
import appLayoutReducer, {
  AppLayoutContainer
} from 'layouts/AppLayout/containers/AppLayoutContainer'
import { CoreLayout } from 'layouts/CoreLayout/CoreLayout'
import * as React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import { getLocalStorageToken, setLocalStorage } from 'store/localStorage'
import { injectReducer } from 'store/reducers'
import mutationRefreshToken from '../graphql/mutations/mutationRefreshToken'
import { organisationsReducer } from './AdminOrganisations/organisationsReducer'
import { adminUsersReducer } from './AdminUsers/adminUsersReducer'
import LoginContainer from './Login/LoginContainer'
import { loginReducer, LOGIN_REMOVE_TOKEN } from './Login/loginReducer'
import notificationsReducer from './Notifications/notificationsReducer'
import { ipOrderRuleWizardReducer } from './RuleNewWizardContainer/RuleNewWizardReducer'
import queryCustomerSettings from 'graphql/queries/queryCustomerSettings'
import { useCallback, useEffect, useState } from 'react'
import { CustomerSettings } from 'graphql/queries/types/CustomerSettings'
import { FleximeLoader } from 'components/FleximeLoader/FleximeLoader'
import {
  refreshToken as refreshTokenTypes,
  refreshTokenVariables
} from 'graphql/mutations/types/refreshToken'

class AppContainerWrapper extends React.Component<any, any> {
  constructor(props: any) {
    super(props)

    injectReducer(props.store, { key: 'notifications', reducer: notificationsReducer })
    injectReducer(props.store, { key: 'ipOrderRuleWizard', reducer: ipOrderRuleWizardReducer })
    injectReducer(props.store, { key: 'admin', reducer: adminUsersReducer })
    injectReducer(props.store, { key: 'organisations', reducer: organisationsReducer })
    injectReducer(props.store, { key: 'adminTerminals', reducer: adminTerminalsReducer })
    injectReducer(props.store, { key: 'appLayout', reducer: appLayoutReducer })
    injectReducer(props.store, { key: 'userSettings', reducer: userSettingsReducer })
    injectReducer(props.store, { key: 'login', reducer: loginReducer })
  }

  render() {
    return (
      <ApolloHooksProvider client={this.props.client}>
        <div style={{ height: '100vh' }}>
          <BrowserRouter>
            <AppContainer logout={this.props.logout} />
          </BrowserRouter>
        </div>
      </ApolloHooksProvider>
    )
  }
}
const mapStateToProps = (state: any) => ({
  token: state.login && state.login.get('token') ? state.login.get('token') : null,
  t: state.login
})
const logout = () => ({ type: LOGIN_REMOVE_TOKEN })
export default connect<any, any, any>(mapStateToProps, {
  logout
})(AppContainerWrapper)
interface Props {
  logout: () => void
}

const AppContainer: React.FC<Props> = ({ logout }) => {
  const [loading, setLoading] = useState(true)
  const logoutCheck = useCallback(() => {
    try {
      const token = jwtDecode(getLocalStorageToken())
      if (token.exp * 1000 < new Date().valueOf()) {
        logout()
      }
    } catch {
      logout()
    }
  }, [logout])
  useEffect(() => {
    logoutCheck()
  }, [logoutCheck])
  const { data: queryData } = useQuery<CustomerSettings>(queryCustomerSettings)
  const [refreshToken] = useMutation<refreshTokenTypes, refreshTokenVariables>(
    mutationRefreshToken,
    { ignoreResults: true }
  )

  const { SSO, emailLogin } = queryData?.customerSettings ?? {}
  let timeoutId = React.useRef<NodeJS.Timeout | null>(null)
  const calculateTimeoutTime = useCallback(async (): Promise<number | null> => {
    try {
      const decodedToken = await jwtDecode(getLocalStorageToken())
      const diff = decodedToken?.exp * 1000 - new Date().valueOf()
      let timeoutTime = Math.max(diff - 5000 ?? 0, 5000)
      return timeoutTime
    } catch {
      return null
    }
  }, [])
  const callback = useCallback(async () => {
    let token = getLocalStorageToken()
    const tokenData = await refreshToken({
      variables: { token }
    })
    if (tokenData?.data?.refreshToken) {
      setLocalStorage('token', tokenData?.data.refreshToken)
    }
    token = getLocalStorageToken()
    const timeout = await calculateTimeoutTime()
    if (token && timeout) {
      timeoutId.current = setTimeout(callback, timeout)
    }
  }, [calculateTimeoutTime, refreshToken])
  const startTimer = useCallback(async () => {
    try {
      const token = getLocalStorageToken()
      const timeout = await calculateTimeoutTime()
      if (token && timeout) {
        timeoutId.current = setTimeout(callback, timeout)
      }
    } catch {}
  }, [calculateTimeoutTime, callback])
  const blur = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
  }, [])
  const focus = useCallback(async () => {
    logoutCheck()
    startTimer()
  }, [logoutCheck, startTimer])
  useEffect(() => {
    window.addEventListener('blur', blur)
    window.addEventListener('focus', focus)
    startTimer()
    setLoading(false)
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
      window.removeEventListener('blur', blur)
      window.removeEventListener('focus', focus)
    }
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getLocalStorageToken(),
    blur,
    calculateTimeoutTime,
    callback,
    focus,
    logoutCheck,
    refreshToken,
    startTimer
  ])
  if (loading) {
    return (
      <div className="w-full h-full flex-1 flex-center">
        <FleximeLoader size={64} />
      </div>
    )
  }
  return (
    <>
      {!getLocalStorageToken() ? (
        <Route component={() => <LoginContainer SSO={SSO} emailLogin={emailLogin} />} />
      ) : (
        <CoreLayout>
          <AppLayoutContainer />
        </CoreLayout>
      )}
    </>
  )
}
