export type WhatsNewType = { version: string; entries: WhatsNewEntries[] }
interface WhatsNewEntries {
  header: string
  text: string[]
}
export const whatsNew: WhatsNewType[] = [
  {
    version: '1.10.1',
    entries: [
      {
        header: 'Val av synliga organisationer',
        text: [
          'Komponenten för att välja vilka organisationer som ska visas har flyttats till användarinställningar för mobil och högst upp på hemsidan för större skärmar.',
          'Valda organisationer avgör vilken data som ska visas på alla vyer av hemsidan. Den data som påverkas av detta är:',
          '- Arbetspass utan instämplingar',
          '- Instämplade',
          '- Terminaler',
          '- Notifikationer',
          '- Notifikationsgrupper'
        ]
      },
      {
        header: 'Skapande av virtuell terminal',
        text: [
          'Knappen för att skapa en virtuell terminal har flyttats från Adminsidan till högst upp i terminallistan på Startsidan.',
          'Det är nu möjligt att välja vilka organisationer som en terminal ska tillhöra direkt vid skapandet.'
        ]
      }
    ]
  },
  {
    version: '1.10.2',
    entries: [
      {
        header: 'Val av tillåtna frånvarokoder för terminaler (endast administratörer)',
        text: [
          'Nu finns möjligheten på admin-sidan att välja vilka frånvarokoder som ska tillåtas på terminaler.',
          'Om man skulle förbjuda flera frånvarokoder som används i dagsläget varnar systemet vilka terminaler som använder dem.',
          'Väljer man att spara utan att ta bort koderna från terminalerna manuellt kommer systemet automatiskt ta bort berörda frånvarokoderna från terminalerna.'
        ]
      }
    ]
  },
  {
    version: '1.10.3',
    entries: [
      {
        header: 'Ny vy för avvikelser',
        text: [
          'Nu finns en ny vy för att hantera avvikelser som i kommande uppdateringar helt kommer ersätta notifikationsvyn.',
          'I den nya avvikelsehanteringen så försöker vi gå djupare i sättet vi hjälper dig som användare att avhjälpa avvikelser.',
          'På startsidan finns nu de nya avvikelsegrupperna istället för notifikationsgrupper.',
          'Vi rekomenderar att börja använda den nya avvikelsevyn och vänja sig vid den så snabbt som möjligt för att underlätta övergången från den gamla notifikationsvyn.'
        ]
      }
    ]
  },
  {
    version: '1.10.4',
    entries: [
      {
        header: 'Inbyggd support och flytt av inställningar',
        text: [
          '- Nu finns ett nytt sätt att läsa på om systemet och rapportera in supportärenden. Knappen längst ner i vänstra hörnat används för att öppna supportsystemet.',
          '- Inställningar för vilka organisationer du vill se, vilket språk du vill använda samt byte av lösenord hittar du genomn att klicka högst upp i högra hörnet i systemet.'
        ]
      }
    ]
  },
  {
    version: '1.10.5',
    entries: [
      {
        header: 'Geografiska områden per kund/uppdrag',
        text: [
          'Nu finns det stöd för att lägga till geografiska områden på kunder och uppdrag som konsulterna kan stämpla in på via appen.',
          'Om ett uppdrag/kund är tillagd på en terminal kan konsulten stämpla in på terminalen via uppdraget/kundens områden.'
        ]
      }
    ]
  },
  {
    version: '1.10.6',
    entries: [
      {
        header: 'WhatsNew.1_10_6.header',
        text: ['WhatsNew.1_10_6.text']
      }
    ]
  }
]
