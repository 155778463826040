import { useLocalStorage } from 'hooks/useLocalStorage'
import i18n from 'i18next'
import { Select } from '@material-ui/core'
import * as React from 'react'

export default function LanguageSelector() {
  const [lng, setLng] = useLocalStorage('lng', 'sv')
  const changeLanguage = lng => {
    i18n.changeLanguage(lng)
  }
  return (
    <Select
      classes={{ select: '!py-[3px]' }}
      native
      onChange={(e: any) => {
        changeLanguage(e.target.value)
        setLng(e.target.value)
      }}
      value={lng}
    >
      <option value={'sv'}>Svenska</option>
      <option value={'en'}>English</option>
    </Select>
  )
}
