import * as React from 'react'
import ActionBase from 'components/Timeline/WorkdayActions/ActionBase/ActionBase'
import mutationUpdateTimestamp from 'graphql/mutations/mutationUpdateTimestamp'
import queryTimestampTerminals from 'graphql/queries/queryTimestampTerminals'
import get from 'lodash.get'
import { getLocalStorageToken } from 'store/localStorage'
import SelectDialog, { SelectOption } from 'components/SelectDialog/SelectDialog'

import { Translation } from 'react-i18next'
import { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import toast from 'react-hot-toast'
import {
  updateTimestamp as updateTimestampTypes,
  updateTimestampVariables
} from 'graphql/mutations/types/updateTimestamp'
import {
  timestampsTerminals,
  timestampsTerminalsVariables
} from 'graphql/queries/types/timestampsTerminals'

export interface Props {
  timestampId: number
  workdayOrderIds: number[]
}

const TimestampMarkerTerminalAction: React.FC<Props> = props => {
  const [showModal, setShowModal] = useState(false)
  const { timestampId } = props
  const [updateTimestamp] = useMutation<updateTimestampTypes, updateTimestampVariables>(
    mutationUpdateTimestamp,
    {
      refetchQueries: ['queryPersonWorkdays', 'notificationsList']
    }
  )
  const { data, loading } = useQuery<timestampsTerminals, timestampsTerminalsVariables>(
    queryTimestampTerminals,
    {
      variables: { token: getLocalStorageToken(), timestampId }
    }
  )
  return (
    <Translation>
      {t => {
        const onSave = async (terminalId: number) => {
          try {
            await updateTimestamp({
              variables: {
                token: getLocalStorageToken(),
                id: timestampId,
                terminalId
              }
            })
            toast.success((t('TimestampMarkerTerminalAction.timestampMoved') as string) ?? '')
          } catch (error) {
            toast.error((t('TimestampMarkerTerminalAction.timestampMoveFailed') as string) ?? '')
          }
        }

        if (!data || loading) {
          return null
        }
        const timestamp = get(data, 'viewer.timestamps[0]', {})
        const terminals = get(data, 'viewer.terminals', [])
        const currentTerminal = terminals.find(terminal => terminal.id === timestamp.TerminalId)
        const terminalCandidates = terminals

        const selectOptions: SelectOption[] = terminals.map(terminal => ({
          value: terminal.id,
          name: `${terminal.name} - ${terminal.description}`
        }))

        return (
          <React.Fragment>
            <ActionBase
              text={t('TimestampMarkerTerminalAction.moveTimestampToTerminal')}
              onClick={() => setShowModal(true)}
              isActive={terminalCandidates.length !== 0}
            />
            <SelectDialog
              title={t('TimestampMarkerTerminalAction.changeTerminal')}
              options={selectOptions}
              startValue={currentTerminal ? currentTerminal.id : null}
              onSave={onSave}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </React.Fragment>
        )
      }}
    </Translation>
  )
}

export default TimestampMarkerTerminalAction
