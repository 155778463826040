import React from 'react'
import MultiSelect from 'components/MultiSelect'

export const ExcludePicker: React.FC<{
  allItems: { id: number; name: string }[]
  excludedIds: number[]
  setExcludedIds: (ids: number[]) => void
  title?: string
  icon?: JSX.Element
}> = ({ allItems, excludedIds, setExcludedIds, icon, title }) => {
  const selectedKeys = allItems
    .map(item => item.id)
    .filter(key => !excludedIds.find(itemId => itemId === key))
  return (
    <div className="flex">
      {icon ? (
        <div className="flex justify-center items-end pt-0 pr-2 pb-[6px] pl-0">{icon}</div>
      ) : null}
      <div className="flex flex-col">
        {title && <div className="mr-1 text-xs text-gray-500 leading-6">{title}: </div>}
        <MultiSelect
          options={allItems.map(item => ({ key: item.id, value: item.name }))}
          selectedKeys={selectedKeys}
          includeSelectAll={true}
          includeFilter={true}
          onChange={values => {
            const selected: any[] = []
            const deselected: any[] = []
            values.map(value =>
              value.checked ? selected.push(value.option?.key) : deselected.push(value.option?.key)
            )
            let orgsToExclude: number[] = allItems
              .filter(item => !selectedKeys.find(key => item.id === key))
              .map(o => o.id)
            if (selected.length > 0) {
              orgsToExclude = orgsToExclude.filter(id => !selected.find(desel => desel === id))
            }
            if (deselected.length > 0) {
              orgsToExclude = orgsToExclude.concat(deselected)
            }
            setExcludedIds(orgsToExclude)
          }}
        />
      </div>
    </div>
  )
}
