import * as React from 'react'

interface SidebarMenuProviderValue {
  hidden: boolean
  actions: {
    openMenu: () => void
    closeMenu: () => void
  }
}

const SidebarMenuContext = React.createContext<SidebarMenuProviderValue | null>(null)

class SidebarMenuProvider extends React.Component<{}, SidebarMenuProviderValue> {
  state: SidebarMenuProviderValue = {
    hidden: true,
    actions: {
      openMenu: () => this.setState({ ...this.state, hidden: false }),
      closeMenu: () => this.setState({ ...this.state, hidden: true })
    }
  }

  render() {
    return (
      <SidebarMenuContext.Provider value={this.state}>
        {this.props.children}
      </SidebarMenuContext.Provider>
    )
  }
}

export { SidebarMenuProvider, SidebarMenuContext }
