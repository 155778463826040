import * as React from 'react'

export interface Props {
  style?: React.CSSProperties
  title?: string
  weight?: number
  bodyStyle?: React.CSSProperties
  titleStyle?: React.CSSProperties
  rootStyle?: React.CSSProperties
  inactive?: boolean
  className?: string
  rootClass?: string
  containerRef?: any
}

const DashboardCard: React.SFC<Props> = props => {
  const {
    children,
    style,
    title,
    weight,
    bodyStyle,
    titleStyle,
    rootStyle,
    rootClass,
    inactive,
    className,
    containerRef
  } = props
  return (
    <div className={`bg-white shadow rounded-lg flex-1 max-w-full ${rootClass}`} style={rootStyle}>
      <div
        className={`py-3 px-2 ${className}`}
        style={{ ...style, flex: weight ? weight : 1 }}
        ref={containerRef}
      >
        {title && (
          <div className="pr-4 pl-4" style={titleStyle}>
            {title ? (
              <div className={`pb-3 ${inactive ? 'text-gray-300' : ''}`}>{title}</div>
            ) : null}
          </div>
        )}
        <div className={`pr-4 pl-4 flex-1 ${inactive ? 'text-gray-300' : ''}`} style={bodyStyle}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default DashboardCard
