import { enGB, sv } from 'date-fns/locale'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en_trans from './i18_en.json'
import sv_trans from './i18_sv.json'

export const resources = {
  sv: { translation: sv_trans, datefns: sv },
  en: { translation: en_trans, datefns: enGB }
}
const defaultLanguage = 'sv'
let lng = defaultLanguage
try {
  const lslng = localStorage.getItem('lng')
  if (lslng) {
    lng = JSON.parse(lslng)
  }
} catch (err) {}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng,
    fallbackLng: defaultLanguage,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
