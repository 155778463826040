import * as React from 'react'
import { DialogCloseButton } from './DialogBox.style'

export interface IDialogBox {
  title: string
  iconClass: string
  closeDialog: () => void
}

export class DialogBox extends React.Component<IDialogBox, any> {
  render() {
    const { title, iconClass, children, closeDialog } = this.props
    return (
      <div>
        <div
          className={`rounded-l-sm rounded-r-sm absolute top-0 left-0 w-full h-16 z-50 bg-white`}
        >
          <div className="text-lg text-gray-700 leading-10 pl-4 pt-4">
            <i className={iconClass} />
            <span className="ml-2">{title}</span>
          </div>
          <DialogCloseButton onClick={closeDialog} />
        </div>
        <div className="mt-12">{children}</div>
      </div>
    )
  }
}
