import gql from 'graphql-tag'

export const notificationsPaginatedQuery = gql`
  query notificationsPaginated(
    $token: String!
    $type: NotificationTypeEnum!
    $first: Int!
    $after: Int!
    $dateFrom: Date!
    $dateTo: Date!
    $orderBy: String
    $sortOrder: SortOrder
    $terminalId: Int
    $ipPersonId: Int
    $excludeOrganisationIds: [Int]
  ) {
    viewer(token: $token) {
      id
      notificationsPaginated(
        first: $first
        after: $after
        type: $type
        afterDate: $dateFrom
        beforeDate: $dateTo
        orderBy: $orderBy
        sortOrder: $sortOrder
        terminalId: $terminalId
        ipPersonId: $ipPersonId
        excludeOrganisationIds: $excludeOrganisationIds
      ) {
        paginationInfo {
          id
          pageSize
          currentPage
          totalPages
          totalCount
        }
        posts {
          id
          type
          terminal {
            id
            description
            name
          }
          OrderId
          timestamp {
            id
            timeStamped
            comment
            direction
            TerminalId
            rfid
            ipPerson {
              id
              firstName
              surName
            }
          }
        }
      }
      notificationTypeMeta(
        after: $dateFrom
        before: $dateTo
        type: $type
        terminalId: $terminalId
        ipPersonId: $ipPersonId
        excludeOrganisationIds: $excludeOrganisationIds
      ) {
        ipPersons {
          id
          firstName
          surName
        }
        ipOrders {
          id
          staffingService {
            name
          }
        }
        terminals {
          id
          description
          name
        }
      }
    }
  }
`
