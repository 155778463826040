import { getLocalStorageToken } from 'store/localStorage'
import * as jwtDecode from 'jwt-decode'
export default function isSSOLoggedIn() {
  const token = getLocalStorageToken()
  if (token) {
    try {
      const decodedJwt = jwtDecode(token)
      if (decodedJwt.SSO) {
        return true
      }
    } catch (err) {
      return false
    }
    return false
  }
}
