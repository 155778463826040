import React, { FC } from 'react'
import Button from './Button'
import Spinner from './Spinner'

interface Props {
  open: boolean
  titleText: string
  cancelButtonText?: string
  approveButtonText?: string
  approveDisabled?: boolean
  buttonColor?: 'indigo' | 'red' | 'green'
  loading?: boolean
  onCancel?: () => void
  onApprove?: () => void
}

const Modal: FC<Props> = ({
  open,
  titleText,
  children,
  cancelButtonText,
  approveDisabled,
  buttonColor,
  approveButtonText,
  onCancel,
  loading,
  onApprove
}) => {
  return open ? (
    <div className="fixed z-10 inset-0 overflow-y-auto" onClick={onCancel}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          onClick={(e: any) => {
            e.stopPropagation()
          }}
          className="inline-block align-bottom bg-white relative rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          {loading && (
            <div
              className="absolute inset-0 flex items-center justify-center"
              style={{ background: 'rgba(255, 255, 255, 0.6)' }}
            >
              <Spinner />
            </div>
          )}
          <div>
            <div className="">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {titleText}
              </h3>
              <div className="mt-4 text-sm leading-5 text-gray-500">{children}</div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <span className="flex w-full rounded-md shadow-sm">
              {onApprove && (
                <Button
                  color={buttonColor}
                  disabled={approveDisabled}
                  className="w-full mr-1"
                  onClick={onApprove}
                >
                  {approveButtonText || 'Bekräfta'}
                </Button>
              )}
              {onCancel && (
                <Button
                  color={buttonColor}
                  className="w-full ml-1"
                  variant="secondary"
                  onClick={onCancel}
                >
                  {cancelButtonText || 'Avbryt'}
                </Button>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default Modal
