import * as jwtDecode from 'jwt-decode'
import { ReduxResponsive } from 'models/customTypings'
import { IToken } from 'models/interfaces'
import * as React from 'react'
import { useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import Login from 'routes/LoginView/LoginView'
import { getLocalStorageToken } from 'store/localStorage'
import { browserSelector } from 'store/reducers'
import {
  ILoginConnectedDispatch,
  ILoginConnectedState,
  loginMapDispatchToProps,
  loginMapStateToProps
} from './loginMapProps'

export interface ILoginProps extends ILoginConnectedState, ILoginConnectedDispatch {
  browser: ReduxResponsive
  SSO: boolean
  emailLogin: boolean
  handleSubmit: (event: any) => void
}

const LoginContainer: React.FC<ILoginProps> = props => {
  const { goToStart, logout } = props
  const isTokenOld = (decodedToken: IToken) => {
    const expDate = new Date(decodedToken.exp * 1000)
    return Date.now() >= expDate.valueOf()
  }

  useLayoutEffect(() => {
    const token = getLocalStorageToken()
    try {
      const decodedToken: IToken = jwtDecode(token)
      if (isTokenOld(decodedToken)) {
        logout()
      } else {
        goToStart()
      }
    } catch (err) {
      //
    }
  }, [goToStart, logout])

  return <Login {...props} />
}
export default reduxForm<any, any>({ form: 'login' })(
  connect(
    loginMapStateToProps,
    loginMapDispatchToProps
  )(connect<any, any, any, any>(browserSelector)(LoginContainer))
)
