import gql from 'graphql-tag'
export default gql`
  mutation createUser(
    $token: String!
    $username: String!
    $password: String!
    $privilegeLevel: Int!
  ) {
    createUser(
      token: $token
      username: $username
      password: $password
      privilegeLevel: $privilegeLevel
      changePassword: 1
    )
  }
`
