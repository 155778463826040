import { useMutation } from '@apollo/client'

import logo from 'assets/logotyp.svg'

import { ILoginProps } from 'containers/Login/LoginContainer'
import mutation from 'graphql/mutations/mutationCreateToken'
import * as React from 'react'
import { useState } from 'react'
import { Translation } from 'react-i18next'

import microsoftLogo from './assets/microsoft.svg'
import bg from './assets/bg.jpeg'

import firebase from 'firebase/app'
import 'firebase/auth'
import { useForm } from 'react-hook-form'
import {
  createToken as createTokenTypes,
  createTokenVariables
} from 'graphql/mutations/types/createToken'

var provider = new firebase.auth.OAuthProvider('microsoft.com')

const LoginView: React.FC<ILoginProps> = props => {
  const { login, SSO, emailLogin } = props
  const [SSOError, setSSOError] = useState<string | null>(null)
  // Check if sso login began within the last 60 seconds
  const { register, handleSubmit } = useForm<{ email: string; password: string }>()

  const [createToken, { error: e }] = useMutation<createTokenTypes, createTokenVariables>(mutation)
  const onSubmit = async (data: any) => {
    try {
      const response: any = await createToken({
        variables: { username: data.email, password: data.password }
      })
      const token = response.data.createToken
      login(token)
    } catch (err) {}
  }

  const loginWithSSO = React.useCallback(async () => {
    try {
      const userCred = await firebase.auth().signInWithPopup(provider)
      const user = userCred?.user

      if (!user) {
        return
      } else {
        try {
          const id_token = await firebase?.auth?.().currentUser?.getIdToken(/* forceRefresh */ true)
          const response: any = await createToken({ variables: { id_token } })
          const token = response.data.createToken
          login(token)
        } catch (err) {
          firebase?.auth?.()?.signOut()
          setSSOError((err as any)?.message)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [login, createToken])

  return (
    <Translation>
      {t => (
        <div className="min-h-screen bg-white flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img className="h-12 w-auto" src={logo} alt="Workflow" />

                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  {t('LoginView.title')}
                </h2>
              </div>

              <div className="mt-8">
                <div>
                  {emailLogin && (
                    <div className="mt-6">
                      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t('general.email')}
                          </label>
                          <div className="mt-1">
                            <input
                              {...register('email', { required: true })}
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="space-y-1">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {t('general.password')}
                          </label>
                          <div className="mt-1">
                            <input
                              {...register('password', { required: true })}
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="current-password"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <span className="text-red-500">
                          {e?.message?.includes('Credential')
                            ? t('LoginView.CredentialsWrong')
                            : null}
                        </span>
                        <div>
                          <button
                            data-testid="email-login-button"
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            {t('LoginView.button')}
                          </button>
                        </div>
                      </form>
                    </div>
                  )}

                  {SSO && (
                    <div className="mt-6 relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300"></div>
                      </div>
                      <div className="relative flex justify-center text-sm">
                        {SSO && emailLogin && (
                          <span className="px-2 bg-white text-gray-500">
                            {t('LoginView.SSOTitle2')}
                          </span>
                        )}
                        {SSO && !emailLogin && (
                          <span className="px-2 bg-white text-gray-500">
                            {t('LoginView.SSOTitle1')}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {SSO && (
                  <div>
                    <span className="text-red-500">
                      {SSOError?.includes('domain that is not allowed')
                        ? t('LoginView.NotAllowedDomain')
                        : null}
                    </span>
                    <div className="mt-6 grid grid-cols-3 gap-3">
                      <div />
                      <div>
                        <div
                          data-testid="login-with-sso"
                          onClick={loginWithSSO}
                          className="cursor-pointer w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Sign in with Microsoft</span>
                          <img src={microsoftLogo} alt="Microsoft logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img className="absolute inset-0 h-full w-full object-cover" src={bg} alt="" />
          </div>
        </div>
      )}
    </Translation>
  )
}

export default LoginView
