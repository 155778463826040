import { useMutation, useQuery } from '@apollo/client'
import { FleximeLoader } from 'components/FleximeLoader/FleximeLoader'
import {
  notifications,
  notificationsVariables,
  notifications_viewer_notifications
} from '../graphql/queries/types/notifications'
import React, { useEffect, useState } from 'react'
import { getLocalStorageToken } from 'store/localStorage'
import notificationsQuery from '../graphql/queries/queryNotifications'
import { Error } from 'base-components/Error'
import { groupArrayBy } from 'utils/groupArrayBy'
import Toggle from 'base-components/Toggle'
import DashboardCard from 'components/DashboardCard/DashboardCard'
import ListItem from 'components/Notifications/ListItem'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import Button from 'base-components/Button'
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation'
import { SelectObjectDialog } from '../components/SelectObjectDialog'
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core'
import moment from 'moment-timezone'
import { useConfirmDialog } from 'hooks/useConfirmDialog'
import useUpdateTimestampRemoveNotification from '../hooks/useUpdateTimestampRemoveNotification'
import { useOrganisations } from 'hooks/useOrganisations'
import { mutationUpdatePerson } from 'graphql/mutations/mutationUpdatePerson'
import { UpdatePerson, UpdatePersonVariables } from 'graphql/mutations/types/UpdatePerson'
import toast from 'react-hot-toast'
import ActionPanel from 'base-components/ActionPanel'
import { NotificationTypeEnum } from 'types/graphql-global-types'

interface Props {
  dateFrom: Date
  dateTo: Date
}

export const NotificationsRfidMissing: React.FC<Props> = ({ dateFrom, dateTo }) => {
  const { excludeOrganisationIds } = useOrganisations()
  const { data: notificationsData, loading, error } = useQuery<
    notifications,
    notificationsVariables
  >(notificationsQuery, {
    variables: {
      token: getLocalStorageToken(),
      type: NotificationTypeEnum.RfidNotFound,
      after: dateFrom.valueOf(),
      before: dateTo.valueOf(),
      excludeOrganisationIds
    },
    skip: excludeOrganisationIds === null
  })
  const updateTimestampRemoveNotification = useUpdateTimestampRemoveNotification()
  const [updatePersonMutation] = useMutation<UpdatePerson, UpdatePersonVariables>(
    mutationUpdatePerson
  )
  const { showDialog, dialogComponent } = useConfirmDialog()

  const { t } = useTranslation()
  const [checkedRfid, setCheckedRfid] = useState<string[]>([])
  const [moveToPersonRfid, setMoveToPersonRfid] = useState<string | undefined>(undefined)
  const [updatePersonRfid, setUpdatePersonRfid] = useState<boolean>(false)

  useEffect(() => {
    setCheckedRfid([])
  }, [dateFrom, dateTo])

  if (loading) {
    return <FleximeLoader size={32} />
  }
  if (error) {
    return <Error error={error} />
  }

  const notificationsByRfid = groupArrayBy<notifications_viewer_notifications>(
    notificationsData?.viewer?.notifications ?? [],
    'timestamp.rfid'
  )

  const handleRemove = async () => {
    const onConfirm = async () => {
      const notifications: any[] = []
      for (const rfid of checkedRfid) {
        notifications.push(...notificationsByRfid[rfid])
      }
      await updateTimestampRemoveNotification({
        notifications,
        timeSend: undefined,
        sendAttempts: 0,
        successText: t('NewNotifications.NotificationsRfidMissing.successRemove')
      })
      setCheckedRfid([])
    }
    showDialog({
      title: t('NewNotifications.NotificationsRfidMissing.dialog.title'),
      body: t('NewNotifications.NotificationsRfidMissing.dialog.body'),
      confirm: { text: t('general.yes'), callback: onConfirm }
    })
  }

  const handleSelect = async (ipPersonId: number | undefined) => {
    if (moveToPersonRfid) {
      await updateTimestampRemoveNotification({
        notifications: notificationsByRfid[moveToPersonRfid],
        ipPersonId,
        timeSend: moment().valueOf(),
        sendAttempts: 5,
        successText: t('NewNotifications.NotificationsRfidMissing.successMove')
      })
      if (updatePersonRfid && typeof ipPersonId !== 'undefined') {
        toast.promise(
          updatePersonMutation({
            variables: { token: getLocalStorageToken(), ipPersonId, rfid: moveToPersonRfid }
          }),
          {
            success: t('PersonListContainer.personUpdated').toString(),
            error: t('PersonListContainer.personUpdatedFailed').toString(),
            loading: t('NewNotifications.NotificationsRfidMissing.updatingPerson').toString()
          }
        )
        setUpdatePersonRfid(false)
      }
    }
  }
  const empty = Object.keys(notificationsByRfid).length === 0
  if (empty) {
    return <div className="py-8 text-lg">{t('NewNotifications.noNotifications')} 🎉</div>
  }
  return (
    <>
      {dialogComponent}
      <SelectObjectDialog
        title={t('NewNotifications.NotificationsRfidMissing.personDialog.title')}
        text={t('NewNotifications.NotificationsRfidMissing.personDialog.body', {
          moveToPersonRfid
        })}
        isShowing={typeof moveToPersonRfid !== 'undefined'}
        onSelect={handleSelect}
        onClose={() => {
          setMoveToPersonRfid(undefined)
          setUpdatePersonRfid(false)
        }}
        type={'Person'}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={updatePersonRfid}
              onChange={event => setUpdatePersonRfid(event.target.checked)}
              name="updatePersonRfid"
            />
          }
          label={t('NewNotifications.NotificationsRfidMissing.personDialog.checkBoxLabel', {
            moveToPersonRfid
          })}
        />
      </SelectObjectDialog>
      <div className="w-full max-w-3xl pt-5 space-y-2">
        <ActionPanel
          title={t('NewNotifications.titles.' + NotificationTypeEnum.RfidNotFound)}
          content={
            <>
              <div className="text-sm text-gray-500">
                {t('NewNotifications.description.' + NotificationTypeEnum.RfidNotFound)}
              </div>
              <div className="mt-4 mb-2">
                {checkedRfid.reduce(
                  (sum, next) => sum + (notificationsByRfid?.[next]?.length ?? 0),
                  0
                )}{' '}
                {t('NewNotifications.NotificationsRfidMissing.timesSelected')}
              </div>
              <Button onClick={handleRemove} color="red" disabled={checkedRfid.length === 0}>
                {t('NewNotifications.NotificationsRfidMissing.removeSelected')}
              </Button>
            </>
          }
        />

        <DashboardCard rootClass="space-y-2">
          {Object.keys(notificationsByRfid).map(rfid => {
            const notificationsPerDay: { [key: string]: notifications_viewer_notifications[] } = {}
            for (const notification of notificationsByRfid[rfid]) {
              const formattedDate = format(notification?.timestamp?.timeStamped, 'yyyy-MM-dd')
              if (typeof notificationsPerDay[formattedDate] === 'undefined') {
                notificationsPerDay[formattedDate] = []
              }
              notificationsPerDay[formattedDate].push(notification)
            }
            return (
              <ListItem
                key={rfid}
                label={
                  <div>
                    RFID: {rfid}{' '}
                    <span className="text-gray-500 truncate">
                      ({notificationsByRfid[rfid].length} tider)
                    </span>
                  </div>
                }
                expandedBody={
                  <div className="ml-4">
                    {Object.keys(notificationsPerDay).map(notificationsDay => (
                      <div className="mt-4" key={notificationsDay}>
                        <div>{notificationsDay}</div>
                        <div className="ml-4">
                          {notificationsPerDay[notificationsDay].map(notification => (
                            <div key={notification?.id ?? undefined}>
                              {t(`TimestampDirection.${notification?.timestamp?.direction}`)}{' '}
                              {format(new Date(notification?.timestamp?.timeStamped), 'hh:mm')} @{' '}
                              {notification?.terminal?.description}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                }
                left={
                  <Toggle
                    onChange={toggled => {
                      if (toggled) {
                        if (!checkedRfid.find(checked => checked === rfid)) {
                          setCheckedRfid([...checkedRfid, rfid])
                        }
                      } else {
                        setCheckedRfid(checkedRfid.filter(checked => checked !== rfid))
                      }
                    }}
                    toggled={!!checkedRfid.find(checked => checked === rfid)}
                  />
                }
                body={''}
                right={
                  <IconButton onClick={() => setMoveToPersonRfid(rfid)}>
                    <TransferWithinAStationIcon />
                  </IconButton>
                }
              ></ListItem>
            )
          })}
        </DashboardCard>
      </div>
    </>
  )
}
